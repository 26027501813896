<template>
    <div class="header">
        <Dropdown trigger="click" @on-click="selectItem">
            <a href="javascript:void(0)">
                {{ title }}
                <Icon type="ios-arrow-down"></Icon>
            </a>
            <DropdownMenu slot="list">
                <DropdownItem v-for="(item, index) in list" :name="index" :key="index">{{ item.name }}</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    </div>
</template>

<script>
export default {
    name: 'leftHeader',
    props: {
        list: {
            type: Array,
            default() {
                return [];
            }
        },
        title: {
            type: String,
            default: '板块'
        }
    },
    computed: {
        selectedTab: {
            get() {
                return this.$store.state.project.selectedTab;
            },
            set(newValue) {
                this.$store.state.project.selectedTab = newValue;
            }
        }
    },
    methods: {
        selectItem(value) {
            this.selectedTab = value;
        }
    }
};
</script>

<style lang="scss" scoped>
.header {
    width: 483px;
    position: fixed;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    color: #1562d6;
    text-align: center;
    background-color: #f3f7ff;

}
</style>
