<template>
	<div class="detail-right">
		<div class="header">
			<div class="selector">
				<Dropdown trigger="custom" :visible="saleStateVisible" @on-click="saleStateChange"
					@on-clickoutside="saleStateVisible = false">
					<a href="javascript:void(0)" @click="saleStateOpen">
						销售状态
						<Icon type="ios-arrow-down"></Icon>
					</a>
					<DropdownMenu slot="list">
						<DropdownItem v-for="item in saleState" :name="item.id" :key="item.id">
							<Icon class="icon" type="md-checkmark" v-show="saleStateId.includes(item.id)" />
							{{ item.name }}
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</div>
			<div class="selector">
				<Dropdown trigger="custom" :visible="propertyTypeVisible" @on-click="propertyTypeChange"
					@on-clickoutside="propertyTypeVisible = false">
					<a href="javascript:void(0)" @click="propertyTypeOpen">
						物业类型
						<Icon type="ios-arrow-down"></Icon>
					</a>
					<DropdownMenu slot="list">
						<DropdownItem v-for="item in propertyType" :name="item.id" :key="item.id">
							<Icon class="icon" type="md-checkmark" v-show="propertyTypeId.includes(item.id)" />
							{{ item.name }}
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</div>
			<div class="selector time-range">
				<a class="text" href="javascript:void(0)" @click="timeRangeClick">
					开盘时间
					<Icon type="ios-arrow-down"></Icon>
				</a>
				<div class="picker" v-show="showDatePicker">
					<DatePicker type="date" :value="timeRange[0]" format="yyyy-MM-dd" placeholder="起始日期"
						style="width: 130px" @on-change="changeStartTime"></DatePicker>
					<span class="zhi">至</span>
					<DatePicker type="date" :value="timeRange[1]" format="yyyy-MM-dd" placeholder="结束日期"
						style="width: 130px" @on-change="changeEndTime"></DatePicker>
				</div>
			</div>
			<div class="selector price">
				<a class="text" href="javascript:void(0)" @click="priceRangeClick">
					价格
					<Icon type="ios-arrow-down"></Icon>
				</a>
				<div class="picker price" v-show="showPricePicker">
					<Input v-model="priceRange[0]" placeholder="最低价" style="width: 130px"
						@on-blur="handlePriceChange" />
					<span class="zhi">至</span>
					<Input v-model="priceRange[1]" placeholder="最高价" style="width: 130px"
						@on-blur="handlePriceChange" />
				</div>
			</div>
		</div>
		<div class="search-box">
			<Input v-model="keyword" placeholder="请输入小区名称" style="width: 100%" @on-enter="handleSearch" />
			<span class="icon-box" @click="handleSearch">
				<Icon type="ios-search" color="#fff" :size="16" />
			</span>
		</div>
		<div class="banner">
			<span class="left">共{{ total }}个结果</span>
			<div class="sort">
				<div class="item" v-for="(item, index) in sortList" :key="index" @click="handleResultSort(item, index)">
					{{ item.name }}
					<i :class="['sort-icon', { active: index === sortType }]"></i>
				</div>
			</div>
		</div>

		<div class="result">
			<div class="result-item" v-for="item in resultList" :key="item.id" @click.stop="handleItemClick(item)">
				<div class="left">
					<img :src="tempImg" />
				</div>
				<div class="middle">
					<!-- @click.stop="toLp(item)" -->
					<p class="name" >{{ item.xmmc }}</p>
					<p class="params">
						<span class="hx">{{ item.zthx }}</span>
						<span class="area">{{ item.fwmjxy }}{{ item.fwmjdy }}m²</span>
					</p>
					<p>
						<span class="date">距离本案:{{ item.distance.toFixed(0)}}米</span>
					</p>
					<p class="box">
						<span class="link">看项目</span>
						<span class="date">{{ item.kpsj }}</span>
					</p>
				</div>
				<div class="right">
					<p class="price-list">
						<span class="type new">新</span>
						<span class="price"><span class="num">{{ item.xfjg }}</span>元/m²</span>
					</p>
					<p v-if="item.esfj" class="price-list">
						<span class="type">二</span>
						<span class="price"><span class="num">{{ item.esfj }}</span>元/m²</span>
					</p>
					<p v-if="item.zjjg" class="price-list">
						<span class="type">租</span>
						<span class="price"><span
						class="num">{{ item.zjjg?Number(item.zjjg).toFixed(2):'' }}</span>元/m²/月</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import BMap from "BMap";
	import BMapLib from "BMapLib";
	import {
		zblp
	} from "@/api/projectAnalysis";

	export default {
		name: "detailRight",
		data() {
			return {
				keyword: "",
				sortList: [{
						id: 0,
						name: "智能",
						checked: true,
						type: "asc",
					},
					{
						id: 1,
						name: "价格",
						checked: false,
						type: "asc",
					},
					{
						id: 2,
						name: "发布时间",
						checked: false,
						type: "asc",
					},
				],
				sortType: 0,

				saleStateText: "销售状态",
				saleStateId: [],
				saleStateVisible: false,
				propertyTypeText: "物业类型",
				propertyTypeId: [],
				propertyTypeVisible: false,

				rangeMap: {
					sj: "开盘时间",
					quotationInterval: "价格",
				},

				timeRange: [],
				showDatePicker: false,

				priceRange: [],
				showPricePicker: false,

				tempImg: require("@/assets/image/buildingImg.jpg"),
			};
		},

		computed: {
			projectDetail() {
				return this.$store.state.project.projectDetail;
			},
			projectData() {
				return this.$store.state.project.option;
			},
			propertyType() {
				return this.projectData.LpWYType;
			},
			saleState() {
				return this.projectData.saleState;
			},
			shi() {
				return this.$store.getters["project/shi"];
			},

			resultList: {
				get() {
					return this.$store.state.project.lpList ?
						this.$store.state.project.lpList.list :
						[];
				},
				set(value) {
					this.$store.state.project.lpList.list = value;
				},
			},
			total() {
				return this.$store.state.project.lpList ?
					this.$store.state.project.lpList.total :
					0;
			},
		},

		mounted() {
			this.sortList = this.sortList.map((item, index) => {
				index === 0 ? (item.checked = true) : (item.checked = false);
				return item;
			});
			this.propertyTypeChange(1)
		},

		methods: {
			handleResultSort(item, index) {
				this.sortType = item.id;
				item.checked = true;
				this.sortList.forEach((it) => {
					if (item.id !== it.id) {
						it.checked = false;
					}
				});

				switch (item.id) {
					case 0:
						this.resultList.sort((a, b) => {
							return this.sortFn(item.type, a.xmmc, b.xmmc);
						});
						break;
					case 1:
						this.resultList.sort((a, b) => {
							return this.sortFn(item.type, a.xfjg, b.xfjg);
						});
						break;
					case 2:
						this.resultList.sort((a, b) => {
							return this.sortFn(
								item.type,
								new Date(a.kpsj).getTime(),
								new Date(b.kpsj).getTime()
							);
						});
						break;
				}

				item.type = item.type === "asc" ? "desc" : "asc";
				this.$set(this.sortList, index, item);
			},
			sortFn(type, a, b) {
				if (!a) a = 0;
				if (!b) b = 0;
				if (typeof a === "string" && typeof b === "string") {
					return type === "asc" ? b.localeCompare(a) : a.localeCompare(b);
				} else {
					return type === "asc" ? b - a : a - b;
				}
			},

			saleStateChange(value) {
				if (this.saleStateId.indexOf(value) > -1) {
					this.saleStateId.splice(this.saleStateId.indexOf(value), 1);
				} else {
					this.saleStateId.push(value);
				}

				this.handleSearch();
			},
			propertyTypeChange(value) {
				if (this.propertyTypeId.indexOf(value) > -1) {
					this.propertyTypeId.splice(this.propertyTypeId.indexOf(value), 1);
				} else {
					this.propertyTypeId.push(value);
				}
				this.handleSearch();
			},

			timeRangeClick(event) {
				if (!event.target.className.includes("text")) return;
				this.showDatePicker = !this.showDatePicker;
			},

			priceRangeClick(event) {
				if (!event.target.className.includes("text")) return;
				this.showPricePicker = !this.showPricePicker;
			},

			changeStartTime(value) {
				this.timeRange[0] = value;

				this.handleSearch();
			},
			changeEndTime(value) {
				this.timeRange[1] = value;

				this.handleSearch();
			},

			handlePriceChange() {
				this.handleSearch();
			},

			handleSearch() {
				if (!this.projectDetail) {
					// this.$Message.warning('请先选择项目');
					this.$msg.error({
						type: "remind",
						code: 4024
					});

					return;
				}

				const params = {
					propertyType: this.propertyTypeId,
					shengId: this.projectDetail.sheng,
					quId: this.projectDetail.qu,
					shiId: this.shi,
					saleStatus: this.saleStateId,
					xqmc: this.keyword,
					quotationInterval: this.priceRange,
					sj: this.timeRange,
				};

				params.sj = params.sj.filter((item) => !!item);

				for (let key in this.rangeMap) {
					let start = params[key][0];
					let end = params[key][1];

					if (!start && !end) continue;

					if (!start || !end) {
						// this.$Message.warning(`${this.rangeMap[key]}, 请选择一个范围`);
						this.$msg.error({
							text: [""`${this.rangeMap[key]}`, ", 请选择一个范围"],
						});

						return;
					}
				}

				this.$parent.getLpData(params);
			},

			handleItemClick(item) {
				if (!item.jd || !item.wd) {
					// this.$Message.warning(`位置信息不全`);
					this.$msg.error({
						text: "位置信息不全"
					});

					return;
				}
				this.$parent.openInfoBox(item);
			},

			saleStateOpen() {
				this.saleStateVisible = !this.saleStateVisible;
			},
			propertyTypeOpen() {
				this.propertyTypeVisible = !this.propertyTypeVisible;
			},

			toLp(item) {
				const url = this.$router.resolve({
					path: `/Insight/City/search/buildingDetail`,
					query: {
						id: item.id,
						type: 0,
					},
				});
				window.open(url.href);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.detail-right {
		width: 100%;
		height: 100%;

		.header {
			width: 100%;
			height: 60px;
			display: flex;
			justify-content: space-around;
			align-items: center;
			background-color: #fcfcff;
			font-size: 14px;
			color: #333;
			border-bottom: 1px solid #d6d7d9;

			.selector {
				flex: 1;
				text-align: center;
				position: relative;

				.picker {
					display: flex;
					align-items: center;
					background-color: #fff;
					position: absolute;
					top: 30px;
					left: -108px;
					padding: 10px;
					border-radius: 4px;
					box-shadow: 0px 0px 6px #ccc;
					z-index: 1;

					&.price {
						left: -205px;
					}
				}

				.ivu-dropdown-item {
					position: relative;

					.icon {
						position: absolute;
						top: 11px;
						left: 16px;
					}
				}
			}

			.zhi {
				margin: 0 10px;
			}
		}

		.search-box {
			width: 100%;
			padding: 14px 10px;
			display: flex;
			align-items: center;

			.icon-box {
				display: inline-block;
				width: 30px;
				height: 30px;
				margin-left: 17px;
				background-color: #00b6ff;
				border-radius: 5px;
				text-align: center;
				line-height: 30px;
				cursor: pointer;
			}
		}

		.banner {
			width: 100%;
			height: 30px;
			padding-left: 10px;
			padding-right: 7px;
			background-color: #f4f4f4;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.sort {
				display: flex;
				align-items: center;

				.item {
					margin-right: 10px;
					cursor: pointer;

					&:nth-child(3) {
						margin-right: 0px;
					}

					.sort-icon {
						display: inline-block;
						width: 10px;
						height: 12px;
						background-image: url(~@/assets/image/map/sort.png);

						&.active {
							background-image: url(~@/assets/image/map/sort_active.png);
						}
					}
				}
			}
		}

		.result {
			width: 100%;
			height: calc(100% - 150px);
			padding: 0 10px;
			overflow: auto;

			&-item {
				width: 100%;
				padding: 14px 0px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #d6d7d9;
				// cursor: pointer;

				.left {
					width: 105px;
					height: 80px;
					margin-right: 10px;
					font-size: 14px;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.middle {
					max-width: 160px;

					.name {
						margin-bottom: 10px;
						font-size: 16px;
						color: #333;

						// &:hover {
						// 	text-decoration: underline;
						// }
					}

					.params {
						font-size: 14px;
						color: #666;
						margin-bottom: 10px;

						.hx {
							margin-right: 30px;
						}
					}

					.box {
						display: flex;
						align-items: center;
						font-size: 14px;

						.link {
							margin-right: 20px;
							color: #00b6ff;
							cursor: pointer;
							&:hover {
								text-decoration: underline;
							}
						}

						.date {
							flex: 1;
							display: inline-block;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							color: #333;
						}
					}
				}

				.right {
					flex: 1;
					margin-left: 10px;
					text-align: right;

					.price-list {
						margin-bottom: 8px;
						font-size: 12px;
						color: #333;

						&:last-child {
							margin-bottom: 0;
						}

						.type {
							padding: 2px;
							font-size: 12px;
							border: 1px solid #e0e0e0;
							color: #666;
							border-radius: 2px;

							&.new {
								color: #ee7d78;
								border: 1px solid #ee7d78;
							}
						}

						.num {
							margin-left: 3px;
							font-size: 16px;
							color: #ff3333;
						}
					}
				}
			}
		}
	}
</style>
