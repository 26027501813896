<template>
<div class="plan-view-left">
    <Header title="规划及实景图" :list="navList"></Header>
    <div class="content bigContent">
        <div class="file-box">
            <p class="title">城市及项目规划图</p>
            <div class="table">
                <Table :max-height="200" tooltip-theme="light" :columns="columns" :data="tableData"></Table>
            </div>
        </div>

        <div class="image-box">
            <p class="title">实景图片</p>

            <div class="images">
                <ul class="image-list">
                    <li class="image-item" v-for="item in imageList" :key="item.id" @click="imageClick(item)">
                        <img :src="item.pictureUrl" class="image" />
                    </li>
                </ul>
            </div>
        </div>

        <div class="video-box">
            <p class="title">航拍视频</p>

            <div class="videos">
                <ul class="video-list">
                    <li class="video-item" v-for="item in videoList" :key="item.id">
                        <svg-icon iconClass="play"></svg-icon>
                        <video :src="item.videoUrl" class="video" @loadeddata="loadedVideo($event)" @click="videoClick(item)" />
                    </li>
                </ul>
            </div>
        </div>

        <div class="vr-box">
            <p class="title">VR</p>

            <div class="images" v-for="item in VRList" :key="item.id">
                <img :src="item.vrUrl" class="image" />
            </div>
        </div>

        <Modal v-model="isShowModal" class="show-modal" class-name="vertical-center-modal" footer-hide :width="1000">
            <div class="content">
                <img :src="selectedImg" v-if="selectedImg" alt="图片" style="width: 100%" />
                <video controls :src="selectedVideo" v-if="selectedVideo" @loadeddata="loadedVideo($event)" />
            </div>
        </Modal>
    </div>
</div>
</template>

<script>
import Header from "../LeftHeader";
import {
    xmgh
} from "@/api/projectAnalysis";

export default {
    name: "planeViewLeft",
    components: {
        Header,
    },
    props: {
        navList: {
            type: Array,
            default () {
                return [];
            },
        },
    },
    data() {
        return {
            columns: [{
                    title: "序号",
                    key: "id",
                    align: "center",
                },
                {
                    title: "文件名称",
                    key: "name",
                    align: "center",
                    width: 152,
                    render(h, params) {
                        return h(
                            "a", {
                                style: {
                                    cursor: "pointer",
                                    color: "#00B6FF",
                                    overflow: "hidden",
                                    "white-space": "nowrap",
                                    "text-overflow": "ellipsis",
                                },
                                domProps: {
                                    href: params.row.url,
                                    download: params.row.name,
                                    title: params.row.name,
                                },
                            },
                            params.row.name
                        );
                    },
                },
                {
                    title: "类型",
                    key: "type",
                    align: "center",
                },
                {
                    title: "描述",
                    key: "desc",
                    align: "center",
                    width: 164,
                },
            ],

            tableData: [],
            resultList: [],
            imageList: [],
            videoList: [],
            VRList: [],

            imgSrc: require("@/assets/image/buildingImg.jpg"),

            isShowModal: false,

            selectedImg: "",
            selectedVideo: "",
        };
    },

    computed: {
        projectDetail() {
            return this.$store.state.project.projectDetail;
        },
    },
    beforeMount() {
        this.$Notice.destroy();
    },
    mounted() {
        this.getData();

    },

    methods: {
        loadedVideo(event) {
            const poster = this.getVideoBase64(event.target);
            $(event.target).attr("poster", poster);
        },
        getVideoBase64(target) {
            let dataURL = "";
            let canvas = document.createElement("canvas");
            canvas.width = target.offsetWidth;
            canvas.height = target.offsetHeight;
            canvas
                .getContext("2d")
                .drawImage(target, 0, 0, canvas.width, canvas.height); //绘制canvas
            dataURL = canvas.toDataURL("image/jpeg"); //转换为base64

            return dataURL;
        },
        getData() {
            xmgh({
                xmId: this.projectDetail.id,
            }).then((res) => {
                this.resultList = res;

                if (JSON.stringify(res) === "{}") return;

                this.tableData = res
                    .filter((item) => item.lx === "文件")
                    .map((item, index) => {
                        return {
                            id: index + 1,
                            name: item.wjmc,
                            type: item.lx,
                            desc: item.ms,
                            url: item.url,
                        };
                    });

                this.imageList = res.filter((item) => !!item.pictureUrl);

                this.videoList = res.filter((item) => !!item.videoUrl);

                this.VRList = res.filter((item) => !!item.vrUrl);
            });
        },

        imageClick(item) {
            this.selectedVideo = "";
            this.isShowModal = true;
            this.selectedImg = item.pictureUrl;
        },
        videoClick(item) {
            this.selectedImg = "";
            this.isShowModal = true;
            this.selectedVideo = item.videoUrl;
        },
    },
};
</script>

<style lang="scss" scoped>
.plan-view-left {
    padding-bottom: 10px;
    position: relative;
    overflow-x: hidden;
    .header{
        z-index: 10;
    }
    .content {
        position: relative;
        width: 100%;
        display: block;
        &.bigContent {
            padding-top: 60px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 100px;
        }
        .title {
            width: 483px;
            height: 24px;
            padding-left: 16px;
            font-size: 18px;
            font-weight: bold;
            color: #1562d6;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn-one {
                margin-right: 10px;
            }

            &::before {
                content: "";
                width: 4px;
                height: 20px;
                background-color: #1562d6;
                position: absolute;
                left: 0;
                top: 2px;
            }
        }

        .file-box {
            width: 100%;
            margin-top: 30px;
            padding: 0 20px;

            .table {
                width: 100%;
                margin-top: 10px;

                & /deep/ .ivu-table-wrapper {
                    .ivu-table-cell {
                        padding: 0 4px;
                    }

                    .ivu-table-tbody {
                        border-top: 1px solid #d6d7d9;
                    }

                    .ivu-table-tbody .ivu-table-column-center {
                        border-right: 1px solid #d6d7d9;
                        border-bottom: 1px solid #d6d7d9;

                        &:first-child {
                            border-left: 1px solid #d6d7d9;
                        }
                    }

                    .ivu-table td {
                        height: 40px;
                    }
                }
            }
        }

        .image-box {
            width: 100%;
            margin-top: 30px;
            padding: 0 20px;

            .images {
                width: 100%;
                margin-top: 10px;
                overflow: hidden;

                .image-list {
                    width: 100%;
                    height: 87px;
                    white-space: nowrap;
                    overflow-x: auto;
                    overflow-y: hidden;
                    list-style: none;

                    .image-item {
                        width: 105px;
                        height: 80px;
                        margin-right: 6px;
                        display: inline-block;
                        cursor: pointer;

                        &:last-child {
                            margin-right: 0;
                        }

                        .image {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .video-box {
            width: 100%;
            margin-top: 27px;
            padding: 0 20px;

            .videos {
                width: 100%;
                margin-top: 10px;
                overflow: hidden;

                .video-list {
                    width: 100%;
                    white-space: nowrap;
                    overflow-x: auto;
                    overflow-y: hidden;
                    list-style: none;

                    .video-item {
                        display: inline-block;
                        width: 105px;
                        margin-right: 6px;
                        font-size: 0px;
                        cursor: pointer;
                        position: relative;

                        .svg-icon {
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            margin: auto;
                        }

                        .video {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }

        .vr-box {
            width: 100%;
            margin-top: 27px;
            padding: 0 20px;

            .images {
                width: 440px;
                height: 240px;
                margin-top: 10px;

                .image {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .show-modal {
        & /deep/ .vertical-center-modal {
            display: flex;
            align-items: center;
            justify-content: center;

            .ivu-modal {
                top: 0;

                .content {
                    width: 100%;
                    height: 545px;

                    video {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

}
</style>
