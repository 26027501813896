<template>
  <div class="analysis">
    <Header :title="projectName" ref="header"></Header>
    <ProjectModal @click="handleMyProjectClick"></ProjectModal>
    <div class="content">
      <aside :class="['aside-left', { component: selectedTab !== -1 }]">
        <div class="tabs" v-if="selectedTab === -1">
          <p
            class="tab"
            v-for="item in navList"
            :key="item.id"
            @click="chooseLeftcomponet(item)"
          >
            {{ item.name }}
          </p>
        </div>
        <div class="component" v-else>
          <svg-icon iconClass="close" @click="backToMenu"></svg-icon>
          <component :is="leftComponet" :navList="navList" :itemid="1"></component>
        </div>
      </aside>

      <div class="map-container" id="map"></div>

      <aside class="aside-right" v-if="rightComponent">
        <div class="component">
          <component :is="rightComponent"></component>
        </div>
      </aside>

      <div class="tools">
        <span class="label">工具：</span>
        <div class="options">
          <!-- <div class="option" @click="dropSearch">控框搜索</div> -->
          <div class="option radius">
            <p
              :class="['btn', { active: isShowRadiusSearch }]"
              @click="showRadiusSearch"
            >
              <span class="text">半径搜索</span>
              <Icon type="ios-arrow-down" color="#AAAAAA" />
            </p>

            <div
              class="border"
              v-show="isShowRadiusSearch"
              @click="closeRadiusSearch"
            ></div>

            <div class="search-box" v-show="isShowRadiusSearch">
              <div class="input-box km">
                <Input
                  v-model="radius"
                  size="small"
                  style="width: 43px"
                  maxlength="4"
                />
                <span>公里</span>
              </div>
              <Button size="small" type="primary" @click="radiusSearch"
                >确定</Button
              >
            </div>
          </div>
          <div class="option" @click="ranging">测距</div>
          <div class="option" @click="measureArea">测面积</div>
          <div class="option clear" @click="clearOverlay">清除覆盖物</div>
          <div
            :class="['option', 'map-type', { active: isShowMapType }]"
            @click="isShowMapType = !isShowMapType"
          >
            地图
            <Icon type="ios-arrow-down" color="#AAAAAA" />

            <ul :class="['map-type-list', { show: isShowMapType }]">
              <li class="map-type-item" @click="changeMapType(0)">默认地图</li>
              <li class="map-type-item" @click="changeMapType(1)">卫星地图</li>
              <!-- <li class="map-type-item" @click="changeMapType(2)">
                                街景
              </li>-->
            </ul>
          </div>
        </div>
      </div>

      <div
        :class="['right-tabs', isHide ? 'hide' : '']"
        :style="tabStyle"
        @mouseleave="switchLeave"
      >
        <div
          :class="['swicth', isHide ? 'hide' : '']"
          @click="changeHide"
          :style="switchStyle"
          @mouseenter="switchEnter"
        >
          {{ switchText }}
        </div>
        <p
          :class="['tab-item', { active: selectedRightTab === item.id }]"
          v-for="item in rightTabs"
          :key="item.id"
          @click="handleRightTabClick(item)"
        >
          <img :src="item.src" class="img" />
          {{ item.name }}
        </p>
      </div>

      <div id="tmpMap" v-show="false"></div>
    </div>
  </div>
</template>

<script>
import BMap from "BMap";
import BMapLib from "BMapLib";
import Header from "./components/Header";
import DetailLeft from "./components/left/DetailLeft";
import LawLeft from "./components/left/LawLeft";
import EconomicLeft from "./components/left/EconomicLeft";
import LandMarketLeft from "./components/left/LandMarketLeft";
import MarketAnalysisLeft from "./components/left/MarketAnalysisLeft";
import NearbyLeft from "./components/left/NearbyLeft";
import PlanViewLeft from "./components/left/PlanViewLeft";
import LandRight from "./components/right/LandRight";
import CommunityRight from "./components/right/CommunityRight";
import ProjectRight from "./components/right/ProjectRight";
import NearbyRight from "./components/right/NearbyRight";
import ProjectModal from "./components/modal/projectModal";
import addProjectModal from "@/components/modal/addProject";
import { zbtd, zblp, getProjectDetail } from "@/api/projectAnalysis";
import dataReports from "@/mixins/dataReports";
import { fixNumber } from "@/utils";

export default {
  name: "analysisHome",
  mixins: [dataReports],
  components: {
    Header,
    DetailLeft,
    LawLeft,
    EconomicLeft,
    LandMarketLeft,
    MarketAnalysisLeft,
    NearbyLeft,
    PlanViewLeft,
    LandRight,
    CommunityRight,
    ProjectRight,
    NearbyRight,
    ProjectModal,
    addProjectModal,
  },
  data() {
    return {
      mapData: {
        zoom: 14,
        center: {
          lng: 116.46,
          lat: 39.92,
        },
      },
      panoramaCoverageLayer: null,
      stCtrl: null,
      isMapReady: false,

      drawingManager: null,
      mapDis: null,

      isRangingOpen: false,
      isHide: false,
      tabStyle: {},
      switchStyle: {},
      // 左边导航栏
      navList: [
        {
          id: "0",
          name: "项目详情",
        },
        {
          id: "1",
          name: "规划及实景图",
        },
        {
          id: "2",
          name: "政策法规",
        },
        {
          id: "3",
          name: "经济发展",
        },
        {
          id: "4",
          name: "土地市场",
        },
        {
          id: "5",
          name: "市场分析",
        },
        {
          id: "6",
          name: "周边楼盘",
        },
      ],
      selectedRightTab: -1,
      hasRight: false,

      // 拉框搜索相关数据
      isPullBoxOpen: false,

      // 半径搜索参数
      radiusKeyWord: "",
      radius: "1",
      isShowRadiusSearch: false,
      radiusOverlay: [],
      radiusInputVal: null,

      // 测面积数据
      isMeasureArea: false,
      polygonIndex: 0,
      polygonList: [],
      polygonLabelList: [],

      isShowMapType: false,

      // 坐标点
      point: null,

      // 右边tab
      rightTabs: [
        {
          id: 0,
          name: "土地",
          src: require("@/assets/image/analysis/land.png"),
        },
        {
          id: 1,
          name: "小区",
          src: require("@/assets/image/analysis/community.png"),
        },
        // {
        //     id: 2,
        //     name: '项目',
        //     src: require('@/assets/image/analysis/project.png')
        // },
        {
          id: 3,
          name: "配套",
          src: require("@/assets/image/analysis/land.png"),
        },
      ],
      rightComponent: "",

      overlayCloseIcon: require("@/assets/image/map/overLay_close.svg"),
      closeIcon: require("@/assets/image/map/close_white.png"),
      closeIconBlack: require("@/assets/image/map/close.png"),
      liveIcon: require("@/assets/image/map/live.png"),

      pointList: [],
      markerList: [],
      infoBoxList: [],
      includesIndex: [],
      nearbyMarkers: [],

      // addprojectmodal参数
      address: {},
      addProjectId: -1,

      // 圆圈样式
      circleOptions: {
        strokeStyle: "dashed",
        fillColor: "transparent",
      },
    };
  },
  computed: {
    switchText() {
      return this.isHide ? "周边" : "隐藏";
    },
    leftComponet() {
      switch (this.selectedTab) {
        case 0:
          return "DetailLeft";
        case 1:
          return "PlanViewLeft";
        case 2:
          return "LawLeft";
        case 3:
          return "EconomicLeft";
        case 4:
          return "LandMarketLeft";
        case 5:
          return "MarketAnalysisLeft";
        case 6:
          return "NearbyLeft";
      }
      return "";
    },

    projectDetail() {
      return this.$store.state.project.projectDetail;
    },

    projectName() {
      return this.projectDetail ? this.projectDetail.xmmc : "未选择项目";
    },

    landList() {
      return this.$store.state.project.landList;
    },

    lpList: {
      get() {
        return this.$store.state.project.lpList;
      },
      set(val) {
        this.$store.state.project.lpList = val;
      },
    },

    nearbyList: {
      get() {
        return this.$store.state.project.nearbyList;
      },
      set(value) {
        this.$store.state.project.nearbyList = value;
      },
    },

    landListOrigin() {
      return this.$store.state.project.landListOrigin;
    },

    lpListOrigin() {
      return this.$store.state.project.lpListOrigin;
    },

    infoBoxOptionsLp() {
      return {
        boxStyle: {
          width: "300px",
          color: "#fff",
          position: "relative",
          cursor: "pointer",
          border: "1px solid #e0e0e0",
          "text-align": "center",
          "font-size": "12px",
        },
        closeIconMargin: "4px 16px 0 0",
        enableAutoPan: false,
        offset: new BMap.Size(0, 0),
      };
    },
    infoBoxOptionsTd() {
      return {
        boxStyle: {
          background: "#fff",
          width: "272px",
          height: "138px",
          padding: "16px 14px 14px 12px",
          cursor: "default",
          "font-size": "12px",
        },
        closeIconMargin: "4px 16px 0 0",
        enableAutoPan: true,
        offset: new BMap.Size(0, 20),
      };
    },

    nearbyListOrigin: {
      get() {
        return this.$store.state.project.nearbyListOrigin;
      },
      set(value) {
        this.$store.state.project.nearbyListOrigin = value;
      },
    },

    isLoadingLand: {
      get() {
        return this.$store.state.project.isLoadingLand;
      },
      set(value) {
        this.$store.state.project.isLoadingLand = value;
      },
    },

    showProjectModal: {
      get() {
        return this.$store.state.modal.showProjectModal;
      },
      set(newValue) {
        this.$store.state.modal.showProjectModal = newValue;
      },
    },

    selectedTab: {
      get() {
        return this.$store.state.project.selectedTab;
      },
      set(newValue) {
        this.$store.state.project.selectedTab = newValue;
      },
    },

    map: {
      get() {
        return this.$store.state.project.map;
      },
      set(newValue) {
        this.$store.state.project.map = newValue;
      },
    },
    showAddProjectModal: {
      get() {
        return this.$store.state.modal.showAddProjectModal;
      },
      set(value) {
        this.$store.state.modal.showAddProjectModal = value;
      },
    },
  },
  watch: {
    projectDetail(value, oldValue) {
      if (value) {
        let resultArea;
        if (!value.jd || !value.wd) {
          // this.$Message.warning("坐标信息不全");
          this.$msg.error({ text: "坐标数据不全，无法定位。" });

          return;
        } else {
          this.map.clearOverlays();
          this.point = new BMap.Point(value.jd, value.wd);
          const icon = new BMap.Icon(this.liveIcon, new BMap.Size(50, 100));
          icon.setSize(new BMap.Size(50, 50));
          icon.setImageSize(new BMap.Size(50, 50));
          const marker = new BMap.Marker(this.point, { icon });
          this.map.centerAndZoom(this.point, this.mapData.zoom);
          this.map.addOverlay(marker);
          let myPoint = this.point;
          marker.addEventListener("click", function () {
            this.map.centerAndZoom(myPoint, 14);
          });

          const center = this.getCenterPoint(JSON.parse(value.zdyjson));

          var opts = {
            position: center, // 指定文本标注所在的地理位置
            offset: new BMap.Size(-60, 10), //设置文本偏移量
          };
        }

        if (value.zdyjson) {
          try {
            let polygonStyleOptions = {
              strokeColor: "red", //边线颜色。
              fillColor: "", //填充颜色。当参数为空时，圆形将没有填充效果。
              strokeWeight: 2, //边线的宽度，以像素为单位。
              strokeOpacity: 1, //边线透明度，取值范围0 - 1。
              fillOpacity: 1, //填充的透明度，取值范围0 - 1。
              strokeStyle: "dashed", //边线的样式，solid或dashed。
            };
            const polygon = new BMap.Polygon(
              JSON.parse(value.zdyjson).map((item) => {
                return new BMap.Point(item.lng, item.lat);
              }),
              polygonStyleOptions
            );
            resultArea = Number(
              BMapLib.GeoUtils.getPolygonArea(polygon).toFixed(2)
            );
            const labelContent = `
            <div>项目名称：${value.xmmc}</div>
            <div>面积：${resultArea}m²</div>
            `;
            const label = new BMap.Label(labelContent, opts); // 创建文本标注对象
            label.setStyle({
              color: "red",
              fontSize: "12px",
              height: "40px",
              lineHeight: "20px",
              padding: "0 6px",
              fontFamily: "微软雅黑",
              "box-sizing": "border-box",
            });
            this.map.addOverlay(label);
            this.map.addOverlay(polygon);
          } catch (error) {
            // this.$msg.error("区域信息解析失败");
            this.$msg.error({ text: "区域信息解析失败" });
          }
        }

        // this.getLandData();
        if (oldValue && value.id !== oldValue.id) {
          this.rightComponent = "";
          this.selectedTab = -1;
          this.selectedRightTab = -1;
        }
      }
    },

    selectedRightTab(value) {
      switch (value) {
        case 0:
          this.radius = "3";
          this.getLandData();
          break;
        case 1:
        case 2:
          this.radius = "5";
          // this.getLpData();
          break;
        case 3:
          this.radius = "2";
          this.getNearbyList();
          break;
        default:
          break;
      }
    },
  },
  beforeMount() {
    this.$Notice.destroy();
  },
  mounted() {
    this.getProjectDetail();
    this.loadMap(BMap);
    this.$store.dispatch("project/initProjectData");


  },
  destroyed() {
    if (this.showProjectModal) {
      this.showProjectModal = false;
    }
  },
  methods: {
    loadMap(BMap) {
      const point = this.point
        ? this.point
        : new BMap.Point(this.mapData.center.lng, this.mapData.center.lat);
      this.map = new BMap.Map("map");
      this.map.centerAndZoom(point, this.mapData.zoom);
      this.map.enableScrollWheelZoom();

      this.stCtrl = new BMap.PanoramaControl(); //构造全景控件
      this.stCtrl.setOffset(new BMap.Size(20, 20));
      this.map.addControl(this.stCtrl); //添加全景控件

      this.isMapReady = true;
      this.initDrawingManager();
      this.initRanging(); // 初始化测距
    },
    getPosition() {
      const callback = (result) => {
        this.map.setCenter(result.name);
      };
      const myCity = new BMap.LocalCity();
      myCity.get(callback);
    },
    // 初始化地图收手绘工具
    initDrawingManager() {
      const styleOptions = {
        strokeColor: "red", //边线颜色。
        fillColor: "#fff", //填充颜色。当参数为空时，圆形将没有填充效果。
        strokeWeight: 3, //边线的宽度，以像素为单位。
        strokeOpacity: 0.8, //边线透明度，取值范围0 - 1。
        fillOpacity: 0.8, //填充的透明度，取值范围0 - 1。
        strokeStyle: "solid", //边线的样式，solid或dashed。
      };

      this.drawingManager = new BMapLib.DrawingManager(this.map, {
        isOpen: false, //是否开启绘制模式
        enableDrawingTool: false, //是否显示工具栏
        drawingToolOptions: {
          offset: new BMap.Size(5, 5), //偏离值
        },
        circleOptions: styleOptions, //圆的样式
        polylineOptions: styleOptions, //线的样式
        polygonOptions: styleOptions, //多边形的样式
        rectangleOptions: styleOptions, //矩形的样式
      });
    },
    initRanging() {
      this.mapDis = new BMapLib.DistanceTool(this.map);

      this.mapDis.addEventListener("ondrawend", () => {
        this.isRangingOpen = false;
      });
    },
    // 控框搜索
    dropSearch() {
      const self = this;

      this.resetMeasureArea();
      this.mapDis.close();
      this.isShowRadiusSearch = false;

      if (!this.projectDetail) {
        // this.$Message.warning("请先选择项目");
        this.$msg.error({
          type: "remind",
          code: 4025,
          cb: () => {
            this.$refs.header.handleMyProjectClick();
          },
          timeout: 0,
        });

        return;
      }

      this.isPullBoxOpen = !this.isPullBoxOpen;

      this.drawingManager.setDrawingMode(BMAP_DRAWING_POLYGON);
      this.drawingManager.disableCalculate();

      this.drawingManager.addEventListener(
        "overlaycomplete",
        this.dropSearchCb
      );
      this.isPullBoxOpen
        ? this.drawingManager.open()
        : this.drawingManager.close();
    },

    dropSearchCb(e) {
      const pts = e.overlay.Tn;
      const ply = new BMap.Polygon(pts);
      this.map.removeOverlay(e.overlay);

      let mapSearchList;
      this.includesIndex = [];

      switch (this.selectedRightTab) {
        case 0:
          mapSearchList = this.landListOrigin.list;
          break;
        case 1:
        case 2:
          mapSearchList = this.lpListOrigin.list;
          break;
        case 3:
          mapSearchList = this.nearbyListOrigin;
          break;
        default:
          mapSearchList = [];
          break;
      }

      this.markerList.forEach((item) => {
        this.map.removeOverlay(item);
      });
      mapSearchList.forEach((item, index) => {
        const point = new BMap.Point(item.jd, item.wd);

        const result = BMapLib.GeoUtils.isPointInPolygon(point, ply);
        if (result) {
          this.includesIndex.push(index);
          const marker = new BMap.Marker(point);
          this.map.addOverlay(marker);
          this.markerList.push(marker);

          marker.addEventListener("click", function () {
            const content = `
                            <div class="info-box-inner">
                                <div class="title">区域名称</div>
                                <div class="name" title="${
                                  item.xmmc || item.title
                                }">${item.xmmc || item.title}</div>
                            </div>
                        `;

            const infoBox = new BMapLib.InfoBox(this.map, content, {
              boxStyle: {
                background: "#FF3737",
                width: "164px",
                height: "49px",
                color: "#fff",
                position: "relative",
                cursor: "pointer",
                "padding-top": "6px",
                "text-align": "center",
                "font-size": "12px",
                "border-radius": "14px",
              },
              closeIconUrl: self.closeIcon,
              closeIconMargin: "0px 14px 0 0",
              enableAutoPan: false,
              offset: new BMap.Size(0, 20),
            });

            if (marker.open) {
              marker.open = false;
              infoBox.close();
            } else {
              marker.open = true;
              infoBox.open(marker);
            }
          });
        }
      });

      if (this.selectedRightTab === 1 || this.selectedRightTab === 2) {
        this.lpList.list = this.lpListOrigin.list.filter((item, index) =>
          this.includesIndex.includes(index)
        );
      } else if (this.selectedRightTab === 0) {
        this.landList.list = this.landListOrigin.list.filter((item, index) =>
          this.includesIndex.includes(index)
        );
      } else {
        this.nearbyList = this.nearbyListOrigin.filter((item, index) =>
          this.includesIndex.includes(index)
        );
      }

      this.isPullBoxOpen = false;
      this.drawingManager.removeEventListener(
        "overlaycomplete",
        this.dropSearchCb
      );
    },

    // 半径搜索
    showRadiusSearch() {
      this.isShowRadiusSearch = true;
    },
    closeRadiusSearch() {
      this.isShowRadiusSearch = false;
    },
    radiusSearch() {
      const self = this;
      this.resetMeasureArea();
      this.clearOverlay();

      this.includesIndex = [];
      let mapSearchList;

      if (!this.radius) {
        // this.$Message.warning("请输入完成信息");
        this.$msg.error({ type: "remind", code: 4047 });

        return;
      }
      if (this.radius > 5) {
        this.$msg.error({ type: "remind", code: 4048 });
        this.radius = 5;
        return;
      }
      if (!/^-?\d+(\.\d+)?$/.test(this.radius)) {
        // this.$Message.warning('请输入正确的数字')
        this.$msg.error({ type: "remind", code: 4046 });
        return;
      }
      if (!this.point) {
        // this.$Message.warning("请选择中心点");
        this.$msg.error({ text: "请先选择或创建一个项目" });
        return;
      }

      this.clearCircle();

      switch (this.selectedRightTab) {
        case 0:
          mapSearchList = this.landListOrigin.list;
          break;
        case 1:
        case 2:
          mapSearchList = this.lpListOrigin.list;
          break;
        case 3:
          mapSearchList = this.nearbyListOrigin;
          break;
        default:
          mapSearchList = [];
          break;
      }

      this.setMarker(mapSearchList, this.radius, true);

      if (this.selectedRightTab === 1 || this.selectedRightTab === 2) {
        this.lpList.list = this.lpListOrigin.list.filter((item, index) =>
          this.includesIndex.includes(index)
        );
        this.getLpData();
      } else if (this.selectedRightTab === 0) {
        this.landList.list = this.landListOrigin.list.filter((item, index) =>
          this.includesIndex.includes(index)
        );
      } else {
        this.nearbyList = this.nearbyListOrigin.filter((item, index) =>
          this.includesIndex.includes(index)
        );

        this.getNearbyList(this.radiusInputVal, this.radiusKeyWord);
      }
    },

    // 测距
    ranging() {
      this.resetMeasureArea();
      this.isShowRadiusSearch = false;
      this.isRangingOpen = !this.isRangingOpen;

      this.isRangingOpen ? this.mapDis.open() : this.mapDis.close();
    },

    // 测面积
    measureArea() {
      const self = this;
      this.mapDis.close();
      this.isShowRadiusSearch = false;
      this.isMeasureArea = !this.isMeasureArea;
      this.drawingManager.setDrawingMode(BMAP_DRAWING_POLYGON);
      this.drawingManager.enableCalculate();

      this.drawingManager.addEventListener("overlaycomplete", measureFn);
      this.isMeasureArea
        ? this.drawingManager.open()
        : this.drawingManager.close();

      function measureFn(e) {
        if (e.calculate === null) {
          // self.$Message.warning("无法测量");
          self.$msg.success({ text: "框选区域无效，请重新框选" });

          self.map.removeOverlay(e.overlay);
          return;
        }

        e.label.ba.innerHTML = `
                    <span>区域面积：${e.calculate}m²</span>
                    <img id="polygonClose_${self.polygonIndex}" style="width:13px;height:13px;margin-left:4px;margin-right:4px;vertical-align:text-top;cursor:pointer" src="${self.overlayCloseIcon}" />
                `;

        self.polygonList.push({
          index: self.polygonIndex,
          overlay: e.overlay,
        });
        self.polygonLabelList.push({
          index: self.polygonIndex,
          overlay: e.label,
        });

        $(`#polygonClose_${self.polygonIndex}`).click((e) => {
          const index = Number($(e.target).attr("id").match(/\d/)[0]);
          self.polygonList.some((item, idx) => {
            if (item.index === index) {
              self.map.removeOverlay(item.overlay);
              self.polygonList.splice(idx, 1);
              return true;
            }
          });
          self.polygonLabelList.some((item, idx) => {
            if (item.index === index) {
              self.map.removeOverlay(item.overlay);
              self.polygonLabelList.splice(idx, 1);
              return true;
            }
          });
        });

        self.isMeasureArea = false;
        self.polygonIndex++;

        self.drawingManager.removeEventListener("overlaycomplete", measureFn);
      }
    },
    resetMeasureArea() {
      this.drawingManager.close();
      this.isMeasureArea = false;
    },
    clearCircle() {
      if (this.radiusOverlay.length > 0) {
        this.radiusOverlay.forEach((circle) => {
          this.map.removeOverlay(circle);
        });
      }
    },
    clearNearby() {
      if (this.nearbyMarkers.length > 0) {
        this.nearbyMarkers.forEach((marker) => {
          this.map.removeOverlay(marker);
        });
      }
    },
    clearInfoBox() {
      if (this.infoBoxList.length > 0) {
        this.infoBoxList.forEach((infoBox) => {
          this.map.removeOverlay(infoBox);
        });
        this.infoBoxList = [];
      }
    },
    clearOverlay() {
      this.clearCircle();
      this.clearNearby();
      this.clearInfoBox();
      this.polygonList.forEach((item) => {
        this.map.removeOverlay(item.overlay);
      });
      this.polygonList = [];
      this.polygonLabelList.forEach((item) => {
        this.map.removeOverlay(item.overlay);
      });
      this.polygonLabelList = [];

      this.markerList.forEach((item) => {
        this.map.removeOverlay(item);
      });

      const mapDisList = [
        ...document.querySelectorAll("[title='清除本次测距']"),
      ];
      mapDisList.forEach((item) => {
        item.click();
      });
    },
    changeMapType(type) {
      switch (type) {
        case 0:
          this.removePanorama();
          this.map.setMapType(BMAP_NORMAL_MAP);
          break;
        case 1:
          this.removePanorama();
          this.map.setMapType(BMAP_SATELLITE_MAP);
          break;
        case 2: {
          this.panoramaCoverageLayer = new BMap.PanoramaCoverageLayer();
          this.map.addTileLayer(this.panoramaCoverageLayer);
          break;
        }
      }
    },
    removePanorama() {
      this.map.removeTileLayer(this.panoramaCoverageLayer);
      this.map.removeControl(this.stCtrl);
    },
    chooseLeftcomponet(item) {
      if (!this.projectDetail) {
        // this.$Message.warning("请先选择项目");
        this.$msg.error({
          type: "remind",
          code: 4025,
          cb: () => {
            this.$refs.header.handleMyProjectClick();
          },
          timeout: 0,
        });

        return;
      }

      const id = Number(item.id);
      this.selectedTab = id;
    },
    handleRightTabClick(item) {
      if (!this.projectDetail) {
        // this.$Message.warning("请先选择项目");
        this.$msg.error({
          type: "remind",
          code: 4025,
          cb: () => {
            this.$refs.header.handleMyProjectClick();
          },
          timeout: 0,
        });

        return;
      }

      this.clearOverlay();

      switch (item.id) {
        case 0:
          this.rightComponent =
            this.rightComponent === "landRight" ? "" : "landRight";
          break;
        case 1:
          this.rightComponent =
            this.rightComponent === "communityRight" ? "" : "communityRight";
          break;
        case 2:
          this.rightComponent =
            this.rightComponent === "projectRight" ? "" : "projectRight";
          break;
        case 3:
          this.rightComponent =
            this.rightComponent === "nearbyRight" ? "" : "nearbyRight";
          break;
      }

      if (this.selectedRightTab === item.id) {
        this.selectedRightTab = -1;
      } else {
        this.selectedRightTab = item.id;
      }
    },

    backToMenu() {
      this.selectedTab = -1;
    },

    getLandData(params) {
      this.isLoadingLand = true;

      params = params || {
        shengId: this.projectDetail.tdsheng,
        quId: this.projectDetail.tdqu,
        shiId: this.projectDetail.tdshi,
      };

      zbtd(params).then((res) => {
        if (this.selectedRightTab === 0 || this.selectedRightTab === -1) {
          const map = new BMap.Map("tmpMap");
          const projectPoint = new BMap.Point(
            this.projectDetail.jd,
            this.projectDetail.wd
          );
          map.centerAndZoom(projectPoint, 12);

          const list = res
            .filter((item) => {
              const point = new BMap.Point(item.jd, item.wd);

              const distance = map.getDistance(point, projectPoint);
              item.distance = distance;
              return (
                !item.ppbm.includes("NULL") && distance <= this.radius * 1000
              );
            })
            .map((item) => {
              item.cjlmj = Math.floor(item.cjlmj);
              item.cjj = Math.round(fixNumber(item.cjj || 0) / 10000);
              return item;
            });

          list.sort((a, b) => {
            return (
              (new Date(a.gpkssj).getTime() || "") -
              (new Date(b.gpkssj).getTime() || "")
            );
          });

          this.setMarker(list, 3);

          this.$store.commit("project/setState", {
            prop: "landList",
            value: {
              list,
              total: list.length,
            },
          });
          this.$store.commit("project/setState", {
            prop: "landListOrigin",
            value: {
              list,
              total: list.length,
            },
          });

          this.isLoadingLand = false;
        }
      });
    },

    getLpData(params) {
      params = params || {
        shengId: this.projectDetail.sheng,
        quId: this.projectDetail.qu,
        shiId: this.projectDetail.shi,
        propertyType: [],
        saleStatus: [],
        xqmc: "",
        quotationInterval: [],
        sj: [],
      };
      // if(init){
      //   params.propertyType=[1]
      // }

      zblp(params).then((res) => {
        if (this.selectedRightTab === 1 || this.selectedRightTab === 2) {
          const map = new BMap.Map("tmpMap");
          const projectPoint = new BMap.Point(
            this.projectDetail.jd,
            this.projectDetail.wd
          );
          map.centerAndZoom(projectPoint, 12);

          const list = res.sort((a, b) => {
            return a.xmmc.localeCompare(b.xmmc);
          });
          const result = res
            .sort((a, b) => {
              return a.xmmc.localeCompare(b.xmmc);
            })
            .filter((item) => {
              const point = new BMap.Point(item.jd, item.wd);
              const distance = map.getDistance(point, projectPoint);
              item.distance = distance;
              return distance <= this.radius * 1000;
            });
          this.setMarker(result, 3);
          this.$store.commit("project/setState", {
            prop: "lpList",
            value: {
              list: result,
              total: result.length,
            },
          });
          this.$store.commit("project/setState", {
            prop: "lpListOrigin",
            value: {
              list: result,
              total: result.length,
            },
          });
        }
      });
    },
    //搜索周边配套
    getNearbyList(inputVal, keyword) {
      if (!this.projectDetail) {
        // this.$Message.warning(`请先选择项目`);
        this.$msg.error({
          type: "remind",
          code: 4025,
          cb: () => {
            this.$refs.header.handleMyProjectClick();
          },
          timeout: 0,
        });

        return;
      }

      let lng;
      let lat;

      if (!this.projectDetail.jd || !this.projectDetail.wd) {
        if (this.projectDetail.zdyjson) {
          lng = JSON.parse(this.projectDetail.zdyjson)[0].lng;
          lat = JSON.parse(this.projectDetail.zdyjson)[0].lat;
        }
      } else {
        lng = this.projectDetail.jd;
        lat = this.projectDetail.wd;
      }

      if (!lng || !lat) {
        // this.$Message.warning(`经纬度信息不全`);
        this.$msg.error({ text: "经纬度信息不全" });

        return;
      }
      const myPoint = new BMap.Point(lng, lat);

      this.clearOverlay();

      keyword = keyword || [
        "地铁",
        "火车站",
        "高铁站",
        "汽车站",
        "飞机场",
        "港口",
        "停车场",
        "加油站",
        "公交站",
        "轻轨",
      ];
      const circle = new BMap.Circle(
        this.point,
        this.radius * 1000,
        this.circleOptions
      );
      this.map.addOverlay(circle); //添加一个圆形覆盖物
      this.radiusOverlay.push(circle);

      this.nearbySearch = new BMap.LocalSearch(this.point, {
        renderOptions: {
          map: this.map,
          autoViewport: false,
          selectFirstResult: false,
        },
        pageCapacity: 70,
        onSearchComplete: (result) => {
          //判断搜索结果，若不能返回数据，则提示重新搜索
          if (!Array.isArray(result)) {
            // this.$msg.error("搜索结果过多，请缩小搜索范围再试");
            this.$msg.error({ type: "remind", code: 4039 });

            return;
          }
        },
        onMarkersSet: (result) => {
          if (!result) return;
          this.nearbyList = [];
          this.nearbyListOrigin = [];
          result.forEach((item) => {
            this.nearbyMarkers.push(item.marker);
          });

          // if (Array.isArray(result)) {
          //     result.forEach((item, i) => {
          //         i !== 0 && (result[0] = [...result[0], ...item]);
          //     });
          //     result = result[0];
          // }
          result.forEach((item) => {
            const point = new BMap.Point(item.point.lng, item.point.lat);
            item.juli = +this.map.getDistance(point, this.point).toFixed(2);
          });

          // 去重
          let newRes = [];
          for (let i = 0; i < result.length; i++) {
            if (
              newRes.findIndex(
                (nr) =>
                  nr.point.lng === result[i].point.lng &&
                  nr.point.lat === result[i].point.lat
              ) < 0
            ) {
              newRes.push(result[i]);
            }
          }

          // newRes = newRes.filter((item) => item.juli <= this.radius*1000);
          newRes.sort((a, b) => {
            return a.juli - b.juli;
          });

          const myPoint = new BMap.Point(lng, lat);
          newRes.forEach((item) => {
            const obj = {};
            obj.tag = item.tag;
            if (Array.isArray(item.tags) && item.tags.length > 0) {
              obj.tag = item.tags[item.tags.length - 1];
            }
            obj.id = item.uid;
            obj.title = item.title;
            obj.distance = item.juli;
            obj.jd = item.point.lng;
            obj.wd = item.point.lat;
            obj.marker = item.marker;
            obj.tags = item.tags;
            if (inputVal) {
              //存起搜索关键字
              this.radiusInputVal = inputVal;
              if (item.title.includes(inputVal)) {
                this.nearbyList.push(obj);
                this.nearbyListOrigin.push(obj);
              }
            } else {
              //清空搜索关键字
              this.radiusInputVal = null;
              this.nearbyList.push(obj);
              this.nearbyListOrigin.push(obj);
            }
          });
        },
      });

      // let len = keyword.length;
      // let n = 0;
      // while (len > 10) {
      //   this.nearbySearch.searchNearby(keyword.slice(n, n + 10), myPoint, this.radius*1000);
      //   n += 10;
      //   len -= 10;
      // }
      // this.nearbySearch.searchNearby(keyword.slice(n, len - 1), myPoint, this.radius*1000);
      this.radiusKeyWord = keyword;
      this.nearbySearch.searchNearby(
        this.radiusKeyWord,
        this.point,
        this.radius * 1000
      );
    },

    // 区域内打点
    setMarker(data, radius = 5, isMapTool) {
      this.clearOverlay();

      if (data.length === 0) return;

      const self = this;
      const circle = new BMap.Circle(
        this.point,
        this.radius * 1000,
        this.circleOptions
      );
      let content = "";

      this.map.addOverlay(circle); //添加一个圆形覆盖物
      this.radiusOverlay.push(circle);
      this.markerList = [];

      data.forEach((item, index) => {
        const point = new BMap.Point(item.jd, item.wd);

        if (isMapTool) {
          this.includesIndex.push(index);
        }

        const result = BMapLib.GeoUtils.isPointInCircle(point, circle);
        if (result) {
          const marker = new BMap.Marker(point);
          this.map.addOverlay(marker);
          this.markerList.push(marker);

          marker.addEventListener("click", function () {
            self.clearInfoBox();

            if (self.selectedRightTab == 1) {
              // 小区
              content = self.getLpContent(item);
              const infoBox = new BMapLib.InfoBox(
                self.map,
                content,
                self.infoBoxOptionsLp
              );
              self.infoBoxList.push(infoBox);

              infoBox.addEventListener("open", () => {
                const $closeBtn = $(".info-box-inner-lp .close");

                $closeBtn.on("click", () => {
                  infoBox.close();
                  marker.open = false;
                });
              });

              if (marker.open) {
                marker.open = false;
                infoBox.close();
              } else {
                marker.open = true;
                infoBox.open(marker);
              }
            } else if (self.selectedRightTab == 0) {
              // 土地
              content = self.getTdContent(item);

              const infoBox = new BMapLib.InfoBox(
                self.map,
                content,
                self.infoBoxOptionsTd
              );
              self.infoBoxList.push(infoBox);

              if (marker.open) {
                marker.open = false;
                infoBox.close();
              } else {
                marker.open = true;
                infoBox.open(marker);
              }

              infoBox.addEventListener("open", () => {
                const $tabs = $("#tabs");
                const $closeBtn = $(".info-box-inner-td .close");
                const $toDetail = $(".info-box-inner-td .tool .detail");
                const $lookPro = $(".info-box-inner-td .lookProject");

                $lookPro.on("click", () => {
                  self.addProjectId = item.id;
                  self.address.lng = item.jd;
                  self.address.lat = item.wd;
                  self.showAddProjectModal = true;
                });

                $toDetail.on("click", () => {
                  const { href } = self.$router.resolve({
                    path: `/Insight/PlaceDetail`,
                    query: {
                      id: item.id,
                      type: "option",
                    },
                  });
                  window.open(href);
                });
                $closeBtn.on("click", () => {
                  infoBox.close();
                  marker.open = false;
                });
              });
            }
          });
        }
      });
    },

    openInfoBox(data) {
      let content = "";
      let infoBox = null;
      if (this.selectedRightTab == 1) {
        // 小区
        content = this.getLpContent(data);

        infoBox = new BMapLib.InfoBox(this.map, content, this.infoBoxOptionsLp);
        infoBox.addEventListener("open", () => {
          const $closeBtn = $(".info-box-inner-lp .close");

          $closeBtn.on("click", () => {
            infoBox.close();
            marker.open = false;
          });
        });
      } else if (this.selectedRightTab == 0) {
        // 土地
        content = this.getTdContent(data);

        infoBox = new BMapLib.InfoBox(this.map, content, this.infoBoxOptionsTd);

        infoBox.addEventListener("open", () => {
          const $tabs = $("#tabs");
          const $closeBtn = $(".info-box-inner-td .close");
          const $toDetail = $(".info-box-inner-td .tool .detail");
          const $lookPro = $(".info-box-inner-td .lookProject");

          $lookPro.on("click", () => {
            self.addProjectId = data.id;
            self.address.lng = data.jd;
            self.address.lat = data.wd;
            self.showAddProjectModal = true;
          });

          $toDetail.on("click", () => {
            const { href } = self.$router.resolve({
              path: `/Insight/PlaceDetail`,
              query: {
                id: data.id,
                type: "option",
              },
            });
            window.open(href);
          });
          $closeBtn.on("click", () => {
            infoBox.close();
            marker.open = false;
          });
        });
      }

      const marker = this.markerList.find(
        (marker) => data.jd == marker.point.lng && data.wd == marker.point.lat
      );

      this.clearInfoBox();

      this.infoBoxList.push(infoBox);

      marker.open = true;
      infoBox.open(marker);
    },

    getCenterPoint(path) {
      var x = 0.0;
      var y = 0.0;
      for (var i = 0; i < path.length; i++) {
        x = x + parseFloat(path[i].lng);
        y = y + parseFloat(path[i].lat);
      }
      x = x / path.length;
      y = y / path.length;

      return new BMap.Point(x, y);
    },

    getProjectDetail() {
      if (!this.$route.query.id) {
        this.getPosition();
        return;
      }
      getProjectDetail({
        id: this.$route.query.id,
      }).then((res) => {
        this.$store.commit("project/setState", {
          prop: "projectDetail",
          value: res,
        });
        this.$store.commit("project/getProjectType", res.xmlx);
      });
    },

    handleMyProjectClick() {
      this.selectedTab = -1;
      this.selectedRightTab = -1;
      this.rightComponent = "";
    },

    getLpContent(data) {
      const xfjg = data.xfjg || "--";
      const esfj = data.esfj || "--";
      const zjjg = data.zjjg || "--";
      const distance = data.distance.toFixed(0) || "--";
      return `
                <div class="info-box-inner-lp">
                    <img class="close" src="${this.closeIconBlack}"/>
                    <div class="name" title="${data.xmmc || data.title}">
                        ${data.xmmc || data.title}
                    </div>
                    <div class="xin">
                        新：${xfjg}元/m²
                    </div>
                    <div class="er">
                        二：${esfj}元/m²
                    </div>
                    <div class="zu">
                        租：${zjjg}元/m²/月
                    </div>
                     <div class="zu">
                        距离本案：${distance}米
                    </div>
                </div>
            `;
    },

    getTdContent(data) {
      return `
                <div class="info-box-inner-td">
                    <div class="title">
                        <div class="text">
                            <span title="${data.ppbm}">${data.ppbm}</span>
                            [${data.stateText}]
                        </div>
                        <div class="close">
                            <img src="${this.closeIconBlack}"/>
                        </div>
                    </div>
                    <div class="info">
                        <div class="row">
                            <div class="col" :title="item.tdytCr">土地用途：${
                              data.tdyt
                            }</div>
                            <div class="col price"><span>${
                              data.cjlmj
                            }</span>元/m²</div>
                        </div>
                        <div class="row">
                            <div class="col" title="${data.dkwz}">地址：${
        data.dkwz
      }</div>
                        </div>
                         <div class="row">
                            <div class="col" title="距离本案：${data.distance.toFixed(
                              0
                            )}米">距离本案：${data.distance.toFixed(0)}米</div>
                        </div>
                    </div>
                    <div class="tool">
                        <a class="item detail">查看详情</a>
                        <a class="item lookProject" >看项目</a>
                    </div>
                </div>
            `;
    },
    changeHide() {
      this.isHide = !this.isHide;
    },
    switchEnter(e) {
      if (!this.isHide) return;
      this.tabStyle = {
        right: `20px`,
      };
      this.switchStyle = {
        left: "100px",
      };
    },
    switchLeave(e) {
      if (!this.isHide) return;
      this.tabStyle = {};
      this.switchStyle = {};
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.analysis {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .content {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 0 20px;
    position: relative;

    .map-container {
      width: 100%;
      height: 100%;
    }

    .aside-left {
      width: 120px;
      height: 100%;
      margin-left: 20px;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #d6d7d9;
      z-index: 1;

      &.component {
        width: 500px;

        .svg-icon {
          position: fixed;
          left: 715px;
          top: 170px;
          fill: #1562d6;
          cursor: pointer;
          z-index: 99;
        }

          div{
            ::v-deep .content{
              // padding-top: 60px;
              // padding-left: 20px;
              // padding-right: 20px;
              // padding-bottom: 100px;
            }

          }

      }

      .tabs {
        height: 100%;
        padding-top: 40px;
        background-color: rgba($color: #fff, $alpha: 0.8);

        .tab {
          margin-bottom: 60px;
          font-size: 14px;
          color: #333;
          text-align: center;
          cursor: pointer;

          &:hover {
            color: #00b6ff;
          }
        }
      }

      .component {
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: #fff;
      }
    }

    .aside-right {
      width: 500px;
      height: 100%;
      margin-right: 20px;
      border: 1px solid #d6d7d9;
      position: absolute;
      top: 0;
      right: 0;
      border: 1px solid #d6d7d9;
      z-index: 1;
      background-color: #fff;

      .component {
        height: 100%;
      }
    }

    .tools {
      height: 40px;
      padding-left: 20px;
      position: absolute;
      top: 20px;
      left: 50%;
      display: flex;
      transform: translateX(-50%);
      background-color: #fff;
      border: 1px solid #d6d7d9;

      .label {
        line-height: 40px;
      }

      .options {
        display: flex;
        align-items: center;

        .label {
          margin-right: 10px;
        }

        .option {
          margin-right: 30px;
          cursor: pointer;

          &.radius {
            position: relative;

            .btn {
              .text {
                margin-right: 8px;
              }

              &.active {
                & /deep/ .ivu-icon {
                  transform: rotate(180deg);
                }
              }
            }

            .border {
              position: absolute;
              top: -5px;
              left: -10px;
              width: 94px;
              height: 32px;
              border: 1px solid rgb(177, 177, 177);
              border-radius: 4px 4px 0 0;

              &::after {
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: -1px;
                z-index: 12;
                background-color: #fff;
              }
            }

            .search-box {
              width: 150px;
              height: 46px;
              padding-left: 8px;
              padding-right: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid rgb(177, 177, 177);
              position: absolute;
              top: 26px;
              left: -10px;
              z-index: 11;
              background-color: #fff;
              font-size: 12px;

              .input-box {
                margin-right: 10px;

                &.km {
                  span {
                    margin-left: 5px;
                    vertical-align: middle;
                  }
                }

                & /deep/ .ivu-input {
                  font-size: 12px;
                }
              }
            }
          }

          &.disabled {
            color: #ccc;
            cursor: no-drop;
          }

          &:last-child {
            margin-right: 0;
          }

          &.clear {
            margin-right: 10px;
          }

          &.map-type {
            width: 82px;
            height: 100%;
            line-height: 40px;
            position: relative;
            text-align: center;

            &.active {
              border-left: 1px solid #d6d7d9;
            }

            .map-type-list {
              width: 102%;
              height: 0;
              padding-top: 0px;
              padding-left: 19px;
              list-style: none;
              position: absolute;
              top: 38px;
              left: -1px;
              background-color: #fff;
              text-align: left;
              overflow: hidden;
              border: 1px solid #d6d7d9;
              border-top-width: 0px;
              color: #333;

              .map-type-item {
                line-height: 30px;

                &:hover {
                  color: #00b6ff;
                }
              }

              &.show {
                height: 67px;
              }
            }
          }

          &:hover {
            color: #00b6ff;
          }
        }
      }
    }

    .right-tabs {
      width: 100px;
      position: absolute;
      right: 20px;
      top: 40%;
      transform: translateY(-50%);
      z-index: 1;
      transition: all 0.5s;
      &.hide {
        right: -100px;
      }
      .swicth {
        padding-top: 50px;
        position: absolute;
        width: 20px;
        height: 170px;
        line-height: 40px;
        background-size: 100%;
        background-color: #1562d6;
        top: 0px;
        left: 100px;
        color: white;
        text-align: center;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
          background-color: #1562d6;
          opacity: 1;
        }
        &.hide {
          color: #1562d6;
          font-weight: 900;
          left: -20px;
          background-color: transparent;
          box-shadow: 5px 0px 5px 0px gray;
          &:hover {
            opacity: 1;
            color: white;
            background-color: #1562d6;
          }
        }
      }
      .tab-item {
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        background-color: rgba(0, 0, 0, 0.4);
        color: #fff;
        line-height: 50px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .img {
          margin-right: 10px;
        }

        &.active {
          background-color: #1562d6;
        }

        &:hover {
          background-color: #1562d6;
        }
      }
    }
  }

  /deep/ .info-box-inner-td {
    position: relative;
    background-color: #fff;

    &::after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      bottom: -27px;
      transform: translateX(-50%);
      border-top: 9px solid #fff;
      border-right: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-left: 9px solid transparent;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: bold;
      color: #333;

      .text {
        max-width: 210px;
        display: flex;

        span {
          display: inline-block;
          max-width: 155px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .close {
        width: 10px;
        height: 10px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          vertical-align: text-top;
        }
      }
    }

    .info {
      margin-bottom: 12px;
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .col {
          max-width: 128px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.price {
            span {
              color: #ac1812;
              font-size: 14px;
              font-weight: bold;
            }
          }
        }
      }
    }

    .tool {
      margin-bottom: 8px;

      .item {
        margin-right: 16px;
      }
    }
  }

  /deep/ .info-box-inner-lp {
    position: relative;
    background-color: #fff;
    padding: 8px 10px 10px;
    color: #333;
    border-radius: "14px";
    text-align: left;

    .name {
      font-size: 18px;
    }

    .close {
      width: 10px;
      height: 10px;
      position: absolute;
      right: 8px;
      top: 10px;
    }
  }
}
</style>
