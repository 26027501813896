<template>
	<div>
		<Modal class="project-modal" footer-hide :closable="true" :mask-closable="false" :width="1200"
			v-model="showProjectModal" class-name="vertical-center-modal" mask>
			<div class="left">
				<p class="head">
					<span class="icon"></span>
					<span class="text">项目列表</span>
				</p>

				<div class="search-box">
					<Input v-model="projectKeyword" placeholder="请输入" style="width: 100%" @on-blur="searchInputBlur"
						@on-focus="searchInputFocus" />
					<ul class="tips-box" v-show="(isShowTipsBox && tipsList.length > 0) || isOverTips" ref="tipsBox"
						@mouseenter="enterTipsBox" @mouseleave="leaveTipsBox">
						<li class="tip" v-for="item in tipsList" :key="item.id" @click="handleTipsClick(item.id)">
							{{ item.xmmc }}
						</li>
					</ul>
					<span class="icon-box" @click="handleSearch">
						<Icon type="ios-search" color="#fff" :size="16" />
					</span>
				</div>

				<div class="table">
					<Table :max-height="740" tooltip-theme="light" :columns="columns" :data="projectList"></Table>
					<Page class-name="page" :current='current' :page-size='size' :total="projectTotal" @on-change="pageChange" />
				</div>
			</div>
			<div class="right">
				<p class="head">
					<span class="icon"></span>
					<span class="text">新建项目</span>
				</p>
				<div class="tabs">
					<span class="tab new-tab" @click="currentTab = 0">新项目</span>
					<span class="tab search-tab" @click="currentTab = 1">搜索项目
						<Icon type="ios-arrow-down" color="#aaa" :size="9" />
					</span>
					<div class="underline" :style="underlineStyle"></div>
				</div>

				<div class="options">
					<Row class-name="row" type="flex" justify="space-between">
						<Col class-name="col">
						<span class="require-icon">*</span>
						<span class="name">项目名称</span>
						<Input v-model="formData.xmmc" placeholder="请输入" style="width: 606px" />
						</Col>
					</Row>
				<!-- 	<Row class-name="row" type="flex" justify="space-between">
						<Col class-name="col location">
						<span class="require-icon">*</span>
						<span class="name">项目地址</span>
						<Select v-model="formData.province" style="width: 200px" label-in-value
							@on-change="selectProvince">
							<Option v-for="item in dimData.province" :value="item.id" :key="item.id">
								{{ item.districtName }}</Option>
						</Select>
						<Select v-model="formData.city" style="width: 200px" label-in-value @on-change="selectCity">
							<Option v-for="item in cityData" :value="item.id" :key="item.id">{{ item.districtName }}
							</Option>
						</Select>
						<Select v-model="formData.region" style="width: 200px" label-in-value @on-change="selectRegion">
							<Option v-for="item in regionData" :value="item.id" :key="item.id">{{ item.districtName }}
							</Option>
						</Select>
						</Col>
					</Row> -->
					<Row class-name="row" type="flex" justify="space-between">
						<Col class-name="col">
						<span class="require-icon">*</span>
						<span class="name">项目地址</span>
						<Input v-model="formData.xmwz" style="width: 606px" />
					<!-- 	<Select placeholder="请输入项目地址" size="large" clearable v-model="xmwz" filterable
							:remote-method="getAdressList" @on-select='selectAdress' :label-in-value='true'
							:loading="adressListLoading">
							<Option v-for="(option, index) in adressList" :value="option.value" :key="index">
								{{option.name}}
							</Option>
						</Select> -->
						</Col>
					</Row>
					<Row class-name="row path" type="flex" justify="space-between">
						<Col class-name="col">
						<span class="require-icon">*</span>
						<span class="name">地图定位</span>
						<span class="text" @click="openMapModal">
							<Icon type="ios-locate-outline" />{{selectAreaText}}
						</span>
						<!-- <p v-if="pathData">{{ pathData }}</p> -->
						</Col>
					</Row>
					<Row class-name="row" type="flex" justify="space-between">
						<Col class-name="col">
						<span class="require-icon">*</span>
						<span class="name">项目类型</span>
						<Select v-model="formData.zdyxmlx" style="width: 200px">
							<Option v-for="item in landTypeData" :value="item.id" :key="item.id">{{ item.name }}
							</Option>
						</Select>
						</Col>
						<Col class-name="col">
						<span class="name">项目性质</span>
						<Select v-model="formData.zdytdyt" style="width: 200px">
							<Option v-for="item in landUseList" :value="item.name" :key="item.id">{{ item.name }}
							</Option>
						</Select>
						</Col>
					</Row>
					<Row class-name="row area" type="flex" justify="space-between">
						<Col class-name="col">
						<span class="require-icon">*</span>
						<span class="name">用地面积</span>
						<Input v-model="formData.ydmj" placeholder="请输入" style="width: 200px" />
						<span class="unit">m²</span>
						</Col>
						<Col class-name="col">
						<span class="require-icon">*</span>
						<span class="name">总建筑面积</span>
						<Input v-model="formData.zjzmj" placeholder="请输入" style="width: 200px" />
						<span class="unit">m²</span>
						</Col>
					</Row>
					<Row class-name="row" type="flex" justify="space-between">
						<Col class-name="col">
						<span class="name">容积率</span>
						<Input v-model="formData.rjl" placeholder="请输入" style="width: 200px" />
						</Col>
						<Col class-name="col">
						<span class="name">终止日期</span>
						<Input v-model="formData.zzrq" placeholder="请输入" style="width: 200px" />
						</Col>
					</Row>
					<Row class-name="row" type="flex" justify="space-between">
						<Col class-name="col textarea">
						<span class="require-icon">*</span>
						<span class="name">项目现状</span>
						<Input v-model="formData.xz" type="textarea" :autosize="true" placeholder="请输入"
							style="width: 500px" />
						</Col>
					</Row>
					<Row class-name="row" type="flex" justify="space-between">
						<Col class-name="col textarea">
						<span class="name">项目简介</span>
						<Input v-model="formData.xmjj" type="textarea" :autosize="true" placeholder="请输入"
							style="width: 500px" />
						</Col>
					</Row>

					<div class="upload-file">
						<p class="text">上传相关文件</p>
						<div class="table">
							<Table :columns="uploadFileColumns" tooltip-theme="light" :data="formData.fileList"
								:min-height="130"></Table>
						</div>
					</div>

					<div class="btn-group">
						<Button type="primary" @click="addFile">添加文件</Button>
						<Button type="info" :loading='formLoading' @click="submit">创建项目</Button>
						<input class="file-input" type="file" ref="fileInput" @change="uploadFile" />
					</div>
				</div>

				<div :class="['search-project-box', { show: currentTab === 1 }]">
					<span class="tab" v-for="item in jumpList" :key="item.id" @click="jump(item)">{{ item.name }}</span>
				</div>
			</div>
		</Modal>

<!-- 		<mapModal @confirm="getLocation" :address="address" :city="city"></mapModal> -->
		<newMapModal @confirm="getLocation" :address="address"></newMapModal>
		<Modal class="detail-modal" title="描述" v-model="isShowDetailModal" class-name="vertical-center-modal"
			@on-ok="modifyFileDetail">
			<div class="content">
				<Input v-model="fileDetail" placeholder="请输入项目描述"></Input>
			</div>
		</Modal>
		<Modal v-model="deleteModal" width="360" footer-hide>
			<div style="text-align: center">
				<p style="font-size: 20px; margin: 5px 0 30px">
					<span>删除项目</span>
				</p>
				<p style="margin-bottom: 30px">确定要删除该项目？</p>
				<Button style="width: 80px" type="primary" @click="deleteProject(1)">确定</Button>
				<Button style="margin-left: 30px; width: 80px" @click="deleteProject(0)">取消</Button>
			</div>

			<!-- <Button size="small" type="info" @click="deleteProject(1)">确定</Button>
        <Button size="small" type="error" @click="deleteProject(0)">取消</Button> -->
		</Modal>
	</div>
</template>

<script>
	import SparkMD5 from "spark-md5";
	import {
		mapState
	} from "vuex";
	import {
		city,
		region,
		addProject,
		deleteProject,
		getProjectList,
		getProjectDetail,
	} from "@/api/projectAnalysis";
	import {
		uploadFile
	} from "@/api/public";
	import {
		formatDate,
		getFileType
	} from "@/utils/index";
	// import mapModal from "@/components/modal/mapModal";
	import newMapModal from "@/components/modal/newMapModal";

	import {
		debounce
	} from '@/utils/index.js'
	import {
		getAdress
	} from '../../../../api/apiBaidu.js'
	import JQ from "jquery"

	export default {
		name: "projectModal",

		components: {
			newMapModal
		},

		data() {
			const self = this;

			return {
				formLoading:false,
				xmwz:'',
				adressListLoading: false,
				adressList: [],
				deleteId: null,
				deleteModal: false,
				projectKeyword: "",
				columns: [{
						title: "序号",
						key: "id",
						align: "center",
					},
					{
						title: "项目名称",
						key: "xmmc",
						align: "center",
						width: 189,
						render(c, params) {
							return c(
								"div", {
									domProps: {
										title: params.row.xmmc,
									},
									style: {
										cursor: "pointer",
										color: "#00B6FF",
										height: "40px",
										overflow: "hidden",
										"white-space": "nowrap",
										"text-overflow": "ellipsis",
										"line-height": "40px",
									},
									on: {
										click: () => {
											getProjectDetail({
												id: params.row.id,
											}).then((res) => {
												self.$store.commit("project/setState", {
													prop: "projectDetail",
													value: res,
												});
												self.$store.commit("project/getProjectType", res.xmlx);
												self.showProjectModal = false;
											});
										},
									},
								},
								params.row.xmmc
							);
						},
					},
					{
						title: "创建日期",
						key: "gmtCreate",
						align: "center",
						width: 114,
					},
					{
						title: "操作",
						key: "option",
						align: "center",
						render: (h, params) => {
							return h("div", [
								h(
									"Button", {
										props: {
											type: "error",
											size: "small",
										},
										on: {
											click: () => {
												self.deleteId = params.row.id;
												self.deleteModal = true;
											},
										},
									},
									"删除"
								),
							]);
						},
					},
				],

				currentTab: 0,

				formData: {
					xmmc: "",
					shengName: "",
					shiName: "",
					quName: "",
					xmlx: 3,
					zdyxmlx: '',
					zdytdyt: "",
					ydmj: "",
					zjzmj: "",
					rjl: "",
					zzrq: "",
					xz: "",
					xmjj: "",
					fileList: [],
					jd: "",
					wd: "",
					xmwz: "",
					zdyjson: null,
					location:''
				},

				required: {
					xmmc: "项目名称",
					xmwz:'项目地址',
					zdyjson: "地图定位",
					ydmj: "用地面积",
					zjzmj: "总建筑面积",
					xz: "项目现状",
					zdyxmlx: "项目类型",
				},

				cityData: [],
				regionData: [],

				landTypeData: [{
						id: "土地",
						name: "土地",
					},
					{
						id: "在建物业",
						name: "在建物业",
					},
					{
						id: "建成物业",
						name: "建成物业",
					},
					{
						id: "城市更新",
						name: "城市更新",
					},
				],

				uploadFileColumns: [{
						title: "文件名称",
						key: "wjmc",
						align: "center",
						render(h, params) {
							return self.fileNameCheckArr[params.index] === 0 ?
								h(
									"span", {
										style: {
											overflow: "hidden",
											"white-space": "nowrap",
											"text-overflow": "ellipsis",
										},
										domProps: {
											title: params.row.name,
										},
										on: {
											click: () => {
												self.$set(self.fileNameCheckArr, params.index, 1);
											},
										},
									},
									params.row.wjmc
								) :
								h("Input", {
									props: {
										value: params.row.wjmc,
										size: "small",
									},
									on: {
										"on-focus": function(e) {
											e.target.select();
										},
										"on-blur": function(e) {
											self.$set(self.fileNameCheckArr, params.index, 0);
											self.formData.fileList[params.index].wjmc =
												e.target.value;
										},
									},
								});
						},
					},
					{
						title: "描述",
						key: "ms",
						align: "center",
						render(h, params) {
							return self.formData.fileList[params.index] &&
								self.formData.fileList[params.index].ms ?
								h("div", [
									h(
										"span", {
											style: {
												overflow: "hidden",
												"white-space": "nowrap",
												"text-overflow": "ellipsis",
												cursor: "pointer",
											},
											domProps: {
												title: "点击修改",
											},
											on: {
												click: () => {
													self.isShowDetailModal = true;
													self.selectFileIdx = params.index;
													self.fileDetail =
														self.formData.fileList[params.index].ms;
												},
											},
										},
										self.formData.fileList[params.index].ms
									),
								]) :
								h("div", [
									h(
										"Button", {
											props: {
												type: "primary",
												size: "small",
											},
											on: {
												click: () => {
													self.isShowDetailModal = true;
													self.selectFileIdx = params.index;
												},
											},
										},
										"添加描述"
									),
								]);
						},
					},
					{
						title: "操作",
						key: "option",
						align: "center",
						render: (h, params) => {
							return h(
								"Button", {
									props: {
										type: "error",
										size: "small",
									},
									on: {
										click: () => {
											self.fileNameCheckArr.splice(params.index, 1);
											self.formData.fileList.splice(params.index, 1);
										},
									},
								},
								"删除"
							);
						},
					},
				],

				jumpList: [{
						id: 0,
						name: "招拍挂地块",
						route: "/Insight/PlaceOptions",
					},
					// {
					//   id: 1,
					//   name: "楼盘",
					//   route: "/Insight/City/search/buildingSearch",
					// },
					// {
					//   id: 2,
					//   name: "交易所",
					// },
				],

				// mapModal数据
				pathData: "",
				province: "",
				city: "",
				region: "",

				// 项目列表相关数据
				size: 10,
				current: 1,

				projectList: [],
				projectTotal: 10,

				isShowDetailModal: false,
				fileDetail: "",
				selectFileIdx: -1,
				fileNameCheckArr: [],

				tipsTimer: null,
				tipsList: [],
				isShowTipsBox: false,
				isOverTips: false,
				resolveId: null,
				landUseList: [{
						id: 0,
						name: "综合",
					},
					{
						id: 1,
						name: "住宅",
					},
					{
						id: 2,
						name: "商服",
					},
					{
						id: 3,
						name: "商住",
					},
					{
						id: 4,
						name: "工业",
					},
					{
						id: 5,
						name: "其他",
					},
				],
			};
		},

		computed: {
			showProjectModal: {
				get() {
					return this.$store.state.modal.showProjectModal;
				},
				set(newValue) {
					this.$store.state.modal.showProjectModal = newValue;
				},
			},
			showMapModal: {
				get() {
					return this.$store.state.modal.showMapModal;
				},
				set(newValue) {
					this.$store.state.modal.showMapModal = newValue;
				},
			},
			//使用新地图
			showNewMapModal: {
				get() {
					return this.$store.state.modal.showNewMapModal;
				},
				set(newValue) {
					this.$store.state.modal.showNewMapModal = newValue;
				},
			},
			underlineStyle() {
				if (this.currentTab === 0) {
					return {
						width: "60px",
						transform: "translateX(150px)",
					};
				}
				return {
					width: "100px",
					transform: "translateX(500px)",
				};
			},
			...mapState({
				dimData(state) {
					return state.project.option;
				},
			}),

			address() {
				// if (!this.province || !this.city || !this.region || !this.formData.xmwz) {
				// 	return "";
				// }
				// return `${this.province}${this.city}${this.region}${this.formData.xmwz}`;
				return this.formData.xmwz
			},

			selectAreaText() {
				return this.pathData ? "修改定位" : "定位";
			},

			// landUseList() {
			//     const landUse = this.dimData.landUse.map(item => {
			//         return {
			//             id: item.name,
			//             name: item.name
			//         };
			//     });
			//     return this.formData.zdyxmlx === '土地' ? landUse : [];
			// }
		},

		watch: {
			projectKeyword(value) {
				if (value === "") {
					this.tipsList = [];
				} else {
					this.getTips(value);
				}
			},
			resolveId(value, oldValue) {
				if (value) {
					this.getProjectList();
					getProjectDetail({
							id: this.resolveId,
						})
						.then((res) => {
							this.$store.commit("project/setState", {
								prop: "projectDetail",
								value: res,
							});
							this.$store.commit("project/getProjectType", res.xmlx);
							this.showProjectModal = false;
							this.resolveId = null;
						})
						.catch((err) => {
						});
				}
			},
		},
		mounted() {
			this.getProjectList();
		},

		methods: {
			// selectAdress(e) {
			// 	let info = JSON.parse(e.value)
			// 	this.province = this.formData.sheng = info.province
			// 	this.city = this.formData.shi = info.city
			// 	this.region = this.formData.qu = info.area
			// 	this.formData.xmwz = `${this.province}${this.city}${this.region}${info.address}`

			// },


			// async getAdressList(query) {
			// 	this.adressListLoading = true;
			// 	let _this = this
			// 	let timer = null //借助闭包
			// 	if (timer) {
			// 		clearTimeout(timer)
			// 	}
			// 	timer = setTimeout(async () => {
			// 		if (query !== '') {
			// 			let params = {
			// 				query: query
			// 			}
			// 			window.showList = function(data) {
			// 				if (data.message == 'ok') {
			// 					_this.adressList = []
			// 					data.results.forEach(item => {
			// 						let obj = {
			// 							name: `${item.name}(${item.address})`,
			// 							value: JSON.stringify(item)
			// 						}
			// 						_this.adressList.push(obj)
			// 						if (_this.adressList.length == data.results.length) {
			// 							_this.adressListLoading = false;
			// 						} else {
			// 							_this.adressListLoading = true;
			// 						}
			// 					})
			// 				}
			// 			}
			// 			let url =
			// 				`https://api.map.baidu.com/place/v2/search?query=${params.query}&region=%E5%85%A8%E5%9B%BD&output=json&ak=GRKYHU8PVZXsKZ0FH4u5gGncQVjPbDKd&callback=showList`
			// 			JQ.getScript(url)

			// 		} else {
			// 			this.adressList = [];
			// 		}
			// 	}, 1000) // 简化写法
			// },



			// 获取项目列表
			async getProjectList() {
				const params = {
					size: this.size,
					current: this.current,
				};

				this.projectList = [];

				const result = await getProjectList(params);
				result.records.forEach((item) => {
					let obj = {};
					obj.id = item.id;
					obj.xmmc = item.xmmc;
					obj.gmtCreate = formatDate(item.gmtCreate);

					this.projectList.push(obj);
				});

				this.projectTotal = result.pages * 10;
			},
			pageChange(page) {
				this.current = page;
				this.getProjectList();
			},
			// selectProvince(value) {
			// 	if (!value) return;
			// 	this.province = value.label;
			// 	this.formData.sheng = value.value;
			// 	city({
			// 		id: value.value
			// 	}).then((res) => {
			// 		this.cityData = res;
			// 	});
			// },
			// selectCity(value) {
			// 	if (!value) return;
			// 	this.city = value.label;
			// 	this.formData.shi = value.value;
			// 	region({
			// 		id: value.value
			// 	}).then((res) => {
			// 		this.regionData = res;
			// 	});
			// },
			// selectRegion(value) {
			// 	if (!value) return;
			// 	this.region = value.label;
			// 	this.formData.qu = value.value;
			// },
			addFile() {
				this.$refs.fileInput.click();
			},

			beforeUpload(file) {
				return new Promise((resolve, reject) => {
					let _self = this;
					let fileReader = new FileReader();
					let blobSlice =
						File.prototype.mozSlice ||
						File.prototype.webkitSlice ||
						File.prototype.slice,
						chunkSize = 2097152,
						chunks = Math.ceil(file.size / chunkSize),
						currentChunk = 0,
						spark = new SparkMD5();

					fileReader.onload = function(e) {
						spark.appendBinary(e.target.result);
						currentChunk++;
						if (currentChunk < chunks) {
							loadNext();
						} else {
							resolve({
								md5_key: spark.end(),
								md5_name: file.name,
							});
						}
					};

					function loadNext() {
						let start = currentChunk * chunkSize,
							end =
							start + chunkSize >= file.size ? file.size : start + chunkSize;
						fileReader.readAsBinaryString(blobSlice.call(file, start, end));
					}
					loadNext();
				});
			},

			async uploadFile(ev) {
				const file = ev.target.files[0];
				const type = getFileType(file.name);
				const formData = new FormData();
				const obj = {};

				const result = await this.beforeUpload(file);
				formData.append("file", file);
				formData.append("filename", result.md5_name);
				formData.append("md5", result.md5_key);

				uploadFile(formData).then((res) => {
					this.formData.fileList.push({
						lx: type,
						ms: "",
						url: res,
						wjmc: file.name,
					});
					this.fileNameCheckArr.push(0);
				});
				obj.name = file.name;
				obj.type = type;

				ev.target.value = null;
			},

			submit() {
				const params = this.formData;
				params.zdyjson = this.pathData;
				//发送数据检测
				for (let key in this.required) {
					if (!params[key]) {
						// this.$Message.warning(`${this.required[key]}不能为空`);
						let arr = ["省份", "城市", "行政区", "项目类型"];
						let a = this.required[key];
						let tipArr = [
							"【",
							`*${this.required[key]}`,
							"】不可留空，请您",
							`${arr.includes(a) ? "选择" : "填写"}`,
							`*${this.required[key]}`,
						];
						this.$msg.error({
							text: tipArr
						});
						return;
					}
				}
				this.formLoading = true
				addProject(params)
					.then((resolve) => {
						this.$msg.error({
							type: "remind",
							code: 4051
						});
						this.resolveId = resolve;
						this.formData = {
							xmmc: "",
							shengName: "",
							shiName: "",
							quName: "",
							xmlx: 3,
							zdyxmlx: 3,
							zdytdyt: "",
							ydmj: "",
							zjzmj: "",
							rjl: "",
							zzrq: "",
							xz: "",
							xmjj: "",
							fileList: [],
							jd: "",
							wd: "",
							xmwz: "",
							zdyjson: null,
						};
						this.pathData = null;
						this.formLoading = false
					})
					.catch((err) => {
						this.$msg.error({
							text: err.data
						});
						this.formLoading = false

					})
			},

			openMapModal() {
				if (!this.formData.xmwz) {
					this.$msg.error({
						type: "remind",
						code: 4041
					});
					return;
				}
				this.showNewMapModal = true;
			},

			getLocation(data, center ,area) {
				this.formData.jd = center.lng;
				this.formData.wd = center.lat;
				this.pathData = data;
				this.formData.shengName = area.sheng
				this.formData.shiName = area.shi
				this.formData.quName = area.qu
				this.formData.location = area.location
			},

			jump(data) {
				if (!data.route) return;

				const routeUrl = this.$router.resolve({
					path: data.route,
				});

				window.open(routeUrl.href, "_blank");
			},

			async handleSearch() {
				this.current = 1;
				const params = {
					gjz: this.projectKeyword,
					size: this.size,
					current: this.current,
				};

				this.projectList = [];

				const result = await getProjectList(params);
				result.records.forEach((item) => {
					let obj = {};
					obj.id = item.id;
					obj.xmmc = item.xmmc;
					obj.gmtCreate = formatDate(item.gmtCreate);

					this.projectList.push(obj);
				});

				this.projectTotal = result.pages * 10;
			},

			modifyFileDetail() {
				this.formData.fileList[this.selectFileIdx].ms = this.fileDetail;
				this.fileDetail = "";
			},

			getTips(value) {
				if (this.tipsTimer) {
					clearTimeout(this.tipsTimer);
					this.tipsTimer = null;
				}

				this.tipsTimer = setTimeout(() => {
					getProjectList({
						current: 1,
						size: 4,
						gjz: value,
					}).then((res) => {
						this.tipsList = res.records;
					});
				}, 300);
			},

			searchInputBlur() {
				this.isShowTipsBox = false;
			},
			searchInputFocus() {
				this.isShowTipsBox = true;
			},
			handleTipsClick(id) {
				getProjectDetail({
					id,
				}).then((res) => {
					this.$store.commit("project/setState", {
						prop: "projectDetail",
						value: res,
					});
					this.$store.commit("project/getProjectType", res.xmlx);
					this.showProjectModal = false;
					this.isShowTipsBox = false;
					this.tipsTimer = null;
					this.tipsList = [];
					this.isOverTips = false;
				});
			},
			enterTipsBox() {
				this.isOverTips = true;
			},
			leaveTipsBox() {
				this.isOverTips = false;
			},
			//删除项目
			deleteProject(type) {
				if (type == 1) {
					deleteProject({
						id: this.deleteId,
					}).then(() => {
						this.projectList = this.projectList.filter((item) => {
							return item.id !== this.deleteId;
						});
						this.deleteId = null;
					});
				} else {
					this.deleteId = null;
				}
				this.deleteModal = false;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.project-modal {
		/deep/ .vertical-center-modal {
			display: flex;
			align-items: center;
			justify-content: center;

			.ivu-modal {
				height: 93%;
				top: 0;
				overflow: auto;
				border-radius: 6px;

				&::-webkit-scrollbar {
					width: 0;
				}
			}

			.ivu-modal-body {
				display: flex;
				justify-content: center;
				padding: 0;
				height: 100%;

				.table {
					width: 100%;
					padding: 0 20px;
					margin-top: 10px;

					& /deep/ .ivu-table-wrapper {
						.ivu-table-cell {
							padding: 6px;
						}

						.ivu-table-tbody {
							border-top: 1px solid #d6d7d9;
						}

						.ivu-table-tbody .ivu-table-column-center {
							border-right: 1px solid #d6d7d9;
							border-bottom: 1px solid #d6d7d9;

							&:first-child {
								border-left: 1px solid #d6d7d9;
							}
						}

						.ivu-table td {
							height: 40px;
						}
					}
				}

				.left,
				.right {
					height: 100%;
					padding-bottom: 13px;

					.head {
						width: 100%;
						padding-top: 20px;
						padding-left: 20px;
						display: flex;
						align-items: center;

						.icon {
							display: inline-block;
							width: 4px;
							height: 23px;
							background-color: #00b6ff;
							position: relative;

							&::after {
								content: "";
								border: 4px solid transparent;
								border-left-color: #00b6ff;
								position: absolute;
								top: 50%;
								right: -7px;
								margin-top: -4px;
							}
						}

						.text {
							margin-left: 6px;
							font-size: 18px;
							font-weight: bold;
							line-height: 20px;
							color: #333;
						}
					}
				}

				.left {
					width: 480px;

					.search-box {
						width: 100%;
						padding: 0 20px;
						margin-top: 14px;
						display: flex;
						align-items: center;
						position: relative;

						.icon-box {
							display: inline-block;
							width: 30px;
							height: 30px;
							margin-left: 10px;
							background-color: #00b6ff;
							border-radius: 5px;
							text-align: center;
							line-height: 30px;
							cursor: pointer;
						}

						.tips-box {
							width: 84%;
							position: absolute;
							top: 30px;
							background-color: #fff;
							border-radius: 0 0 4px 4px;
							border: 1px solid #57a3f3;
							border-top: 0px;
							list-style: none;
							padding: 0 10px;
							z-index: 1;

							.tip {
								margin: 5px 0;

								&:hover {
									background: #f6f6f6;
								}
							}
						}

						& /deep/ .ivu-input {
							box-shadow: none;
						}
					}

					.table {
						.page {
							margin-top: 10px;
							text-align: right;
						}
					}
				}

				.right {
					flex: 1;
					position: relative;
					border-left: 1px solid #d6d7d9;

					.tabs {
						width: 100%;
						margin-top: 20px;
						display: flex;
						align-items: center;
						position: relative;
						border-bottom: 1px solid #d6d7d9;

						.tab {
							flex: 1;
							font-size: 16px;
							color: #666;
							line-height: 40px;
							text-align: center;
							cursor: pointer;

							&:active {
								color: #00b6ff;
							}

							.ivu-icon {
								margin-left: 10px;
							}
						}

						.underline {
							height: 3px;
							background-color: #00b6ff;
							position: absolute;
							bottom: 0;
							left: 0;
						}
					}

					.options {
						width: 100%;
						padding: 0 20px;

						.row {
							.col {
								margin-top: 20px;
								display: flex;
								align-items: center;
								justify-content: space-between;
								position: relative;

								.require-icon {
									color: red;
									position: absolute;
									top: 52%;
									left: -10px;
									transform: translateY(-50%);
								}

								.name {
									min-width: 56px;
									width: fit-content;
									margin-right: 10px;
									text-align: right;
								}

								&.location {
									.ivu-select {
										margin-right: 4px;

										&:last-child {
											margin-right: 0;
										}
									}
								}

								&.textarea {
									.ivu-input {
										resize: none;
									}
								}

								&:nth-child(n + 2) {
									margin-left: 30px;
								}
							}

							&.area {
								.col {
									position: relative;

									.ivu-input {
										padding-right: 25px;
									}

									.unit {
										position: absolute;
										right: 10px;
										font-size: 14px;
										color: #333;
									}
								}
							}

							&.path {
								.col {
									flex-wrap: wrap;
									justify-content: flex-start;

									.text {
										color: gray;
										text-decoration: underline;
										cursor: pointer;

										&:hover {
											color: #00b6ff;
										}
									}

									.require-icon {
										top: 12px;
									}
								}
							}
						}

						.upload-file {
							width: 100%;
							margin-top: 20px;

							.text {
								margin-bottom: 10px;
								font-size: 14px;
								color: #666;
							}
						}

						.btn-group {
							width: 100%;
							margin-top: 20px;
							text-align: center;

							.file-input {
								display: none;
							}

							.ivu-btn {
								width: 144px;
								height: 40px;

								&:first-child {
									margin-right: 20px;
								}
							}
						}
					}

					.search-project-box {
						width: 100%;
						height: 0px;
						background-color: #fafafd;
						text-align: right;
						line-height: 50px;
						position: absolute;
						top: 96px;
						overflow: hidden;
						transition: height 0.3s;

						&.show {
							height: 50px;
						}

						.tab {
							margin-right: 20px;
							padding: 5px 10px;
							color: #333;
							font-size: 14px;
							background-color: #fff;
							border: 1px solid #d6d7d9;
							border-radius: 5px;
							cursor: pointer;
						}
					}
				}
			}
		}
	}

	.detail-modal {
		/deep/ .vertical-center-modal {
			display: flex;
			align-items: center;
			justify-content: center;

			.ivu-modal {
				top: 0;
			}
		}
	}
</style>
