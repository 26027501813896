<template>
<div class="market-analysis-left">
    <Header title="市场分析" :list="navList"></Header>
    <div class="content bigContent">
        <LeftTabs :tabsList="tabsList"></LeftTabs>

        <p class="date" v-if="statisticData.statDateVar">{{ statisticData.statDateVar }}</p>

        <div class="detail">
            <div class="top">
                <div class="box">
                    <p class="box-item box-top">供应面积</p>
                    <p class="box-item box-bottom">{{ statisticData.gymj }}万m²</p>
                    <p class="box-item middle-item" v-if="statisticData.gymjtb">
                        同比
                        <span class="value">{{ statisticData.gymjtb }}%</span>
                    </p>
                </div>
                <div class="box">
                    <p class="box-item box-top">成交面积</p>
                    <p class="box-item box-bottom">{{ statisticData.cjmj }}万m²</p>
                    <p class="box-item middle-item" v-if="statisticData.cjmjtb">
                        同比
                        <span class="value">{{ statisticData.cjmjtb }}%</span>
                    </p>
                </div>
                <div class="box">
                    <p class="box-item box-top">库存面积</p>
                    <p class="box-item box-bottom">{{ statisticData.kcmj }}万m²</p>
                    <p class="box-item middle-item" v-if="statisticData.kcmjtb">
                        同比
                        <span class="value">{{ statisticData.kcmjtb }}%</span>
                    </p>
                </div>
            </div>
            <div class="bottom">
                <span class="name">去化周期</span>
                <span class="value" v-if="statisticData.qhzq">{{ statisticData.qhzq }}个月</span>
                <span class="value" v-else>--</span>
            </div>
        </div>

        <div class="new-house">
            <p class="title">一手房市场</p>

            <div class="options">
                <span class="name">物业类型</span>

                <Select v-model="propertyType" style="width:64px; margin-left: 10px;" @on-change="getMidChartData">
                    <Option v-for="item in propertyTypeList" :value="item.id" :key="item.name">{{ item.name }}</Option>
                </Select>
                <Select v-model="areaType" :max-tag-count="0" :max-tag-placeholder="maxTagMidFn" multiple style="width:150px; margin-left: 10px;" @on-change="genNewHouseBar()">
                    <Option v-for="item in areaTypeList" :value="item.id" :key="item.name">{{ item.name }}</Option>
                </Select>

                <div class="btn-box">
                    <Button class="btn-one" :type=middleButtonType1 @click="midBtnClick(1)">指标值</Button>
                    <Button class="btn-two" :type=middleButtonType2 @click="midBtnClick(2)">同比</Button>
                </div>
            </div>

            <div class="echart" ref="newHouseBar"></div>
        </div>

        <div class="old-house">
            <div class="head">
                <p class="title">二手房价/租金价格</p>
                <div class="btn-box">
                    <Button class="btn-one" :type=bottomButtonType1 @click="genOldHouseBar(1)">二手房价</Button>
                    <Button class="btn-two" :type=bottomButtonType2 @click="genOldHouseBar(2)">租金价格</Button>
                </div>
            </div>
            
            <div class="echart" ref="oldHouseBar"></div>
        </div>
    </div>

</div>
</template>

<script>
import echarts from 'echarts';
import Header from '../LeftHeader';
import LeftTabs from '../LeftTabs';
import {
    singleArea,
    scfxLptj,
    scfxEsfjzl,
    scfxZyjjzb
} from '@/api/projectAnalysis';
import {
    returnFloat
} from '../../../../utils';

export default {
    name: 'marketAnalysisLeft',
    components: {
        Header,
        LeftTabs,
    },
    props: {
        navList: {
            type: Array,
            default () {
                return [];
            },
        },
    },
    data() {
        return {
            tabsList: [],
            propertyType: -1,

            areaTypeList: [{
                    id: 'cjts',
                    name: '成交套数',
                    unit: '',
                },
                {
                    id: 'cjmj',
                    name: '成交面积',
                    unit: '',
                },
                {
                    id: 'gyts',
                    name: '供应套数',
                },
                {
                    id: 'gymj',
                    name: '供应面积',
                },
                {
                    id: 'kcts',
                    name: '库存套数',
                },
                {
                    id: 'kcmj',
                    name: '库存面积',
                },
                {
                    id: 'cjjj',
                    name: '成交均价',
                },
                {
                    id: 'cjje',
                    name: '成交金额',
                },
            ],
            areaType: ['cjts'],

            indicatorMap: {
                cjts: '成交套数',
                cjmj: '成交面积',
                gyts: '供应套数',
                gymj: '供应面积',
                kcts: '库存套数',
                kcmj: '库存面积',
                cjjj: '成交均价',
                cjje: '成交金额',
            },
            indicatorTbMap: {
                cjts: '成交套数同比',
                cjmj: '成交面积同比',
                gyts: '供应套数同比',
                gymj: '供应面积同比',
                kcts: '库存套数同比',
                kcmj: '库存面积同比',
                cjjj: '成交均价同比',
                cjje: '成交金额同比',
            },
            indicatorUnitMap: {
                cjts: '',
                cjmj: '万m²',
                gyts: '',
                gymj: '万m²',
                kcts: '',
                kcmj: '万m²',
                cjjj: '元/m²',
                cjje: '亿元',
            },

            statisticData: {},
            midData: [],

            midType: 1,

            middleButtonType1: 'primary',
            middleButtonType2: 'default',
            bottomButtonType1: 'primary',
            bottomButtonType2: 'default',

            colorList: ['#ffc000', '#ff0000', '#92d050', '#ffff00', '#7030a0'],
        };
    },
    computed: {
        projectDetail() {
            return this.$store.state.project.projectDetail;
        },
        projectData() {
            return this.$store.state.project.option;
        },

        propertyTypeList() {
            return this.projectData.landMarketAnalysisWYType;
        },
        shi() {
            return this.$store.getters['project/shi'];
        },
    },

    mounted() {
        this.propertyType = this.propertyTypeList[1].id;
        this.getCity();
        this.getStatisticData();
        this.getMidChartData();
        this.getBottomChartData();
    },
    methods: {
        getCity() {
            singleArea({
                id: this.projectDetail.shi,
            }).then((res) => {
                this.tabsList.push({
                    id: res.id,
                    name: res.districtName,
                });
            });
        },

        getStatisticData() {
            scfxLptj({
                provinceId: this.shi,
            }).then((res) => {
                this.statisticData = res;
                for (let key in this.statisticData) {
                    if (typeof this.statisticData[key] === 'number') {
                        this.statisticData[key] = returnFloat(this.statisticData[key], 2);
                    }
                    if (key === 'qhzq') {
                        this.statisticData[key] = Math.round(this.statisticData[key] * 10) / 10;
                    }
                }
            });
        },

        getMidChartData() {
            const params = {
                provinceId: this.shi,
                tenementType: this.propertyType === -1 ? null : this.propertyType,
            };

            scfxZyjjzb(params).then((res) => {
                if (!Array.isArray(res)) {
                    res = [];
                }
                this.midData = res.filter((item) => {
                    const now = new Date();
                    const nYear = now.getFullYear();
                    const nMonth = now.getMonth();
                    const date = new Date(item.statDateVar);
                    const dYear = date.getFullYear();
                    const dMonth = date.getMonth();
                    if (nYear - dYear > 3 || (nYear - dYear === 3 && nMonth > dMonth)) {
                        return false;
                    }
                    return true;
                });
                this.genNewHouseBar();
            });
        },

        genNewHouseBar() {
            let chartType = '';
            let yFormatter = '';
            let yPointer = '';

            if (this.midType == 1) {
                this.middleButtonType1 = 'primary';
                this.middleButtonType2 = 'default';
                chartType = 'bar';
                yFormatter = '{value}';
                yPointer = '';
            } else {
                this.middleButtonType1 = 'default';
                this.middleButtonType2 = 'primary';
                chartType = 'line';
                yFormatter = '{value}%';
                yPointer = '%';
            }

            const bar = echarts.init(this.$refs.newHouseBar);

            const legend = this.areaType.map((type) => {
                let result;

                this.areaTypeList.some((item) => {
                    if (type === item.id) {
                        result = item.name;
                        return true;
                    }
                });

                return result;
            });

            const series = this.areaType.map((item) => {
                return {
                    name: item,
                    type: chartType,
                    data: this.midType === 1 ?
                        this.midData.map((mid) => Math.round(mid[item] * 100) / 100) :
                        this.midData.map((mid) => Math.round(-mid[item + 'tb'] * 100) / 100),
                    barGap: 0,
                };
            });

            const xAxis = this.midData.map((item) => {
                const year = item.statDateVar.slice(0, 4);
                const month = item.statDateVar.slice(4);
                return year + month;
            });

            const options = {
                color: this.colorList,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56',
                        },
                    },
                    formatter: (params) => {
                        let relVal = params[0].name + '<br/>';
                        let value = 0;

                        for (let i = 0; i < params.length; i++) {
                            if (this.midType === 1) {
                                if (params[i].seriesName.includes('mj')) {
                                    value = Math.round(params[i].value * 100) / 100;
                                } else {
                                    value = Math.round(params[i].value);
                                }
                                relVal +=
                                    this.indicatorMap[params[i].seriesName] +
                                    ' : ' +
                                    value +
                                    this.indicatorUnitMap[params[i].seriesName] +
                                    '<br/>';
                            } else {
                                relVal +=
                                    this.indicatorTbMap[params[i].seriesName] +
                                    ' : ' +
                                    returnFloat(params[i].value, 2) +
                                    '%' +
                                    '<br/>';
                            }
                        }

                        return relVal;
                    },
                },
                grid: {
                    top: '25px',
                    left: 0,
                    bottom: 0,
                    right: 10,
                    containLabel: true,
                },
                legend: {
                    top: 0,
                    right: 0,
                    data: legend,
                },
                xAxis: [{
                    type: 'category',
                    boundaryGap: true,
                    data: xAxis,
                    onZero: false,
                    axisLabel: {
                        formatter: function (value) {
                            if (value < 0) {
                                return -value;
                            } else {
                                return value;
                            }
                        },
                    },
                }, ],
                yAxis: [{
                    type: 'value',
                    scale: true,
                    splitNumber: 3,
                    axisLabel: {
                        formatter: yFormatter,
                    },
                    axisPointer: {
                        show: true,
                        label: {
                            formatter(params) {
                                return returnFloat(params.value, 2) + yPointer;
                            },
                        },
                    },
                }, ],
                series: series,
            };

            bar.setOption(options, true);
        },

        getBottomChartData() {
            scfxEsfjzl({
                provinceId: this.shi,
            }).then((res) => {
                if (!Array.isArray(res)) {
                    res = [];
                }
                this.bottomData = res;
                this.genOldHouseBar(1);
            });
        },

        genOldHouseBar(type) {
            if (type == 1) {
                this.bottomButtonType1 = 'primary';
                this.bottomButtonType2 = 'default';
            } else {
                this.bottomButtonType1 = 'default';
                this.bottomButtonType2 = 'primary';
            }

            const bar = echarts.init(this.$refs.oldHouseBar);

            const legend = type === 1 ? ['二手房价', '同比', '环比'] : ['租金价格', '同比', '环比'];
            const xAxis = this.bottomData.map((item) => item.month);
            const data1 =
                type === 1 ? this.bottomData.map((item) => item.priceEr) : this.bottomData.map((item) => item.priceZu);
            const data2 =
                type === 1 ?
                this.bottomData.map((item) => Math.round(item.priceErT * 100) / 100) :
                this.bottomData.map((item) => Math.round(item.priceZuT * 100) / 100);
            const data3 =
                type === 1 ?
                this.bottomData.map((item) => Math.round(item.priceErH * 100) / 100) :
                this.bottomData.map((item) => Math.round(item.priceZuH * 100) / 100);

            const options = {
                color: this.colorList,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56',
                        },
                    },
                    formatter: (params) => {
                        let relVal = params[0].name + '<br/>';
                        let unit = '';
                        let value = 0;

                        for (let i = 0; i < params.length; i++) {
                            if (i === 0) {
                                if (type === 1) {
                                    unit = '元/m²';
                                    relVal += params[i].seriesName + ' : ' + params[i].value + unit + '<br/>';
                                } else {
                                    unit = '元/m²/月';
                                    relVal += params[i].seriesName + ' : ' + params[i].value + unit + '<br/>';
                                }
                            } else {
                                relVal += params[i].seriesName + ' : ' + params[i].value + '%' + '<br/>';
                            }
                        }

                        return relVal;
                    },
                },
                grid: {
                    top: '35px',
                    left: 0,
                    bottom: 0,
                    right: 10,
                    containLabel: true,
                },
                legend: {
                    top: 0,
                    right: 0,
                    itemWidth: 25,
                    itemHeight: 12,
                    data: legend,
                },
                xAxis: [{
                    type: 'category',
                    boundaryGap: true,
                    data: xAxis,
                }, ],
                yAxis: [{
                        type: 'value',
                        scale: true,
                        splitNumber: 3,
                    },
                    {
                        type: 'value',
                        scale: true,
                        splitNumber: 3,
                        axisLabel: {
                            formatter: '{value}%',
                        },
                        axisPointer: {
                            show: true,
                            label: {
                                formatter(params) {
                                    return returnFloat(params.value, 2) + '%';
                                },
                            },
                        },
                    },
                ],
                series: [{
                        name: legend[0],
                        type: 'line',
                        symbol: 'circle',
                        symbolSize: 6,
                        smooth: true,
                        data: data1,
                        barWidth: 8,
                        yAxisIndex: 0,
                    },
                    {
                        name: legend[1],
                        type: 'line',
                        symbol: 'circle',
                        symbolSize: 6,
                        smooth: true,
                        data: data2,
                        barGap: 0,
                        barWidth: 8,
                        yAxisIndex: 1,
                    },
                    {
                        name: legend[2],
                        type: 'line',
                        symbol: 'circle',
                        symbolSize: 6,
                        smooth: true,
                        data: data3,
                        barGap: 0,
                        barWidth: 8,
                        yAxisIndex: 1,
                    },
                ],
            };

            bar.setOption(options);
        },

        midBtnClick(type) {
            this.midType = type;
            this.genNewHouseBar();
        },

        maxTagMidFn(count) {
            return `已选${count}个选项`;
        },
    },
};
</script>

<style lang="scss" scoped>
.market-analysis-left {
    width: 100%;
    height: 100%;
    position: relative;

    .header {
        z-index: 10;
    }

    .content {
        display: block;
        // padding-bottom: 120px;
        &.bigContent {
            padding-top: 60px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 100px;
        }

        .title {
            width: 100%;
            height: 24px;
            padding-left: 16px;
            font-size: 18px;
            font-weight: bold;
            color: #1562d6;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn-one {
                margin-right: 10px;
            }

            &::before {
                content: '';
                width: 4px;
                height: 20px;
                background-color: #1562d6;
                position: absolute;
                left: 0;
                top: 2px;
            }
        }

        .echart {
            width: 100%;
            height: 180px;
            margin-top: 12px;
        }

        .date {
            width: 100%;
            margin-top: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #1562d6;
            text-align: center;
        }

        .detail {
            width: 100%;
            margin-top: 10px;
            padding: 0 20px;

            .top {
                width: 100%;
                display: grid;
                margin-bottom: 20px;
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 7px;

                .box {
                    padding: 10px 0;
                    background-color: #f3f7ff;
                    text-align: center;

                    &-item {
                        margin-bottom: 8px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    &-top {
                        font-size: 14px;
                        color: #1562d6;
                    }

                    &-bottom {
                        font-size: 16px;
                        font-weight: bold;
                        color: #1562d6;
                    }
                }
            }

            .middle {
                width: 100%;
                margin: 20px 0;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 7px;
                font-size: 14px;

                &-item {
                    text-align: center;

                    .value {
                        font-size: 16px;
                        color: #ff3333;
                    }
                }
            }

            .bottom {
                width: 100%;
                height: 40px;
                padding: 0 20px;
                background-color: #f3f7ff;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .name {
                    color: #1562d6;
                    font-size: 14px;
                }

                .value {
                    color: #1562d6;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }

        .new-house {
            width: 100%;
            padding: 0 20px;
            margin-top: 40px;

            .options {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .name {
                    font-size: 14px;
                    color: #666;
                }

                .btn-box {
                    display: flex;
                    align-items: center;
                    margin-left: 20px;
                    text-align: center;

                    .btn-one {
                        width: 60px;
                        padding: 0;
                        margin-right: 10px;
                    }

                    .btn-two {
                        width: 50px;
                        padding: 0;
                    }
                }
            }
        }

        .old-house {
            width: 100%;
            padding: 0 20px;
            margin-top: 45px;

            .head {
                display: flex;

                .btn-box {
                    display: flex;
                    align-items: center;
                    margin-left: 20px;
                    text-align: center;

                    .btn-one {
                        width: 70px;
                        padding: 0;
                        margin-right: 10px;
                    }

                    .btn-two {
                        width: 70px;
                        padding: 0;
                    }
                }
            }
        }
    }

}
</style>
