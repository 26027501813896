<template>
<div class="economic-left">
    <Header title="经济发展" :list="navList"></Header>
    <div class="content bigContent">
        <LeftTabs :tabsList="tabsList"></LeftTabs>

        <div class="data-box" v-if="economicData">
            <div class="row">
                <div class="data-item">
                    <p class="num">{{ economicData.fdckftz }}亿元</p>
                    <p class="type">房地产开发投资</p>
                </div>
                <div class="data-item">
                    <p class="num">{{ economicData.sczz }}亿元</p>
                    <p class="type">生产总值</p>
                </div>
                <div class="data-item">
                    <p class="num">{{ economicData.rjkzpsr }}元</p>
                    <p class="type">人均可支配收入</p>
                </div>
            </div>
            <div class="row">
                <div class="data-item">
                    <p class="num">{{ economicData.czrk }}万人</p>
                    <p class="type">常住人口</p>
                </div>
                <div class="data-item year">
                    <p class="num">{{ economicData.sj }}年</p>
                </div>
                <div class="data-item">
                    <p class="num">{{ economicData.rkjll }}万人</p>
                    <p class="type">人口净流量</p>
                </div>
            </div>
        </div>

        <div class="indicators">
            <p class="title">主要经济指标</p>
            <div class="options">
                <Row class="row">
                    <Select v-model="timeDimention" style="width: 65px; margin-right: 10px" @on-change="handleCycleChange">
                        <Option v-for="item in timeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                    <DatePicker :type="dateType" :value="startTime" :format="dateFormat" placeholder="起始日期" :style="{ width: timeDimention === 2 ? '98px' : '130px' }" @on-change="changeStartTime"></DatePicker>
                    <Select v-model="quarterStart" style="width: 55px" v-show="timeDimention === 2" @on-change="getChartData">
                        <Option v-for="item in quarterList" :value="item" :key="item">{{
              item
            }}</Option>
                    </Select>
                    <span class="zhi">至</span>
                    <DatePicker :type="dateType" :value="endTime" :format="dateFormat" placeholder="截止日期" :style="{ width: timeDimention === 2 ? '98px' : '130px' }" @on-change="changeEndTime"></DatePicker>
                    <Select v-model="quarterEnd" style="width: 55px" v-show="timeDimention === 2" @on-change="getChartData">
                        <Option v-for="item in quarterList" :value="item" :key="item">{{
              item
            }}</Option>
                    </Select>
                </Row>
                <Row>
                    <Select v-model="indicators" style="width: 200px" label-in-value @on-change="selectIndicator">
                        <Option v-for="item in indicatorsList" :value="item.id" :key="item.id">
                            {{ item.showName }}
                        </Option>
                    </Select>
                </Row>
            </div>

            <div class="echart" id="echart" ref="echarts"></div>
        </div>

        <div class="analisis">
            <p class="title">城市指标</p>
            <div class="options">
                <div class="row">
                    <Select v-model="timeDimentionCity" style="width: 65px; margin-right: 10px" @on-change="handleCycleChangeCity">
                        <Option v-for="item in timeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                    <DatePicker :type="dateTypeCity" :value="startTimeCity" :format="dateFormatCity" placeholder="起始日期" :style="{ width: timeDimentionCity === 2 ? '98px' : '130px' }" @on-change="changeStartTimeCity"></DatePicker>
                    <Select v-model="quarterStartCity" style="width: 55px" v-show="timeDimentionCity === 2" @on-change="getBottomData">
                        <Option v-for="item in quarterList" :value="item" :key="item">{{
              item
            }}</Option>
                    </Select>
                    <span class="zhi">至</span>
                    <DatePicker :type="dateTypeCity" :value="endTimeCity" :format="dateFormatCity" placeholder="截止日期" :style="{ width: timeDimentionCity === 2 ? '98px' : '130px' }" @on-change="changeEndTimeCity"></DatePicker>
                    <Select v-model="quarterEndCity" style="width: 55px" v-show="timeDimentionCity === 2" @on-change="getBottomData">
                        <Option v-for="item in quarterList" :value="item" :key="item">{{
              item
            }}</Option>
                    </Select>
                    <Select v-model="indicatorsCity" style="width: 70px; margin-left: 10px" label-in-value @on-change="selectIndicatorCity">
                        <Option v-for="item in indicatorsListCity" :value="item.id" :key="item.id">
                            {{ item.showName }}
                        </Option>
                    </Select>
                </div>
                <div class="row city-list">
                    <Dropdown @on-click="selectCity" trigger="click">
                        <a href="javascript:void(0)">
                            {{ selectCityName }}
                            <Icon type="ios-arrow-down"></Icon>
                        </a>
                        <Dropdown-menu slot="list">
                            <Dropdown-item v-for="item in cityList" :name="item.id" :key="item.id">
                                {{ item.districtName }}
                            </Dropdown-item>
                        </Dropdown-menu>
                    </Dropdown>
                    <span class="city-tag" v-for="item in selectCityList" :key="item.id">
                        {{ item.label }}
                        <Icon type="md-close" color="#CBCBCB" :size="10" @click="deleteCityTag(item)" />
                    </span>
                </div>
            </div>

            <div class="table">
                <Table :columns="columns" tooltip-theme="light" :data="tableData"></Table>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import echarts from "echarts";
import Header from "../LeftHeader";
import {
    city,
    economicData,
    jjfzzb,
    zyjjzb
} from "@/api/projectAnalysis";
import {
    formatDate,
    returnFloat
} from "@/utils/";
import LeftTabs from "../LeftTabs";

export default {
    name: "economicLeft",
    components: {
        Header,
        LeftTabs,
    },
    props: {
        navList: {
            type: Array,
            default () {
                return [];
            },
        },
    },
    data() {
        return {
            tabsList: [],

            economicData: null,

            dateType: "month",
            dateFormat: "yyyy-MM",

            dateTypeCity: "month",
            dateFormatCity: "yyyy-MM",

            timeList: [{
                    value: 1,
                    label: "月度",
                },
                {
                    value: 2,
                    label: "季度",
                },
                {
                    value: 3,
                    label: "年度",
                },
            ],

            quarterList: ["Q1", "Q2", "Q3", "Q4"],
            quarterStart: "",
            quarterEnd: "",
            quarterStartCity: "",
            quarterEndCity: "",

            timeDimention: 3,
            timeDimentionCity: 3,

            startTime: "",
            endTime: "",

            startTimeCity: "",
            endTimeCity: "",

            // 图表数据
            legend: [],
            xAxis: [],
            chartValue: [],
            chartUnit: "",

            indicatorsList: [],
            indicatorsListCity: [],
            indicators: "",
            indicatorsCity: "",
            indicatorName: "",
            indicatorNameCity: "",

            unitCity: "",

            cityList: [],
            selectCityList: [],
            selectCityName: "",

            tableData: [],

            topImg: require("@/assets/image/icon98.png"),
        };
    },

    computed: {
        projectData() {
            return this.$store.state.project.option;
        },
        projectDetail() {
            return this.$store.state.project.projectDetail;
        },
        cityId() {
            return this.$store.getters["project/shi"];
        },
        columns() {
            return [{
                    title: "时间",
                    key: "time",
                    align: "center",
                    height: 40,
                },
                {
                    title: "城市",
                    key: "city",
                    align: "center",
                    height: 40,
                },
                {
                    title: this.indicatorNameCity,
                    key: "value",
                    width: 134,
                    align: "center",
                    height: 40,
                },
            ];
        },
    },

    watch: {
        timeDimention: {
            handler(value) {
                switch (value) {
                    case 1:
                        this.dateType = "month";
                        this.dateFormat = "yyyy-MM";
                        break;
                    case 2:
                        this.dateType = "year";
                        this.dateFormat = "yyyy";
                        break;
                    case 3:
                        this.dateType = "year";
                        this.dateFormat = "yyyy";
                        break;
                }
            },
            immediate: true,
        },
        timeDimentionCity: {
            handler(value) {
                switch (value) {
                    case 1:
                        this.dateTypeCity = "month";
                        this.dateFormatCity = "yyyy-MM";
                        break;
                    case 2:
                        this.dateTypeCity = "year";
                        this.dateFormatCity = "yyyy";
                        break;
                    case 3:
                        this.dateTypeCity = "year";
                        this.dateFormatCity = "yyyy";
                        break;
                }
            },
            immediate: true,
        },
    },

    mounted() {
        this.defaultDateRange(this.timeDimention);
        this.initTimeDim();
        this.defaultDateRangeCity(this.timeDimentionCity);
        this.getEconomicData();
        this.getCity();
    },

    methods: {
        getCity() {
            const provinveId = this.projectDetail.sheng;

            city({
                id: provinveId,
            }).then((res) => {
                let city = "";
                this.cityList = res;
                this.city = res[0].id;
                this.selectCityList.push({
                    value: this.cityId,
                    label: res.find((city) => {
                        return city.id == this.cityId;
                    }).districtName,
                });

                this.selectCityName = res[0].districtName;

                this.initCityOptions();

                res.forEach((item) => {
                    if (item.id === this.projectDetail.shi) {
                        city = item.districtName;
                    }
                });

                this.tabsList.push({
                    id: this.cityId,
                    name: city,
                });
            });
        },
        getEconomicData() {
            economicData({
                shiId: this.cityId,
            }).then((res) => {
                this.economicData = res;
            });
        },
        initTimeDim() {
            jjfzzb({
                type: this.timeDimention,
            }).then((res) => {
                this.indicatorsList = res;
                this.indicators = res[0].id;
                this.indicatorName = res[0].showName;
                this.getChartData();
            });
        },
        initTimeDimCity() {
            jjfzzb({
                type: this.timeDimentionCity,
            }).then((res) => {
                this.indicatorsListCity = res;
                this.indicatorsCity = res[0].id;
                this.indicatorNameCity = res[0].showName;
                this.unitCity = res[0].unit;
                this.getBottomData();
            });
        },
        initCityOptions() {
            this.initTimeDimCity();
        },

        selectIndicator(data) {
            this.indicators = data.value;
            this.indicatorName = data.label;
            this.getChartData();
        },
        selectIndicatorCity(data) {
            this.indicatorsCity = data.value;
            this.indicatorNameCity = data.label;
            this.getBottomData();
        },

        getChartData() {
            if (
                !this.timeDimention ||
                !this.startTime ||
                !this.endTime ||
                !this.indicators
            ) {
                // this.$Message.warning("维度不全");
                this.$msg.error({
                    type: "remind",
                    code: 4040
                });

                return;
            }

            this.resetChart();
            const {
                startTime,
                endTime
            } = this.formatTimeRange(
                this.startTime,
                this.endTime
            );

            const params = {
                areaIds: [this.cityId],
                nos: [this.indicators],
                timeRanges: [startTime, endTime],
                timeType: this.timeDimention,
            };

            zyjjzb(params).then((res) => {
                if (JSON.stringify(res) === "{}") {
                    res = [];
                }
                this.legend.push(this.indicatorName);
                res.forEach((item) => {
                    this.chartUnit = item.zbdw;
                    this.xAxis.push(item.sj);
                    if (item.zbdw === "%" || item.zbdw === "‰" || item.zbdw === "‱") {
                        this.chartValue.push(returnFloat(item.zbz, 2));
                    } else {
                        this.chartValue.push(Math.round(item.zbz));
                    }
                });

                this.genChart();
            });
        },

        resetChart() {
            this.xAxis = [];
            this.chartValue = [];
            this.legend = [];
        },

        genChart() {
            const myChart = echarts.init(this.$refs.echarts);
            const self = this;

            const options = {
                color: ["#5C89FF", "#55DB27"],
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#283b56",
                        },
                    },
                    formatter(params) {
                        return (
                            params[0].name +
                            "<br/>" +
                            params[0].marker +
                            params[0].seriesName +
                            " : " +
                            params[0].value +
                            self.chartUnit
                        );
                    },
                },
                grid: {
                    top: "35px",
                    left: 0,
                    bottom: 10,
                    right: 0,
                    containLabel: true,
                },
                legend: {
                    top: 0,
                    right: 0,
                    data: this.legend,
                },
                xAxis: [{
                    type: "category",
                    boundaryGap: true,
                    data: this.xAxis,
                }, ],
                yAxis: [{
                    type: "value",
                    scale: true,
                    min: 0,
                    boundaryGap: [0.2, 0.2],
                    axisPointer: {
                        show: true,
                        label: {
                            formatter(params) {
                                if (
                                    self.chartUnit === "%" ||
                                    self.chartUnit === "‰" ||
                                    self.chartUnit === "‱"
                                ) {
                                    return returnFloat(params.value, 2) + self.chartUnit;
                                }
                                return returnFloat(params.value, 2);
                            },
                        },
                    },
                }, ],
                series: [{
                    name: this.legend[0],
                    type: "bar",
                    data: this.chartValue,
                }, ],
            };

            myChart.setOption(options);
        },

        handleCycleChange(value) {
            jjfzzb({
                type: value,
            }).then((res) => {
                this.indicatorsList = res;
                this.indicators = res[0].id;
                this.defaultDateRange(value);
                this.getChartData();
            });
        },
        defaultDateRange(cycle) {
            switch (cycle) {
                case 1:
                    this.endTime = new Date();
                    this.startTime = new Date(
                        new Date().setMonth(this.endTime.getMonth() - 6)
                    );
                    break;
                case 2: {
                    const date = new Date();
                    let year = date.getFullYear();
                    let month = date.getMonth() + 1;
                    let quarter = "";
                    let startYear = "";
                    let startQuarter = "";

                    if (month >= 1 && month <= 3) {
                        quarter = "Q1";
                        startYear = year - 1;
                        startQuarter = "Q2";
                    } else if (month >= 4 && month <= 6) {
                        quarter = "Q2";
                        startYear = year - 1;
                        startQuarter = "Q3";
                    } else if (month >= 7 && month <= 9) {
                        quarter = "Q3";
                        startYear = year - 1;
                        startQuarter = "Q4";
                    } else {
                        quarter = "Q4";
                        startYear = year;
                        startQuarter = "Q1";
                    }

                    this.endTime = date;
                    this.quarterEnd = quarter;
                    this.startTime = new Date(`${startYear}`);
                    this.quarterStart = startQuarter;

                    break;
                }
                case 3:
                    this.endTime = new Date();
                    this.startTime = new Date(
                        new Date().setFullYear(this.endTime.getFullYear() - 6)
                    );
                    break;
            }
        },
        defaultDateRangeCity(cycle) {
            switch (cycle) {
                case 1:
                    this.endTimeCity = new Date();
                    this.startTimeCity = new Date(
                        new Date().setMonth(this.endTimeCity.getMonth() - 6)
                    );
                    break;
                case 2: {
                    const date = new Date();
                    let year = date.getFullYear();
                    let month = date.getMonth() + 1;
                    let quarter = "";
                    let startYear = "";
                    let startQuarter = "";

                    if (month >= 1 && month <= 3) {
                        quarter = "Q1";
                        startYear = year - 1;
                        startQuarter = "Q2";
                    } else if (month >= 4 && month <= 6) {
                        quarter = "Q2";
                        startYear = year - 1;
                        startQuarter = "Q3";
                    } else if (month >= 7 && month <= 9) {
                        quarter = "Q3";
                        startYear = year - 1;
                        startQuarter = "Q4";
                    } else {
                        quarter = "Q4";
                        startYear = year;
                        startQuarter = "Q1";
                    }

                    this.endTimeCity = date;
                    this.quarterEndCity = quarter;
                    this.startTimeCity = new Date(`${startYear}`);
                    this.quarterStartCity = startQuarter;

                    break;
                }
                case 3:
                    this.endTimeCity = new Date();
                    this.startTimeCity = new Date(
                        new Date().setFullYear(this.endTimeCity.getFullYear() - 6)
                    );
                    break;
            }
        },
        handleCycleChangeCity(value) {
            jjfzzb({
                type: value,
            }).then((res) => {
                this.indicatorsListCity = res;
                this.indicatorsCity = res[0].normNo;
            });

            this.defaultDateRangeCity(value);
            this.getBottomData();
        },
        selectCity(data) {
            let flag = true;
            let cityName = "";
            this.cityList.forEach((item) => {
                if (item.id === data) {
                    cityName = item.districtName;
                }
            });
            this.selectCityList.forEach((item) => {
                if (item.value === data) {
                    flag = false;
                }
            });
            flag &&
                this.selectCityList.push({
                    value: data,
                    label: cityName,
                });

            this.selectCityName = cityName;
            this.getBottomData();
        },
        getBottomData() {
            if (
                !this.timeDimentionCity ||
                !this.startTimeCity ||
                !this.endTimeCity ||
                !this.indicatorsCity ||
                !this.city
            ) {
                // this.$Message.warning("维度不全");
                this.$msg.error({
                    type: "remind",
                    code: 4040
                });

                return;
            }

            const selectCity = this.selectCityList.map((item) => {
                return item.value;
            });

            const {
                startTime,
                endTime
            } = this.formatTimeRange(
                this.startTimeCity,
                this.endTimeCity
            );

            const params = {
                areaIds: selectCity,
                nos: [this.indicatorsCity],
                timeRanges: [startTime, endTime],
                timeType: this.timeDimentionCity,
            };

            zyjjzb(params).then((res) => {
                this.tableData = [];
                res.forEach((item) => {
                    let obj = {};
                    obj.time = item.sj;
                    obj.city = item.cs;
                    obj.value = item.zbz ? item.zbz + item.zbdw : "";

                    this.tableData.push(obj);
                });
                this.tableData.sort((a, b) => {
                    return new Date(b.time) - new Date(a.time);
                });
            });
        },

        formatTimeRange(startTime, endTime) {
            if (typeof startTime === "object") {
                startTime = formatDate(startTime);
            }
            if (typeof endTime === "object") {
                endTime = formatDate(endTime);
            }

            if (this.timeDimention === 1) {
                startTime = startTime.substring(0, 7);
                endTime = endTime.substring(0, 7);
            } else if (this.timeDimention === 2) {
                startTime = startTime.substring(0, 4);
                endTime = endTime.substring(0, 4);
                startTime = `${startTime}-${this.quarterStart}`;
                endTime = `${endTime}-${this.quarterEnd}`;
            } else if (this.timeDimention === 3) {
                startTime = startTime.substring(0, 4);
                endTime = endTime.substring(0, 4);
            }
            return {
                startTime,
                endTime
            };
        },
        deleteCityTag(item) {
            let index;

            this.selectCityList.some((city, cityIdx) => {
                if (city.value === item.value) {
                    index = cityIdx;
                }
            });

            this.selectCityList.splice(index, 1);

            if (this.selectCityList.length === 0) {
                this.selectCityName = "请选择";
            }

            this.getBottomData();
        },

        changeStartTime(value) {
            this.startTime = value;
            this.getChartData();
        },
        changeEndTime(value) {
            this.endTime = value;
            this.getChartData();
        },
        changeStartTimeCity(value) {
            this.startTimeCity = value;
            this.getBottomData();
        },
        changeEndTimeCity(value) {
            this.endTimeCity = value;
            this.getBottomData();
        },
    },
};
</script>

<style lang="scss" scoped>
.economic-left {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;

    // top: -30px;
    .header{
      z-index: 10;
    }
    .content {
      display: block;
      &.bigContent {
            padding-top: 60px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 100px;
        }
        .data-box {
            width: 100%;
            padding: 0 40px;
            margin-top: 38px;

            .row {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                text-align: center;

                .data-item {
                    .num {
                        color: #00b6ff;
                        font-size: 16px;
                    }

                    .type {
                        width: 60px;
                        margin: 0 auto;
                        color: #666;
                        font-size: 12px;
                    }

                    &.year {
                        font-size: 18px;
                    }
                }
            }
        }

        .indicators {
            margin-top: 30px;
            padding: 0 20px;

            .title {
                width: 100%;
                height: 24px;
                padding-left: 16px;
                font-size: 18px;
                font-weight: bold;
                color: #1562d6;
                position: relative;

                &::before {
                    content: "";
                    width: 4px;
                    height: 20px;
                    background-color: #1562d6;
                    position: absolute;
                    left: 0;
                    top: 2px;
                }
            }

            .options {
                margin-top: 10px;

                .row {
                    margin-bottom: 10px;
                }

                .zhi {
                    margin: 0 10px;
                }
            }

            .echart {
                width: 100%;
                height: 150px;
                margin-top: 12px;
            }
        }

        .analisis {
            margin-top: 12px;
            padding: 0 20px;

            .title {
                width: 100%;
                height: 24px;
                padding-left: 16px;
                font-size: 18px;
                font-weight: bold;
                color: #1562d6;
                position: relative;

                &::before {
                    content: "";
                    width: 4px;
                    height: 20px;
                    background-color: #1562d6;
                    position: absolute;
                    left: 0;
                    top: 2px;
                }
            }

            .options {
                display: block;

                .row {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;

                    .zhi {
                        margin: 0 10px;
                    }

                    .city-tag {
                        width: 15%;
                        height: 30px;
                        margin-top: 10px;
                        margin-left: 4px;
                        padding-left: 10px;
                        line-height: 30px;
                        border: 1px solid #d6d7d9;
                        font-size: 12px;
                        position: relative;

                        .ivu-icon {
                            cursor: pointer;
                            position: absolute;
                            right: 7px;
                            top: 50%;
                            margin-top: -5px;
                        }
                    }

                    &.city-list {
                        flex-wrap: wrap;
                        margin-top: 0;

                        & /deep/ .ivu-dropdown {
                            margin-top: 10px;
                            padding: 5px 10px 2px;
                            border-radius: 4px;
                            border: 1px solid #d6d7d9;

                            .ivu-select-dropdown {
                                max-height: 200px;
                                overflow: auto;
                            }

                            a {
                                color: #515a6e;
                            }
                        }
                    }
                }
            }

            .table {
                width: 100%;
                margin-top: 10px;

                .unit {
                    text-align: right;
                }

                & /deep/ .ivu-table-wrapper {
                    .ivu-table-body {
                        .ivu-table-column-center {
                            height: 40px;
                        }
                    }
                }
            }
        }

    }

}
</style>
