<template>
<div class="law-left">
    <Header title="政策法规" :list="navList"></Header>
    <div class="content bigContent">
        <div class="options">
            <div class="select-box">
                <div class="time">
                    <span class="name">发布日期</span>
                    <DatePicker format="yyyy-MM-dd" type="date" placeholder="起始日期" style="width: 115px" :value="formData.rq[0]" @on-change="chooseTimeLeft"></DatePicker>
                    <span class="zhi">至</span>
                    <DatePicker format="yyyy-MM-dd" type="date" placeholder="截止日期" style="width: 115px" :value="formData.rq[1]" @on-change="chooseTimeRight"></DatePicker>
                </div>
                <div class="important">
                    <span class="name">重要</span>
                    <Select v-model="formData.isZy" style="width: 70px" @on-change="getPolicy">
                        <Option v-for="item in importantList" :value="item.value" :key="item.value">{{ `${item.label}+${item.value}` }}</Option>
                    </Select>
                </div>
            </div>

            <div class="select-group">
                <div class="selector">
                    <span class="name">等级</span>
                    <Select v-model="formData.dj" style="width: 126px" multiple :max-tag-count="0" :max-tag-placeholder="maxTagMidFn" @on-select="handleSelect">
                        <Option v-for="item in level" :value="item" :key="item">{{
                item
              }}</Option>
                    </Select>
                </div>
                <div class="selector">
                    <span class="name">分类一</span>
                    <Select v-model="formData.yjfl" style="width: 126px" multiple :max-tag-count="0" :max-tag-placeholder="maxTagMidFn" @on-select="handleSelect">
                        <Option v-for="item in yjfl" :value="item" :key="item">{{
                item
              }}</Option>
                    </Select>
                </div>
                <div class="selector">
                    <span class="name">分类二</span>
                    <Select v-model="formData.ejfl" style="width: 126px" multiple :max-tag-count="0" :max-tag-placeholder="maxTagMidFn" @on-select="handleSelect">
                        <Option v-for="item in ejfl" :value="item" :key="item">{{
                item
              }}</Option>
                    </Select>
                </div>
                <div class="selector">
                    <span class="name">分类三</span>
                    <Select v-model="formData.sjfl" style="width: 126px" multiple :max-tag-count="0" :max-tag-placeholder="maxTagMidFn" @on-select="handleSelect">
                        <Option v-for="item in sjfl" :value="item" :key="item">{{
                item
              }}</Option>
                    </Select>
                </div>
            </div>

            <div class="search-box">
                <Input v-model="formData.bt" placeholder="政策搜索" style="width: 86%" />
                <span class="icon-box" @click="searchLand">
                    <Icon type="ios-search" color="#fff" :size="16" />
                </span>
            </div>
        </div>

        <div class="table">
            <Table :max-height="664" tooltip-theme="light" :columns="columns" :data="tableData" :loading="isLoading"></Table>
            <Page class-name="page" :total="total" @on-change="pageChange" :simple="true" />
        </div>
    </div>
</div>
</template>

<script>
import Header from "../LeftHeader";
import {
    getPolicyList,
    zcfgzy
} from "@/api/projectAnalysis";
import {
    type
} from "os";

export default {
    name: "lawLeft",
    components: {
        Header,
    },
    props: {
        navList: {
            type: Array,
            default () {
                return [];
            },
        },
    },
    data() {
        let self = this
        return {
            importantList: [{
                    value: -1,
                    label: "不限",
                },
                {
                    value: 1,
                    label: "重要",
                },
                {
                    value: 0,
                    label: "普通",
                },
            ],

            rangeOption: {
                rq: "时间",
            },

            formData: {
                time: [],
                isZy: -1,
                landName: "",
                dj: "不限",
                yjfl: "不限",
                ejfl: "不限",
                sjfl: "不限",
                bt: "",
                rq: [],
            },

            columns: [{
                    title: "序号",
                    key: "mid",
                    width: 55,
                    align: "center",
                },
                {
                    title: "标题/政策名称",
                    key: "bt",
                    align: "center",
                    render(h, params) {
                        return h(
                            "a", {
                                style: {
                                    cursor: "pointer",
                                    color: "#00B6FF",
                                    overflow: "hidden",
                                    "white-space": "nowrap",
                                    "text-overflow": "ellipsis",
                                },
                                domProps: {
                                    target: "_blank",
                                    href: params.row.url,
                                    title: params.row.bt,
                                },
                            },
                            params.row.bt
                        );
                    },
                },
                {
                    title: "日期",
                    key: "rq",
                    align: "center",
                },
                {
                    title: "重要",
                    key: "isZy",
                    width: 50,
                    align: "center",
                    render(h, params) {
                        return h("div", [
                            h(
                                "div", {
                                    style: {
                                        width: "14px",
                                        height: "14px",
                                        margin: "0 auto",
                                        border: "1px dashed #D6D7D9",
                                        "font-size": 0,
                                    },
                                },
                                [
                                    h("img", {
                                        attrs: {
                                            src: require("@/assets/image/analysis/important.png"),
                                        },
                                        style: {
                                            width: "13px",
                                            height: "13px",
                                            display: params.row.isZy ? "block" : "none",
                                        },
                                        on: {
                                            click(ev) {
                                                zcfgzy({
                                                    zcfgId: params.row.id,
                                                    isZy: 0,
                                                    itemId: self.projectDetail.id
                                                }).then((res) => {
                                                    // $(ev.target).hide();
                                                    // $(ev.target).siblings().show();s`12
                                                    self.getPolicy()
                                                });
                                            },
                                        },
                                    }),
                                    h("img", {
                                        attrs: {
                                            src: require("@/assets/image/analysis/unimportant.png"),
                                        },
                                        style: {
                                            width: "13px",
                                            height: "13px",
                                            display:
                                                !params.row.isZy ?
                                                "block" :
                                                "none",
                                        },
                                        on: {
                                            click(ev) {
                                                zcfgzy({
                                                    zcfgId: params.row.id,
                                                    isZy: 1,
                                                    itemId: self.projectDetail.id
                                                }).then((res) => {
                                                    // $(ev.target).hide();
                                                    // $(ev.target).siblings().show();
                                                    self.getPolicy()
                                                });
                                            },
                                        },
                                    }),
                                ]
                            ),
                        ]);
                    },
                },
            ],
            tableData: [],

            current: 0,
            size: 8,
            isLoading: true,

            total: 10,

            // 等级
            level: ["国家", "省", "市", "行政区"],
            // 一级分类
            yjfl: [],
            // 二级分类
            ejfl: [],
            // 三级分类
            sjfl: [],
        };
    },
    computed: {
        projectData() {
            return this.$store.state.project.option;
        },
        projectDetail() {
            return this.$store.state.project.projectDetail;
        },
    },
    mounted() {
        const initArray = [];
        this.yjfl = initArray.concat(this.projectData.zcfgfl.yjfl);
        this.ejfl = initArray.concat(this.projectData.zcfgfl.ejfl);
        this.sjfl = initArray.concat(this.projectData.zcfgfl.sjfl);
        this.getPolicy();
    },
    methods: {
        chooseTimeLeft(data) {
            this.formData.rq[0] = data;
        },
        chooseTimeRight(data) {
            this.formData.rq[1] = data;
        },

        searchLand() {
            for (let key in this.rangeOption) {
                if (this.formData[key]) {
                    let start = this.formData[key][0];
                    let end = this.formData[key][1];

                    // 都是空值 继续
                    if (!start && !end) {
                        continue;
                    }

                    // 只有单一值 返回
                    if (!start || !end) {

                        this.$msg.error({
                            type: "remind",
                            code: 4021
                        });

                        return;
                    }
                }
            }

            this.getPolicy();
        },

        async getPolicy() {
            const params = {
                current: this.current,
                size: this.size,
                bt: this.formData.bt,
                rq: this.formData.rq,
                dj: Array.isArray(this.formData.dj) ?
                    this.formData.dj :
                    [this.formData.dj],
                yjfl: Array.isArray(this.formData.yjfl) ?
                    this.formData.yjfl :
                    [this.formData.yjfl],
                ejfl: Array.isArray(this.formData.ejfl) ?
                    this.formData.ejfl :
                    [this.formData.ejfl],
                sjfl: Array.isArray(this.formData.sjfl) ?
                    this.formData.sjfl :
                    [this.formData.sjfl],
                cs: this.projectDetail.shi,
                sheng: this.projectDetail.sheng,
                qu: this.projectDetail.qu,
                isZy: this.formData.isZy,
                itemId: this.projectDetail.id,
            };

            params.rq = params.rq.filter((item) => !!item);

            // 去掉不限
            for (let key in params) {
                if (Array.isArray(params[key])) {
                    params[key] = params[key].filter((item) => item !== "不限");
                }
            }

            const result = await getPolicyList(params);
            this.isLoading = false;

            this.total = result.pages * 10;
            this.tableData = result.records
                .map((item) => {
                    item.date = new Date(item.rq.split(" ")[0]).getTime();
                    item.rq = item.rq.split(" ")[0];
                    return item;
                })
                .sort((a, b) => {
                    return b.date - a.date;
                })
                .map((item, index) => {
                    item.mid = index + 1;
                    return item;
                });
        },

        pageChange(page) {
            this.current = page;

            this.searchLand();
        },

        maxTagMidFn(count) {
            return `+${count}`;
        },

        handleSelect() {
            this.$nextTick(() => {
                this.getPolicy();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.law-left {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;

    .header {
        z-index: 10;
    }

    .content {
        width: 100%;
        display: block;
        overflow: auto;
        // padding: 80px 20px 100px 20px !important;
        &.bigContent {
            padding-top: 60px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 100px;
        }

        .options {
            width: 100%;

            .select-box {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                color: #666;

                .time {
                    .name {
                        margin-right: 8px;
                    }

                    .zhi {
                        margin: 0 6px;
                        color: #999;
                    }
                }

                .important {
                    display: flex;
                    align-items: center;

                    .name {
                        margin-right: 8px;
                    }
                }
            }

            .select-group {
                width: 100%;
                margin-top: 20px;
                display: grid;
                grid-template-columns: repeat(2, 50%);
                grid-template-rows: repeat(2, auto);
                row-gap: 8px;

                .selector {
                    width: fit-content;
                    display: flex;
                    align-items: center;

                    .name {
                        width: 50px;
                        margin-right: 8px;
                    }

                    &:nth-child(2n) {
                        justify-self: end;
                    }
                }
            }

            .search-box {
                width: 100%;
                margin-top: 20px;

                .icon-box {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    margin-left: 17px;
                    background-color: #00b6ff;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 30px;
                    cursor: pointer;
                }
            }
        }

        .table {
            width: 100%;
            margin-top: 30px;
            font-size: 14px;

            .page {
                margin-top: 10px;
                text-align: right;

                & /deep/ input {
                    width: 44px;
                }
            }

            & /deep/ .ivu-table-wrapper {
                .ivu-table-cell {
                    padding: 0 3px;
                }
            }
        }
    }
}
</style>
