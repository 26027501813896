<template>
<div class="nearby-left">
    <Header title="周边楼盘" :list="navList"></Header>
    <div class="content bigContent">
        <div class="options">
            <Select v-model="selectedId" style="width: 360px" placeholder="选择楼盘" multiple @on-change="selectLp" filterable :max-tag-count="2">
                <Option v-for="item in lpList" :value="item.id" :key="item.id">{{
          item.name
        }}</Option>
            </Select>
        </div>

        <div class="table">
            <Table tooltip-theme="light" :width="440" :loading="isLoading" stripe :columns="columns" :data="tableData"></Table>
        </div>
    </div>

</div>
</template>

<script>
import Header from "../LeftHeader";
import {
    zblp
} from "@/api/projectAnalysis";

export default {
    name: "nearbyLeft",
    components: {
        Header,
    },
    props: {
        navList: {
            type: Array,
            default () {
                return [];
            },
        },
    },
    data() {
        return {
            nearby: "",

            lpList: [],
            selectedId: [],

            columns: [{
                title: "项目名称",
                key: "name",
                width: 94,
                align: "center",
            }, ],
            tableData: [],
            indicatorMap: {
                xmmc: "小区名称",
                ssbk: "板块/商圈",
                xszt: "销售状态",
                wylx: "物业类型",
                cqnx: "产权年限",
                kfs: "开发商",
                jzlx: "建筑类型",
                jzjg: "建筑结构",
                zxqk: "装修状况",
                // dtpz: "电梯配置",
                // xzljb: "写字楼级别",
                // symj: "商业面积",
                // bgmj: "办公面积",
                // jznd: "建筑年代",
                zthx: "主力户型",
                kpsj: "开盘时间",
                jfsj: "交房时间",
                zdmj: "占地面积",
                zjjmj: "建筑面积",
                ghhs: "总户数",
                lazs: "楼栋总数",
                lhl: "绿化率",
                rjl: "容积率",
                cws: "停车位数",
                cwpb: "停车位配比",
                wygs: "物业公司",
                wyf: "物业费",
            },

            isLoading: true,
        };
    },

    computed: {
        projectDetail() {
            return this.$store.state.project.projectDetail;
        },
        shi() {
            return this.$store.getters["project/shi"];
        },
    },

    mounted() {
        this.getNearbyData();
    },

    methods: {
        getNearbyData() {
            zblp({
                quId: this.projectDetail.qu,
                shengId: this.projectDetail.sheng,
                shiId: this.shi,
            }).then((res) => {

                if (res.length === 0) return;

                const map = new BMap.Map("tmpMap");
                const projectPoint = new BMap.Point(
                    this.projectDetail.jd,
                    this.projectDetail.wd
                );
                map.centerAndZoom(projectPoint, 12);

                res = res.filter((item) => {
                    const point = new BMap.Point(item.jd, item.wd);
                    const distance = map.getDistance(point, projectPoint);
                    return distance <= 5000;
                });

                this.lpDateList = res;
                this.selectedId = [res[3].id];


                res.forEach((item) => {
                    this.lpList.push({
                        id: item.id,
                        name: item.xmmc,
                    });
                    //处理回调回来的的数据
                    item.lhl = item.lhl ? `${item.lhl * 100}%` : "";
                    item.symj = item.symj ? `${(item.symj * 1).toFixed(0)}㎡` : "";
                    item.bgmj = item.bgmj ? `${(item.bgmj * 1).toFixed(0)}㎡` : "";
                    item.zjjmj =
                        (item.zjjmj * 1).toFixed(0) != 0 ?
                        `${(item.zjjmj * 1).toFixed(0)}㎡` :
                        "";
                    item.zdmj =
                        (item.zdmj * 1).toFixed(0) != 0 ?
                        `${(item.zdmj * 1).toFixed(0)}㎡` :
                        "";
                    item.wyf = item.wyf ? `${item.wyf}元/㎡/月` : "";
                });
                // this.genTableData([res[4]]);
            });
        },

        genTableData(data) {
            this.tableData = [];
            this.columns = this.columns.slice(0, 1);
            this.columns = this.columns.concat(
                data.map((item) => {
                    return {
                        title: item.xmmc,
                        key: `${item.id}`,
                        align: "center",
                        tooltip: true,
                    };
                })
            );
            Object.entries(this.indicatorMap).forEach((entry, index) => {
                data.forEach((lp, lpIdx) => {
                    const lpKey = `${lp.id}`;
                    if (lpIdx > 0) {
                        this.tableData[index][lpKey] = lp[entry[0]];
                    } else {
                        this.tableData.push({
                            name: entry[1],
                            [lpKey]: lp[entry[0]],
                        });
                    }
                });
            });

            this.isLoading = false;
        },

        selectLp(value) {
            if (value.length > 5) {
                this.selectedId.pop();
                // this.$Message.warning('最多选择5个楼盘');
                this.$msg.error({
                    text: "最多选择5个楼盘"
                });

                return;
            }
            this.isLoading = true;
            const tableDate = [];
            value.forEach((v) => {
                let obj = null;
                this.lpDateList.some((item) => {
                    if (item.id === v) {
                        obj = item;
                        return true;
                    }
                });
                tableDate.push(obj);
            });
            this.genTableData(tableDate);
        },
    },
};
</script>

<style lang="scss" scoped>
.nearby-left {
    position: relative;

    .header {
        z-index: 10;
    }

    .content {
        display: block;
        // padding-bottom: 120px;
        &.bigContent {
            padding-top: 60px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 100px;
        }

        .options {
            width: 100%;
            margin-top: 20px;
            padding: 0 20px;
        }

        .table {
            width: 100%;
            margin-top: 20px;
            padding: 0 20px;

            & /deep/ .ivu-table-wrapper {
                .ivu-table-cell {
                    padding-left: 4px;
                    padding-right: 4px;
                }

                .ivu-table-stripe .ivu-table-body tr:nth-child(2n) td,
                .ivu-table-stripe .ivu-table-fixed-body tr:nth-child(2n) td {
                    background-color: #f4faff;
                }

                .ivu-table-tbody {
                    border-top: 1px solid #d6d7d9;
                }

                .ivu-table-tbody .ivu-table-column-center {
                    border-right: 1px solid #d6d7d9;
                    border-bottom: 1px solid #d6d7d9;

                    &:first-child {
                        border-left: 1px solid #d6d7d9;
                    }
                }
            }
        }
    }

}
</style>
