<template>
    <div class="economic-right">
        <div class="header">
            <span class="tab active">交易所</span>
            <span class="tab">司法拍卖</span>
            <span class="tab">产权交易中心</span>
        </div>
        <div class="search-box">
            <div class="time">
                <span class="name">时间</span>
                <DatePicker
                    :value="timeRange[0]"
                    format="yyyy-MM-dd"
                    type="date"
                    placeholder="起始日期"
                    style="width: 150px"
                    @on-change="changeStartTime"
                ></DatePicker>
                <span class="zhi">至</span>
                <DatePicker
                    :value="timeRange[1]"
                    format="yyyy-MM-dd"
                    type="date"
                    placeholder="截止日期"
                    style="width: 150px"
                    @on-change="changeEndTime"
                ></DatePicker>
            </div>
            <div class="lpmc">
                <Input
                    v-model="keyword"
                    placeholder="请输入项目名称或地址"
                    style="width: 100%"
                    @on-enter="handleSearch"
                />
                <span class="icon-box" @click="handleSearch">
                    <Icon type="ios-search" color="#fff" :size="16" />
                </span>
            </div>
        </div>

        <div class="result">
            <div class="result-item" v-for="item in resultList" :key="item.id" @click="handleItemClick(item)">
                <div class="left">
                    <img :src="tempImg" />
                </div>
                <div class="middle">
                    <p class="name">{{ item.xmmc }}</p>
                    <p class="params">
                        <span class="hx">{{ item.zthx }}</span>
                        <span class="area">{{ item.fwmjxy }}-{{ item.fwmjdy }}m²</span>
                    </p>
                    <p class="box">
                        <span class="tab xszt">{{ item.xszt }}</span>
                        <span class="tab wylx" :title="item.wylx">{{ item.wylx }}</span>
                        <span class="link">评估</span>
                    </p>
                </div>
                <div class="right">
                    <p class="price-list">
                        <span class="type new">新</span>
                        <span class="price"
                            ><span class="num">{{ item.ysckj }}</span
                            >元/m²</span
                        >
                    </p>
                    <p class="price-list">
                        <span class="type">二</span>
                        <span class="price"
                            ><span class="num">{{ item.esfj }}</span
                            >元/m²</span
                        >
                    </p>
                    <p class="price-list">
                        <span class="type">租</span>
                        <span class="price"
                            ><span class="num">{{ item.zjjg }}</span
                            >元/m²</span
                        >
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BMap from 'BMap';
import BMapLib from 'BMapLib';
import { zblp } from '@/api/projectAnalysis';

export default {
    name: 'economic-right',
    data() {
        return {
            keyword: '',
            timeRange: [],

            tempImg: require('@/assets/image/buildingImg.jpg'),
        };
    },

    computed: {
        projectDetail() {
            return this.$store.state.project.projectDetail;
        },

        resultList: {
            get() {
                return this.$store.state.project.lpList ? this.$store.state.project.lpList.list : [];
            },
            set(value) {
                this.$store.state.project.lpList.list = value;
            },
        },
        map: {
            get() {
                return this.$store.state.project.map;
            },
            set(newValue) {
                this.$store.state.project.map = newValue;
            },
        },
    },

    mounted() {
        this.handleSearch();
    },

    methods: {
        handleSearch() {
            if (!this.projectDetail) {
                // this.$Message.warning(`请先选择项目`);
          this.$msg.error({ type: "remind", code: 4024 });



                return;
            }

            if ((!this.timeRange[0] && this.timeRange[1]) || (this.timeRange[0] && !this.timeRange[1])) {
                // this.$Message.warning(`时间，请选择一个范围`);
          this.$msg.error({ type: "remind", code: 4021 });




                return;
            }

            zblp({
                shengId: this.projectDetail.sheng,
                quId: this.projectDetail.qu,
                shiId: this.projectDetail.shi,
                sj: this.timeRange,
                xqmc: this.keyword,
            }).then((res) => {
                const map = new BMap.Map();
                const projectPoint = new BMap.Point(this.projectDetail.jd, this.projectDetail.wd);

                const result = res.filter((item) => {
                    const point = new BMap.Point(item.jd, item.wd);
                    const projectPoint = new BMap.Point(this.projectDetail.jd, this.projectDetail.wd);

                    const distance = map.getDistance(point, projectPoint);

                    return distance < 5000;
                });

                this.$store.commit('project/setState', {
                    prop: 'lpList',
                    value: {
                        list: result,
                        total: result.length,
                    },
                });
            });
        },

        changeStartTime(value) {
            this.timeRange[0] = value;
        },
        changeEndTime(value) {
            this.timeRange[1] = value;
        },

        handleItemClick(item) {
            if (!item.jd || !item.wd) return;

            const type = 'right';
            const overlays = this.map.getOverlays();
            const target = overlays.find((item) => item.type === type);

            this.map.removeOverlay(target);

            const point = new BMap.Point(item.jd, item.wd);
            const marker = new BMap.Marker(point);
            marker.type = type;
            this.map.centerAndZoom(point, 15);
            this.map.addOverlay(marker);
        },
    },
};
</script>

<style lang="scss" scoped>
.economic-right {
    width: 100%;
    height: 100%;

    .header {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #fcfcff;
        font-size: 14px;
        color: #333;
        border-bottom: 2px solid #00b6ff;

        .tab {
            flex: 1;
            height: 100%;
            line-height: 60px;
            text-align: center;
        }

        .active {
            color: #fff;
            background-color: #00b6ff;
        }
    }

    .search-box {
        width: 100%;
        margin-top: 14px;
        padding-left: 10px;

        .name {
            margin-right: 20px;
        }

        .zhi {
            margin: 0 10px;
        }
    }

    .lpmc {
        width: 100%;
        padding: 14px 10px 0px 0px;
        display: flex;
        align-items: center;

        .icon-box {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-left: 17px;
            background-color: #00b6ff;
            border-radius: 5px;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
        }
    }

    .result {
        width: 100%;
        height: calc(100% - 150px);
        padding: 0 10px;
        overflow: auto;

        &-item {
            width: 100%;
            padding: 14px 0px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #d6d7d9;

            .left {
                width: 105px;
                height: 80px;
                margin-right: 10px;
                font-size: 14px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .middle {
                .name {
                    margin-bottom: 10px;
                    font-size: 16px;
                    color: #333;
                }

                .params {
                    font-size: 14px;
                    color: #666;
                    margin-bottom: 10px;

                    .hx {
                        margin-right: 30px;
                    }
                }

                .box {
                    display: flex;
                    align-items: center;
                    font-size: 14px;

                    .tab {
                        width: 36px;
                        height: 22px;
                        margin-right: 10px;
                        padding: 0 2px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;

                        &.xszt {
                            border: 1px solid #ee7d78;
                            color: #ee7d78;
                        }

                        &.wylx {
                            border: 1px solid #e0e0e0;
                            color: #333;
                        }
                    }

                    .link {
                        margin-right: 20px;
                        color: #00b6ff;
                        cursor: pointer;
                    }
                    .date {
                        color: #333;
                    }
                }
            }

            .right {
                flex: 1;
                margin-left: 10px;
                text-align: right;

                .price-list {
                    margin-bottom: 8px;
                    font-size: 12px;
                    color: #333;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .type {
                        padding: 2px;
                        font-size: 12px;
                        border: 1px solid #e0e0e0;
                        color: #666;
                        border-radius: 2px;

                        &.new {
                            color: #ee7d78;
                            border: 1px solid #ee7d78;
                        }
                    }

                    .num {
                        margin-left: 3px;
                        font-size: 16px;
                        color: #ff3333;
                    }
                }
            }
        }
    }
}
</style>
