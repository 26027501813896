import JQ from "jquery"
import { post, get } from "@/utils/http";
window.showList =  function(data) {
	return data
}
export function getAdress(params) {
	let url =
		`https://api.map.baidu.com/place/v2/search?query=${params.query}&region=%E5%85%A8%E5%9B%BD&output=json&ak=IrxtF2IWoZUU2w0S99T3AFEbIZZ9Ki7L&callback=showList`
	JQ.getScript(url)
}
export function reverseGeocoding(lat,lng){
	return get({ url: `https://api.map.baidu.com/reverse_geocoding/v3/?ak=IrxtF2IWoZUU2w0S99T3AFEbIZZ9Ki7L&output=json&coordtype=wgs84ll&location=${lat},${lng}`})
}

// 23.11065354410606,113.33082133102815