<template>
<div class="land-market-left">
    <Header title="土地市场" :list="navList"></Header>
    <div class="content bigContent">
        <LeftTabs :tabsList="tabsList"></LeftTabs>

        <div class="pie-box">
            <div class="options">
                <Row class="row">
                    <Select v-model="timeDimention" style="width:65px; margin-right: 20px" @on-change="timeChange">
                        <Option v-for="item in timeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                    <DatePicker :type="dateType" :value="startTime" :format="dateFormat" placeholder="起始日期" :style="{ width: timeDimention === 2 ? '80px' : '96px' }" @on-change="changeStartTime"></DatePicker>
                    <Select v-model="quarterStart" style="width:68px;" v-show="timeDimention === 2" @on-change="getPieData">
                        <Option v-for="item in quarterList" :value="item" :key="item">{{ item }}</Option>
                    </Select>
                    <span class="zhi">至</span>
                    <DatePicker :type="dateType" :value="endTime" :format="dateFormat" placeholder="截止日期" :style="{ width: timeDimention === 2 ? '80px' : '96px' }" @on-change="changeEndTime"></DatePicker>
                    <Select v-model="quarterEnd" style="width:68px;" v-show="timeDimention === 2" @on-change="getPieData">
                        <Option v-for="item in quarterList" :value="item" :key="item">{{ item }}</Option>
                    </Select>
                </Row>

                <Row class="row">
                    <span class="text">土地用途</span>
                    <Select v-model="landUse" style="width:300px;" label-in-value @on-change="changeLandUse" multiple :max-tag-count="2">
                        <Option v-for="item in landUseList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </Row>
            </div>
            <div class="data-view">
                <div class="left">
                    <ul class="list">
                        <li class="list-item">
                            <span class="name">成交宗数</span>
                            <span class="value" :title="topData.religion || 0">{{ topData.religion || 0 }}宗</span>
                        </li>
                        <li class="list-item">
                            <span class="name">成交面积</span>
                            <span class="value" :title="topData.bargainArea + '万m²' || 0 + '万m²'">{{ topData.bargainArea || 0 }}万m²</span>
                        </li>
                        <li class="list-item">
                            <span class="name">成交建面</span>
                            <span class="value" :title="topData.bargainBuild + '万m²' || 0 + '万m²'">{{ topData.bargainBuild || 0 }}万m²</span>
                        </li>
                        <li class="list-item">
                            <span class="name">成交价</span>
                            <span class="value" :title="topData.transactionPrice + '万元' || 0 + '万元'">{{ topData.transactionPrice || 0 }}万元</span>
                        </li>
                        <li class="list-item">
                            <span class="name">楼面价</span>
                            <span class="value" :title="topData.floorPrice + '元/m²' || 0 + '元/m²'">{{ topData.floorPrice || 0 }}元/m²</span>
                        </li>
                        <li class="list-item">
                            <span class="name">溢价率</span>
                            <span class="value" :title="topData.premiumRate + '%' || 0 + '%'">{{ topData.premiumRate || 0 }}%</span>
                        </li>
                    </ul>
                </div>
                <div class="right" ref="pie"></div>
            </div>
        </div>

        <div class="echart-box">
            <div class="title">
                <span class="left">供求走势</span>

                <div class="right">
                    <Button class="btn-one" :type=middleButtonType1 @click="genIndicatorBar(1)">供应面积</Button>
                    <Button :type=middleButtonType2 @click="genIndicatorBar(2)">成交面积</Button>
                </div>
            </div>

            <div class="echart" ref="indicatorsBar"></div>
        </div>

        <div class="echart-box">
            <div class="title">
                <span class="left">楼面价/成交价</span>

                <div class="right">
                    <Button class="btn-one" :type=bottomButtonType1 @click="genPriceCharts(1)">成交价</Button>
                    <Button :type=bottomButtonType2 @click="genPriceCharts(2)">楼面价</Button>
                </div>
            </div>

            <div class="echart" ref="priceCharts"></div>
        </div>
    </div>

</div>
</template>

<script>
import echarts from 'echarts';
import Header from '../LeftHeader';
import LeftTabs from '../LeftTabs';
import {
    singleArea,
    tdscCjcr,
    tdscZyjjzb
} from '@/api/projectAnalysis';
import {
    formatDate,
    returnFloat
} from '@/utils/index';

export default {
    name: 'landMarketLeft',
    components: {
        Header,
        LeftTabs,
    },
    props: {
        navList: {
            type: Array,
            default () {
                return [];
            },
        },
    },
    data() {
        return {
            tabsList: [],
            timeList: [{
                    value: 1,
                    label: '月度',
                },
                // {
                //     value: 2,
                //     label: '季度',
                // },
                {
                    value: 3,
                    label: '年度',
                },
            ],
            timeDimention: 1,
            landUse: 'null',

            dateType: 'month',
            dateFormat: 'yyyy-MM',

            startTime: '',
            endTime: '',

            quarterStart: '',
            quarterEnd: '',

            quarterList: ['Q1', 'Q2', 'Q3', 'Q4'],

            bargainData: null,
            topData: {},
            topLabelList: [],

            middleButtonType1: 'primary',
            middleButtonType2: 'default',
            bottomButtonType1: 'primary',
            bottomButtonType2: 'default',
        };
    },

    computed: {
        projectDetail() {
            return this.$store.state.project.projectDetail;
        },
        projectData() {
            return this.$store.state.project.option;
        },
        landUseList() {
            return this.projectData.landUse;
        },
        shi() {
            return this.$store.getters['project/shi'];
        },
    },

    mounted() {
        this.landUse = this.landUseList.map((item) => item.id);

        this.getCity();
        this.defaultDateRange(this.timeDimention);
        this.getPieData();
        this.getIndicatorData();
    },
    methods: {
        getCity() {
            singleArea({
                id: this.projectDetail.shi,
            }).then((res) => {
                this.tabsList.push({
                    id: res.id,
                    name: res.districtName,
                });
            });
        },

        getPieData() {
            const {
                startTime,
                endTime
            } = this.formatTimeRange();

            const params = {
                landTime: [startTime, endTime],
                landUse: this.landUse,
                provinceId: this.projectDetail.shi,
                timePeriod: this.timeDimention,
            };

            tdscCjcr(params).then((res) => {
                this.topLabelList = res.list
                    .filter((item) => !item.landUse.includes('+'))
                    .map((item) => {
                        item.checked = true;
                        return item;
                    });
                this.topData = {
                    bargainArea: Math.round(res.bargainArea / 10000),
                    bargainBuild: Math.round(res.bargainBuild / 10000),
                    floorPrice: Math.round(res.floorPrice),
                    premiumRate: Number((res.premiumRate * 100).toFixed(2)),
                    religion: Number(res.religion.toFixed(2)),
                    transactionPrice: Math.round(res.transactionPrice / 10000),
                };
                this.genPie();
            });
        },

        genPie() {
            this.getIndicatorData();

            const myChart = echarts.init(this.$refs.pie);

            const data = this.topLabelList.map((item) => {
                if (item.checked) {
                    return {
                        name: item.landUse,
                        value: Math.round(item.bargainArea / 10000),
                    };
                }
            });

            const legend = this.topLabelList.map((item) => {
                if (item.checked) {
                    return item.landUse;
                }
            });

            const options = {
                color: ['#92D051', '#00B0F0', '#E36C0C', '#FFC000', '#FFCC66', '#66FFFF'],
                tooltip: {
                    trigger: 'item',
                    formatter: '{b}: {c}万平米 <br/> 占比: {d}%',
                },
                legend: {
                    orient: 'horizontal',
                    bottom: -5,
                    data: legend,
                    icon: 'circle',
                    textStyle: {
                        fontSize: 12,
                        color: '#666',
                    },
                    itemWidth: 9,
                    itemHeight: 9,
                },
                series: [{
                    type: 'pie',
                    radius: '80%',
                    center: ['53%', '41%'],
                    data: data,
                    hoverAnimation: false,
                    labelLine: {
                        show: false,
                    },
                    label: {
                        show: false,
                    },
                }, ],
            };

            myChart.setOption(options);
        },

        getIndicatorData() {
            const {
                startTime,
                endTime
            } = this.formatTimeRange();

            const params = {
                landTime: [startTime, endTime],
                landUse: this.landUse,
                provinceId: this.projectDetail.shi,
                timePeriod: this.timeDimention,
            };

            tdscZyjjzb(params).then((res) => {
                this.bargainData = res;
                this.genIndicatorBar(1);
                this.genPriceCharts(1);
            });
        },

        genIndicatorBar(type) {
            if (type == 1) {
                this.middleButtonType1 = 'primary';
                this.middleButtonType2 = 'default';
            } else {
                this.middleButtonType1 = 'default';
                this.middleButtonType2 = 'primary';
            }

            const bar = echarts.init(this.$refs.indicatorsBar);

            const legend = type === 1 ? ['供应面积', '同比增长'] : ['成交面积', '同比增长'];
            const data1 =
                type === 1 ?
                this.bargainData.map((item) => Math.round(item.supplyArea / 10000)) :
                this.bargainData.map((item) => Math.round(item.bargainArea / 10000));
            const data2 =
                type === 1 ?
                this.bargainData.map((item) => returnFloat(item.supplyAreaWith, 2)) :
                this.bargainData.map((item) => returnFloat(item.bargainAreaWith, 2));

            const options = {
                color: ['#FF9E37', '#92D051'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56',
                        },
                    },
                    formatter(params) {
                        let unit = '';
                        let relVal = params[0].name;
                        for (var i = 0, l = params.length; i < l; i++) {
                            unit = i === 0 ? '万m²' : '%';
                            relVal +=
                                '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value + unit;
                        }
                        return relVal;
                    },
                },
                grid: {
                    top: '35px',
                    left: 0,
                    bottom: 0,
                    right: 0,
                    containLabel: true,
                },
                legend: {
                    top: 0,
                    right: 0,
                    data: legend,
                },
                xAxis: [{
                    type: 'category',
                    boundaryGap: true,
                    data: this.bargainData.map((item) => item.landTime),
                }, ],
                yAxis: [{
                        type: 'value',
                        scale: true,
                        splitNumber: 3,
                    },
                    {
                        type: 'value',
                        scale: true,
                        splitNumber: 3,
                        axisLabel: {
                            formatter: '{value}%',
                        },
                        axisPointer: {
                            show: true,
                            label: {
                                formatter(params) {
                                    return returnFloat(params.value, 2) + '%';
                                },
                            },
                        },
                    },
                ],
                series: [{
                        name: legend[0],
                        type: 'bar',
                        yAxisIndex: 0,
                        data: data1,
                        barWidth: 8,
                        barGap: 0,
                    },
                    {
                        name: legend[1],
                        type: 'line',
                        yAxisIndex: 1,
                        data: data2,
                    },
                ],
            };

            bar.setOption(options);
        },

        genPriceCharts(type) {
            if (type == 1) {
                this.bottomButtonType1 = 'primary';
                this.bottomButtonType2 = 'default';
            } else {
                this.bottomButtonType1 = 'default';
                this.bottomButtonType2 = 'primary';
            }

            const myChart = echarts.init(this.$refs.priceCharts);

            const legend = type === 1 ? ['成交价', '同比增长'] : ['楼面价', '同比增长'];
            const data1 =
                type === 1 ?
                this.bargainData
                .map((item) => Math.round((item.transactionPrice / 100000000))) :
                this.bargainData.map((item) => Math.round(item.startRegion));
            const data2 =
                type === 1 ?
                this.bargainData.map((item) => returnFloat(item.transactionPriceWith, 2)) :
                this.bargainData.map((item) => returnFloat(item.startRegionWith, 2));

            const option = {
                color: ['#5C89FF', '#00B6FF'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56',
                        },
                    },
                    formatter(params) {
                        let unit = '';
                        let relVal = params[0].name;
                        for (var i = 0, l = params.length; i < l; i++) {
                            unit = i === 0 ? type === 1 ? '亿元' : '元/m²' : '%';
                            relVal +=
                                '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value + unit;
                        }
                        return relVal;
                    },
                },
                grid: {
                    top: '35px',
                    left: 0,
                    bottom: 0,
                    right: 0,
                    containLabel: true,
                },
                legend: {
                    top: 0,
                    right: 0,
                    data: legend,
                },
                xAxis: [{
                    type: 'category',
                    boundaryGap: true,
                    data: this.bargainData.map((item) => item.landTime),
                }, ],
                yAxis: [{
                        type: 'value',
                        scale: true,
                        splitNumber: 3,
                        axisLabel: {
                            // formatter: type === 1 ? '{value}' : '{value}%'
                        },
                    },
                    {
                        type: 'value',
                        scale: true,
                        splitNumber: 3,
                        axisLabel: {
                            formatter: '{value}%'
                        },
                        axisPointer: {
                            show: true,
                            label: {
                                formatter(params) {
                                    return returnFloat(params.value, 2) + '%';
                                },
                            },
                        },
                    },
                ],
                series: [{
                        name: legend[0],
                        type: 'bar',
                        yAxisIndex: 0,
                        data: data1,
                        barWidth: 8,
                    },
                    {
                        name: legend[1],
                        type: 'line',
                        yAxisIndex: 1,
                        smooth: true,
                        data: data2,
                    },
                ],
            };

            myChart.setOption(option);
        },

        timeChange(value) {
            switch (value) {
                case 1:
                    this.dateType = 'month';
                    this.dateFormat = 'yyyy-MM';
                    break;
                case 2:
                    this.dateType = 'year';
                    this.dateFormat = 'yyyy';
                    break;
                case 3:
                    this.dateType = 'year';
                    this.dateFormat = 'yyyy';
                    break;
            }

            this.defaultDateRange(value);
            this.getPieData();
        },

        formatTimeRange() {
            let startTime = this.startTime;
            let endTime = this.endTime;

            if (typeof startTime !== 'string') {
                startTime = formatDate(startTime);
            }
            if (typeof endTime !== 'string') {
                endTime = formatDate(endTime);
            }

            if (this.dateType === 'month') {
                startTime = startTime.substring(0, 7);
                endTime = endTime.substring(0, 7);
            } else if (this.dateType === 'year') {
                if (this.timeDimention === 2) {
                    startTime = startTime.substring(0, 4);
                    endTime = endTime.substring(0, 4);
                    startTime = `${startTime}-${this.quarterStart}`;
                    endTime = `${endTime}-${this.quarterEnd}`;
                } else {
                    startTime = startTime.substring(0, 4);
                    endTime = endTime.substring(0, 4);
                }
            }

            return {
                startTime,
                endTime
            };
        },

        defaultDateRange(cycle) {
            switch (cycle) {
                case 1:
                    this.endTime = new Date();
                    this.startTime = new Date(new Date().setMonth(this.endTime.getMonth() - 6));
                    break;
                case 2: {
                    const date = new Date();
                    let year = date.getFullYear();
                    let month = date.getMonth() + 1;
                    let quarter = '';
                    let startYear = '';
                    let startQuarter = '';

                    if (month >= 1 && month <= 3) {
                        quarter = 'Q1';
                        startYear = year - 1;
                        startQuarter = 'Q2';
                    } else if (month >= 4 && month <= 6) {
                        quarter = 'Q2';
                        startYear = year - 1;
                        startQuarter = 'Q3';
                    } else if (month >= 7 && month <= 9) {
                        quarter = 'Q3';
                        startYear = year - 1;
                        startQuarter = 'Q4';
                    } else {
                        quarter = 'Q4';
                        startYear = year;
                        startQuarter = 'Q1';
                    }

                    this.endTime = date;
                    this.quarterEnd = quarter;
                    this.startTime = new Date(`${startYear}`);
                    this.quarterStart = startQuarter;

                    break;
                }
                case 3:
                    this.endTime = new Date();
                    this.startTime = new Date(new Date().setFullYear(this.endTime.getFullYear() - 6));
                    break;
            }
        },

        changeStartTime(value) {
            this.startTime = value;
            this.getPieData();
        },
        changeEndTime(value) {
            this.endTime = value;
            this.getPieData();
        },

        changeLandUse(data) {
            this.topLabelList.forEach((item) => {
                item.checked = false;
            });

            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < this.topLabelList.length; j++) {
                    if (data[i].label === this.topLabelList[j].landUse) {
                        this.topLabelList[j].checked = true;
                    }
                }
            }

            this.getPieData();
        },
    },
};
</script>

<style lang="scss" scoped>
.land-market-left {
    width: 100%;
    padding-bottom: 20px;
    position: relative;
.header{
    z-index: 10;
}
    .content {
        display: block;
        &.bigContent {
            padding-top: 60px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 100px;
        }

        .pie-box {
            width: 100%;
            margin-top: 30px;
            padding: 0 20px;

            .options {
                .row {
                    margin-bottom: 10px;

                    .zhi {
                        display: inline-block;
                        width: 14px;
                        margin: 0 10px;
                    }

                    .text {
                        display: inline-block;
                        width: 56px;
                        margin-right: 10px;
                        font-size: 14px;
                    }
                }
            }

            .data-view {
                width: 100%;
                margin-top: 26px;
                display: flex;

                .left {
                    flex: 1;

                    .list {
                        width: 100%;
                        list-style: none;
                        color: #333;

                        .list-item {
                            margin-bottom: 20px;
                            display: flex;
                            align-items: center;

                            .name {
                                width: 45%;
                            }

                            .value {
                                flex: 1;
                                overflow: hidden;
                                white-space: nowrap;
                            }
                        }
                    }
                }

                .right {
                    width: 58%;
                    height: 220px;
                }
            }
        }

        .echart-box {
            margin-top: 45px;
            padding: 0 20px;

            .title {
                width: 100%;
                height: 24px;
                padding-left: 16px;
                font-size: 18px;
                font-weight: bold;
                color: #1562d6;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .btn-one {
                    margin-right: 10px;
                }

                &::before {
                    content: '';
                    width: 4px;
                    height: 20px;
                    background-color: #1562d6;
                    position: absolute;
                    left: 0;
                    top: 2px;
                }
            }

            .echart {
                width: 100%;
                height: 150px;
                margin-top: 12px;
            }
        }
    }

}
</style>
