<template>
    <div class="tabs">
        <div class="tab" v-for="item in tabsList" :key="item.id">
            <span>{{ item.name }}</span>

            <div class="underline"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'leftTabs',
    props: {
        tabsList: {
            type: Array,
            default() {
                return [];
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.tabs {
    width: 100%;
    height: 48px;
    background-color: #fcfcff;
    border-top: 1px solid #d6d7d9;
    border-bottom: 1px solid #d6d7d9;
    display: flex;
    align-items: center;

    .tab {
        flex: 1;
        height: 100%;
        line-height: 48px;
        text-align: center;
        cursor: pointer;
        position: relative;

        .underline {
            width: 60px;
            height: 3px;
            background-color: #00b6ff;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
        }
    }
}
</style>
