<template>
  <div class="detail-right">
    <div class="header">
      <div class="selector">
        <Dropdown
          trigger="custom"
          :visible="dealStateVisible"
          @on-click="dealStateChange"
          @on-clickoutside="dealStateVisible = false"
        >
          <a href="javascript:void(0)" @click="dealStateOpen">
            交易状态
            <Icon type="ios-arrow-down"></Icon>
          </a>
          <DropdownMenu slot="list">
            <DropdownItem
              v-for="item in dealState"
              :name="item.id"
              :key="item.id"
            >
              <Icon
                class="icon"
                type="md-checkmark"
                v-show="dealStateId.includes(item.id)"
              />
              {{ item.name }}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div class="selector time-range">
        <a class="text" href="javascript:void(0)" @click="timeRangeClick">
          {{ timeTypeText }}
          <Icon type="ios-arrow-down"></Icon>
        </a>
        <div class="date-picker" v-show="showDatePicker">
          <DatePicker
            type="date"
            :value="timeRange[0]"
            format="yyyy-MM-dd"
            placeholder="起始日期"
            style="width: 130px"
            @on-change="changeStartTime"
          ></DatePicker>
          <span class="zhi">至</span>
          <DatePicker
            type="date"
            :value="timeRange[1]"
            format="yyyy-MM-dd"
            placeholder="结束日期"
            style="width: 130px"
            @on-change="changeEndTime"
          ></DatePicker>
        </div>
      </div>
      <div class="selector land-use">
        <Dropdown
          trigger="custom"
          :visible="landUseVisible"
          @on-click="landUseChange"
          @on-clickoutside="landUseVisible = false"
        >
          <a href="javascript:void(0)" @click="landUseOpen">
            用地性质
            <Icon type="ios-arrow-down"></Icon>
          </a>
          <DropdownMenu slot="list">
            <DropdownItem
              v-for="item in landUse"
              :name="item.id"
              :key="item.id"
            >
              <Icon
                class="icon"
                type="md-checkmark"
                v-show="landUseId.includes(item.id)"
              />
              {{ item.name }}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
    <div class="search-box">
      <Input
        v-model="keyword"
        placeholder="请输入地块名称或地址"
        style="width: 100%"
        @on-enter="handleSearch"
      />
      <span class="icon-box" @click="handleSearch">
        <Icon type="ios-search" color="#fff" :size="16" />
      </span>
    </div>
    <div class="banner">
      <span class="left">共{{ total }}个结果</span>
      <div class="sort">
        <div
          class="item"
          v-for="(item, index) in sortList"
          :key="index"
          @click="handleResultSort(item, index)"
        >
          {{ item.name }}
          <i :class="['sort-icon', { active: index === sortType }]"></i>
        </div>
      </div>
    </div>

    <div class="result">
      <div
        class="result-item"
        v-for="item in resultList"
        :key="item.id"
        @click.stop="handleItemClick(item)"
      >
        <div class="left">
          <p class="deal-state">{{ item.stateText }}</p>
          <p class="land-use">{{ item.tdyt }}</p>
        </div>
        <div class="middle">
          <p class="name" :title="item.dkwz">{{ item.dkwz }}</p>
          <p>
            <span class="date">距离本案:{{ item.distance.toFixed(0) }}米</span>
          </p>
          <p class="box">
            <span class="link" @click="toDetail(item.id)">地块详情</span>
            <span class="date">{{ item.gpkssj }}</span>
          </p>
        </div>
        <div class="right">
          <p class="unit-price" v-if="item.cjlmj">
            <span class="num">{{ item.cjlmj }}</span
            >元/m²
          </p>
          <p class="total-price" v-if="item.cjj">{{ item.cjj }}万元</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { zbtd } from "@/api/projectAnalysis";

export default {
  name: "detailRight",
  data() {
    return {
      keyword: "",
      sortList: [
        {
          id: 0,
          name: "截止日期",
          checked: true,
          type: "asc",
        },
        {
          id: 1,
          name: "楼面价",
          checked: false,
          type: "asc",
        },
        {
          id: 2,
          name: "成交价",
          checked: false,
          type: "asc",
        },
      ],
      sortType: 0,

      dealStateId: [],
      timeTypeText: "起始时间",
      timeTypeId: "",
      landUseId: [],

      dealStateVisible: false,
      landUseVisible: false,

      timeRange: [],
      showDatePicker: false,
    };
  },

  computed: {
    projectDetail() {
      return this.$store.state.project.projectDetail;
    },
    projectData() {
      return this.$store.state.project.option;
    },
    dealState() {
      return this.projectData.dealState;
    },
    landUse() {
      return this.projectData.landUse;
    },
    shi() {
      return this.$store.getters["project/shi"];
    },
    resultList: {
      get() {
        return this.$store.state.project.landList
          ? this.$store.state.project.landList.list
          : [];
      },
      set(value) {
        this.$store.state.project.landList.list = value;
      },
    },
    total() {
      return this.$store.state.project.landList
        ? this.$store.state.project.landList.total
        : 0;
    },
    isLoadingLand: {
      get() {
        return this.$store.state.project.isLoadingLand;
      },
      set(value) {
        this.$store.state.project.isLoadingLand = value;
      },
    },
  },

  watch: {
    timeTypeId(value) {
      this.timeType.some((item) => {
        if (item.id == value) {
          this.timeTypeText = item.name;
          return true;
        }
      });
    },
  },

  mounted() {
    this.sortList = this.sortList.map((item, index) => {
      index === 0 ? (item.checked = true) : (item.checked = false);
      return item;
    });
  },

  methods: {
    handleResultSort(item, index) {
      this.sortType = item.id;
      item.checked = true;
      this.sortList.forEach((it) => {
        if (item.id !== it.id) {
          it.checked = false;
        }
      });

      switch (item.id) {
        case 0:
          this.resultList.sort((a, b) => {
            return this.sortFn(
              item.type,
              new Date(a.gpkssj).getTime() || "",
              new Date(b.gpkssj).getTime() || ""
            );
          });
          break;
        case 1:
          this.resultList.sort((a, b) => {
            return this.sortFn(item.type, a.cjlmj, b.cjlmj);
          });
          break;
        case 2:
          this.resultList.sort((a, b) => {
            return this.sortFn(item.type, a.cjj, b.cjj);
          });
          break;
      }

      item.type = item.type === "asc" ? "desc" : "asc";
      this.$set(this.sortList, index, item);
    },
    sortFn(type, a, b) {
      return type === "asc" ? b - a : a - b;
    },

    dealStateChange(value) {
      if (this.dealStateId.indexOf(value) > -1) {
        this.dealStateId.splice(this.dealStateId.indexOf(value), 1);
      } else {
        this.dealStateId.push(value);
      }

      this.handleSearch();
    },
    timeTypeChange(value) {
      this.timeTypeId = value;
    },
    landUseChange(value) {
      if (this.landUseId.indexOf(value) > -1) {
        this.landUseId.splice(this.landUseId.indexOf(value), 1);
      } else {
        this.landUseId.push(value);
      }

      this.handleSearch();
    },

    timeRangeClick(event) {
      if (!event.target.className.includes("text")) return;
      this.showDatePicker = !this.showDatePicker;
    },

    changeStartTime(value) {
      this.timeRange[0] = value;

      this.handleSearch();
    },
    changeEndTime(value) {
      this.timeRange[1] = value;

      this.handleSearch();
      setTimeout(() => {
        this.showDatePicker = !this.showDatePicker;
      }, 1000);
    },

    handleSearch() {
      if (this.isLoadingLand) return;

      if (!this.projectDetail) {
        // this.$Message.warning(`请先选择项目`);

          this.$msg.error({ type: "remind", code: 4024 });


        return;
      }

      if (
        (!this.timeRange[0] && this.timeRange[1]) ||
        (this.timeRange[0] && !this.timeRange[1])
      ) {
        // this.$Message.warning(`起始时间，请选择一个范围`);

          this.$msg.error({ type: "remind", code: 4021 });


        return;
      }

      const params = {
        dkmc: this.keyword,
        qssj: this.timeRange,
        shengId: this.projectDetail.tdsheng,
        quId: this.projectDetail.tdqu,
        shiId: this.projectDetail.tdshi,
        state: this.dealStateId,
        tdyt: this.landUseId,
      };

      params.qssj = params.qssj.filter((item) => !!item);

      this.$parent.getLandData(params);

      this.sortList = this.sortList.map((item, index) => {
        index === 0 ? (item.checked = true) : (item.checked = false);
        return item;
      });
    },
    toDetail(id) {
      const url = this.$router.resolve({
        path: `/Insight/PlaceDetail`,
        query: {
          id: id,
          type: "option",
        },
      });

      window.open(url.href);
    },

    dealStateOpen() {
      this.dealStateVisible = !this.dealStateVisible;
      if (this.showDatePicker) {
        this.showDatePicker = !this.showDatePicker;
      }
    },
    landUseOpen() {
      this.landUseVisible = !this.landUseVisible;
      if (this.showDatePicker) {
        this.showDatePicker = !this.showDatePicker;
      }
    },

    handleItemClick(item) {
      if (!item.jd || !item.wd) {
        // this.$Message.warning(`位置信息不全`);
        this.$msg.error({text: "位置信息不全"});

        return;
      }

      this.$parent.openInfoBox(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-right {
  width: 100%;
  height: 100%;

  .header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fcfcff;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #d6d7d9;

    .selector {
      flex: 1;
      text-align: center;
      position: relative;

      .date-picker {
        display: flex;
        align-items: center;
        background-color: #fff;
        position: absolute;
        top: 30px;
        left: -80px;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0px 0px 6px #ccc;
        z-index: 1;
      }

      .ivu-dropdown-item {
        position: relative;

        .icon {
          position: absolute;
          top: 11px;
          left: 16px;
        }
      }

      &.land-use {
        .ivu-dropdown-item {
          .icon {
            position: absolute;
            top: 12px;
            left: 6px;
          }
        }
      }
    }

    .zhi {
      margin: 0 10px;
    }
  }

  .search-box {
    width: 100%;
    padding: 14px 10px;
    display: flex;
    align-items: center;

    .icon-box {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-left: 17px;
      background-color: #00b6ff;
      border-radius: 5px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }
  }

  .banner {
    width: 100%;
    height: 30px;
    padding-left: 10px;
    padding-right: 7px;
    background-color: #f4f4f4;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sort {
      display: flex;
      align-items: center;
      .item {
        margin-right: 10px;
        cursor: pointer;

        &:nth-child(3) {
          margin-right: 0px;
        }

        .sort-icon {
          display: inline-block;
          width: 10px;
          height: 12px;
          background-image: url(~@/assets/image/map/sort.png);

          &.active {
            background-image: url(~@/assets/image/map/sort_active.png);
          }
        }
      }
    }
  }

  .result {
    width: 100%;
    height: calc(100% - 150px);
    padding: 0 10px;
    overflow: auto;

    &-item {
      width: 100%;
      height: 100px;
      padding: 20px 0px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d6d7d9;

      .left {
        margin-right: 10px;
        font-size: 14px;

        .deal-state {
          margin-bottom: 13px;
          text-align: center;
          line-height: 22px;
          background-color: #ee7d78;
          color: #fff;
        }

        .land-use {
          padding: 0 15px;
          text-align: center;
          line-height: 22px;
          background-color: #fff;
          border: 1px solid #ee7d78;
          color: #ee7d78;
        }
      }

      .middle {
        max-width: 200px;

        .name {
          min-height: 24px;
          margin-bottom: 13px;
          font-size: 16px;
          color: #333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .box {
          font-size: 14px;

          .link {
            margin-right: 20px;
            color: #00b6ff;
            cursor: pointer;
          }
          .date {
            color: #333;
          }
        }
      }

      .right {
        flex: 1;
        height: 100%;
        margin-left: 10px;
        text-align: right;
        position: relative;

        .unit-price {
          margin-bottom: 13px;
          font-size: 12px;
          color: #333;
          position: absolute;
          top: 0;
          right: 0;

          .num {
            font-weight: bold;
            font-size: 16px;
            color: #ff3333;
          }
        }

        .total-price {
          font-size: 14px;
          position: absolute;
          bottom: 0px;
          right: 0px;
        }
      }
    }
  }
}
</style>
