<template>
	<div class="header">
		<div class="name">
			<span class="text" @click="handleMyProjectClick">{{ title }}</span>
			<Icon :size="10" type="ios-arrow-down"></Icon>
		</div>
		<div class="btn-box">
			<div class="btn" @click="handleMyProjectClick">
				<svg-icon iconClass="project"></svg-icon>
				<span class="text">我的项目</span>
			</div>
			<div class="btn btn-disabled">
				<svg-icon iconClass="paper"></svg-icon>
				<span class="text">项目报告</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "analysisHeader",

		props: {
			title: String,
		},

		data() {
			return {};
		},

		computed: {
			showProjectModal: {
				get() {
					return this.$store.state.modal.showProjectModal;
				},
				set(newValue) {
					this.$store.state.modal.showProjectModal = newValue;
				},
			},
			projectDetail() {
				return this.$store.state.project.projectDetail;
			},
		},

		methods: {
			handleMyProjectClick() {
				this.showProjectModal = true;
				this.$emit("click");
			},
			showInfo() {

			},
		},
	};
</script>

<style lang="scss" scoped>
	.header {
		width: 100%;
		height: 70px;
		position: relative;

		.name {
			width: fit-content;
			min-width: 610px;
			margin: 0 auto;
			background-image: url(~@/assets/image/analysis/header.png);
			background-repeat: no-repeat;
			line-height: 70px;
			text-align: center;
			font-size: 24px;
			color: #fff;

			.ivu-icon {
				margin-left: 10px;
				position: relative;
				top: -4px;
			}

			.text {
				cursor: pointer;
			}
		}

		.btn-box {
			position: absolute;
			top: 30px;
			left: 20px;
			display: flex;
			list-style: none;
			font-size: 14px;

			.btn {
				padding: 10px;
				margin-right: 30px;
				display: flex;
				align-items: center;
				color: #000000;
				cursor: pointer;

				&:last-child {
					margin-right: 0;
				}

				.svg-icon {
					width: 15px;
					height: 15px;
					margin-right: 10px;
					fill: #000000;
				}

				.text {
					line-height: 14px;
				}

				&:hover {
					color: #00b6ff;

					.svg-icon {
						fill: #00b6ff;
					}
				}
			}

			.btn-disabled {
				cursor: default;
				color: #666;
				.svg-icon {
					fill: #666;
				}

				&:hover {
					color: #666;

					.svg-icon {
						fill: #666;
					}
				}

			}
		}

		&::before {
			content: "";
			width: 100%;
			height: 5px;
			background-color: #1562d6;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
</style>
