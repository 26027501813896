<template>
  <div class="land-market-right">
    <div class="header">
      <span
        :class="['tab', { active: item.id === selectedTab }]"
        v-for="item in tabList"
        :key="item.id"
        @click="selectTab(item)"
      >{{ item.name }}</span>
    </div>
    <div class="search-box">
      <Input
        v-model="inputVal"
        placeholder="请输入搜索内容"
        style="width: 100%"
        @keyup.enter="handleSearch"
        @on-enter="handleSearch"
      />
      <span class="icon-box" @click="handleSearch">
        <Icon type="ios-search" color="#fff" :size="16" />
      </span>
    </div>
    <ul class="result-list">
      <li
        class="result-item"
        v-for="(item, index) in nearbyList"
        :key="index"
        @click="handleItemClick(item)"
      >
        <p class="top">
          <span class="name" :title="item.title">{{ item.title }}</span>
          <span
            v-for="(type,index) in item.tags"
            v-html="type"
            :key="type"
            :class="['tag',`tag-${index}`]"
          ></span>
        </p>

        <p class="bottom">
          <svg-icon iconClass="map"></svg-icon>
          <span class="distance">{{ item.distance }}米</span>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import BMap from "BMap";

export default {
  name: "landMarketRight",
  data() {
    return {
      inputVal: "",
      tabList: [
        {
          id: 1,
          name: "交通",
          keywords: [
            "地铁",
            "火车站",
            "高铁站",
            "汽车站",
            "飞机场",
            "港口",
            "停车场",
            "加油站",
            "公交站",
            "轻轨",
          ],
        },
        {
          id: 2,
          name: "教育",
          keywords: [
            "幼儿园",
            "小学",
            "中学",
            "高等院校",
            "成人教育",
            "科研机构",
            "图书馆",
            "科技馆",
          "大学",
          "学院",
          ],
        },
        {
          id: 3,
          name: "医疗",
          keywords: [
            "医院",
            "药店",
            "疗养院",
            "体检机构",
            "急救中心",
            "疾控中心",
            // "医疗保健",
          ],
        },
        {
          id: 4,
          name: "商业",
          keywords: [
          "购物中心",
          "百货商场",
          "超级市场",
          "星级酒店",
          "酒店",
          "美食",
          "休闲娱乐",
          "银行",
          "旅游景点",
          "写字楼",
          ],
        },
        {
          id: 0,
          name: "特殊",
          keywords: [
            "垃圾场",
            "垃圾焚烧厂",
            "垃圾填埋场",
            "殡葬服务",
            "陵园",
            "化工厂",
            "危险品仓库",
            "核电厂",
            "变电站",
            "污水处理厂",
          ],
        },
      ],
      selectedTab: 1,
      keyword: "",
    };
  },

  computed: {
    projectDetail() {
      return this.$store.state.project.projectDetail;
    },
    nearbyList: {
      get() {
        return this.$store.state.project.nearbyList;
      },
      set(value) {
        this.$store.state.project.nearbyList = value;
      },
    },
    map: {
      get() {
        return this.$store.state.project.map;
      },
      set(newValue) {
        this.$store.state.project.map = newValue;
      },
    },
  },

  mounted() {},

  methods: {
    selectTab(item) {
      this.selectedTab = item.id;
      if (item.keywords) {
        this.keyword = item.keywords;
      } else {
        this.keyword = item.name;
      }

      this.inputVal = "";
      this.handleSearch();
    },

    handleSearch() {
      this.$parent.getNearbyList(this.inputVal, this.keyword);
    },
    handleItemClick(item) {
      if (!item.jd || !item.wd) return;
      item.marker.ba.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.land-market-right {
  width: 100%;
  height: 100%;

  .header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fcfcff;
    font-size: 14px;
    color: #333;
    border-bottom: 2px solid #00b6ff;

    .tab {
      flex: 1;
      height: 100%;
      line-height: 60px;
      text-align: center;
      cursor: pointer;
    }

    .active {
      color: #fff;
      background-color: #00b6ff;
    }
  }

  .search-box {
    width: 100%;
    padding: 0 20px;
    margin-top: 14px;
    display: flex;
    align-items: center;

    .icon-box {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin-left: 17px;
      background-color: #00b6ff;
      border-radius: 5px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }
  }

  .result-list {
    width: 100%;
    height: calc(100% - 106px);
    padding: 0 10px;
    overflow: auto;

    .result-item {
      width: 100%;
      padding: 15px 0;
      border-bottom: 1px solid #d6d7d9;
      position: relative;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .name {
          font-size: 16px;
          color: #333;
          width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .tag {
          padding: 0 8px;
          height: 22px;
          border-radius: 2px;
          font-size: 13px;
          right: 0;
          position: absolute;
          &.tag-0 {
            top: 10px;
            color: #c60a00 !important;
            font {
              color: #c60a00 !important;
            }
          }
          &.tag-1 {
            top: 35px;
            color: #c60a00 !important;
            font {
              color: #c60a00 !important;
            }
          }
          &.tag-2 {
            top: 60px;
            color: #c60a00 !important;
            font {
              color: #c60a00 !important;
            }
          }
        }
      }

      .bottom {
        width: 100%;

        .svg-icon {
          width: 18px;
          height: 18px;
          fill: #666;
        }

        .distance {
          font-size: 14px;
          color: #666;
          margin-left: 3px;
          vertical-align: top;
        }
      }
    }
  }
}
</style>
