<template>
	<Modal class="newMapModal" footer-hide fullscreen :width="100" v-model="showNewMapModal">
		<p slot="header" style="text-align:center">
			<span>选择地点</span>
		</p>
		<div class="map-container" ref="modalMap" id="modalMap">
			<baidu-map :center="center" :zoom="zoom" @ready='loadComplete' :scroll-wheel-zoom='true'
				:mapStyle='mapStyle'>
				<bm-view class="map"></bm-view>
				<bm-control :offset="{width:10,height:45}">
					<bm-auto-complete v-model="keyword"
						:sugStyle="{zIndex: 999999}">
						<input type="text" v-model="keyword" placeholder="请输入搜索关键字" class="serachinput">
						<!-- 这里指代一个自定义搜索框组件 -->
					</bm-auto-complete>
					<div class="btnBox">
						<div :class="['btns', 'btn-weixing', { active: mapType === 1 }]" @click="changeMapType"
							:title="mapType === 1 ? '默认地图' : '卫星地图'"></div>
						<Button type="primary" class="btn btn-sure confirm" @click="confirm" size="large">确定</Button>
						<div class="btns btn-reset" @click="clear" title="重置"></div>
					</div>
				</bm-control>
				<bm-local-search :keyword="keyword" :auto-viewport="true"></bm-local-search>
				<bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>
			</baidu-map>
		</div>
	</Modal>
</template>

<script>
	import {
		lchown
	} from "fs";
	import {
		addCityProject,
		addPlaceProject,
		getXmid,
	} from "@/api/projectAnalysis";
	import {
		reverseGeocoding
	} from '../../api/apiBaidu.js'
	import BMapLib from "BMapLib";
	export default {
		name: "newMapModal",
		props: {
			id: [String, Number],
			address: [String, Object],
			city: String,
			projectName: String,
			type: {
				type: String,
				default: "analysis",
			},
		},
		data() {
			return {
				map: '',
				BMap: '',
				keyword: '',
				center: {
					lng: 0,
					lat: 0
				},
				zoom: 15,
				mapStyle: BMAP_NORMAL_MAP,
				offset: {
					'width': '100px',
					'height': '10px'
				},
				loaded: false,
				mapType: 0,
				polyline: {
					editing: false,
					paths: [{
						lng: 116.414,
						lat: 39.915
					}, {
						lng: 116.404,
						lat: 39.915
					}, {
						lng: 116.404,
						lat: 39.925
					}]
				},
				location: {
					locating: false,
					point: null,
				},
				area: {
					sheng: '',
					shi: '',
					qu: '',
					location: '',
				},
				polygonList: [],
				polygonLabelList: [],
				polygonIndex: 0,
				drawingManager: null,
				pathData: null,
				pointData: null,
				oldPoint: null
			};
		},
		computed: {
			showNewMapModal: {
				get() {
					return this.$store.state.modal.showNewMapModal;
				},
				set(newValue) {
					this.$store.state.modal.showNewMapModal = newValue;
				},
			},
		},

		watch: {
			showNewMapModal(value) {
				if (value) {
					this.$nextTick(() => {
						this.keyword = this.address
						this.clear()
						let CityListControl = new this.BMap.CityListControl({
							anchor: global.BMAP_ANCHOR_TOP_RIGHT,
						})
						this.map.addControl(CityListControl)
					});
				}
			},
		},
		mounted() {

		},
		methods: {
			loadComplete({
				BMap,
				map
			}) {
				this.center.lng = 116.404
				this.center.lat = 39.915
				this.zoom = 14
				this.loaded = true
				this.map = map
				this.BMap = BMap
				this.initDrawingManager()

			},
			// 初始化地图收手绘工具
			initDrawingManager() {
				const styleOptions = {
					strokeColor: "red", //边线颜色。
					fillColor: "", //填充颜色。当参数为空时，圆形将没有填充效果。
					strokeWeight: 3, //边线的宽度，以像素为单位。
					strokeOpacity: 1, //边线透明度，取值范围0 - 1。
					fillOpacity: 1, //填充的透明度，取值范围0 - 1。
					strokeStyle: "dashed", //边线的样式，solid或dashed。
				};

				this.drawingManager = new BMapLib.DrawingManager(this.map, {
					isOpen: false, //是否开启绘制模式
					enableDrawingTool: true, //是否显示工具栏
					drawingToolOptions: {
						offset: new BMap.Size(10, 90), //偏离值
						anchor: BMAP_ANCHOR_TOP_LEFT,
						drawingModes: [BMAP_DRAWING_POLYGON, global.BMAP_DRAWING_MARKER],
					},
					polygonOptions: styleOptions, //多边形的样式
				});
				this.drawingManager.addEventListener("overlaycomplete", this.measureArea);
				this.drawingManager.enableCalculate();
			},
			measureArea(e) {
				let self = this
				if (e.drawingMode == "polygon") {

					if (e.calculate === null) {
						self.$msg.success({
							text: "框选区域无效，请重新框选"
						});
						self.map.removeOverlay(e.overlay);
						self.map.removeOverlay(e.label);
						self.drawingManager.close();
						return;
					}
					if (self.pathData) {
						self.$msg.success({
							text: "不能重复框选区域"
						});
						self.map.removeOverlay(e.overlay);
						self.map.removeOverlay(e.label);
						self.drawingManager.close();
						return
					}
					if (!self.pointData) {
						self.$msg.success({
							text: "请定位后再框选区域"
						});
						self.map.removeOverlay(e.overlay);
						self.map.removeOverlay(e.label);
						return
					}
					self.pathData = e.overlay.getPath();

				} else if (e.drawingMode == "marker") {
					if (self.oldPoint) {
						self.map.removeOverlay(self.oldPoint);
					}
					self.oldPoint = e.overlay
					self.pointData = {
						lng: e.overlay.point.lng,
						lat: e.overlay.point.lat
					}
					const point = new self.BMap.Point(e.overlay.point.lng, e.overlay.point.lat);
					const geoc = new self.BMap.Geocoder()
					geoc.getLocation(point, res => {
						self.area.sheng = res.addressComponents.province
						self.area.shi = res.addressComponents.city
						self.area.qu = res.addressComponents.district
						self.area.location = `${res.point.lat},${res.point.lng}`
						self.drawingManager.close();
					})
					//调用webApi服务，已转后端
					// $.ajax({
					// 	url: 'https://api.map.baidu.com/reverse_geocoding/v3/?ak=FKxzbKF8qui5FoV0db5fAdsNGhiq0AQi&location=' + e.overlay.point.lat + ',' +
					// 		e.overlay.point.lng + '&output=json&pois=1',
					// 	dataType: 'jsonp',
					// 	callback: 'BMap._rd._cbk43398',
					// 	success: function(res) {
					// 	}
					// })


				}
			},
			// searchFn(AutocompleteResult) {
			// },
			// autoCmpleteConfirm(e) {
			// },
			// markersset(e) {
			// },
			// toggle(name) {
			// 	if (this.location.locating) {
			// 		this.$msg.success({
			// 			text: "请取消地点选择"
			// 		});
			// 		return
			// 	}
			// 	if (!this.location.point) {
			// 		this.$msg.success({
			// 			text: "请选择地点"
			// 		});
			// 		return
			// 	}
			// 	this[name].editing = !this[name].editing
			// },
			changeMapType() {
				if (this.mapType === 0) {
					this.mapType = 1;
					this.map.setMapType(global.BMAP_HYBRID_MAP);
				} else {
					this.mapType = 0;
					this.map.setMapType(BMAP_NORMAL_MAP);
				}
			},
			// locate() {
			// 	if (this.polyline.editing) {
			// 		this.$msg.success({
			// 			text: "取消折线绘制"
			// 		});
			// 		return
			// 	}
			// 	this.location.locating = true;
			// 	const fn = async (event) => {
			// 		this.map.clearOverlays();
			// 		this.polyline.paths = []
			// 		const point = new this.BMap.Point(event.point.lng, event.point.lat);
			// 		const geoc = new this.BMap.Geocoder()
			// 		geoc.getLocation(event.point, res => {
			// 			this.area.sheng = res.addressComponents.province
			// 			this.area.shi = res.addressComponents.city
			// 			this.area.qu = res.addressComponents.district
			// 		})

			// 		// this.polyline.paths.push({
			// 		// 	lng: event.point.lng + 0.01,
			// 		// 	lat: event.point.lat + 0.01
			// 		// }, {
			// 		// 	lng: event.point.lng - 0.01,
			// 		// 	lat: event.point.lat + 0.01
			// 		// }, {
			// 		// 	lng: event.point.lng - 0.01,
			// 		// 	lat: event.point.lat - 0.01
			// 		// }, {
			// 		// 	lng: event.point.lng + 0.01,
			// 		// 	lat: event.point.lat - 0.01
			// 		// })
			// 		this.map.setCenter(point);
			// 		this.map.addOverlay(new this.BMap.Marker(point));
			// 		this.location.point = {
			// 			lng: event.point.lng,
			// 			lat: event.point.lat
			// 		}
			// 		this.location.locating = false;
			// 		this.map.removeEventListener("click", fn);
			// 	};
			// 	this.map.addEventListener("click", fn);
			// },
			// updatePolygonPath(e) {
			// 	this.polyline.paths = e.target.getPath()
			// },
			// paintPolyline(e) {
			// 	if (!this.polyline.editing) {
			// 		return
			// 	}
			// 	const {
			// 		paths
			// 	} = this.polyline
			// 	paths.push({
			// 		lng: e.point.lng,
			// 		lat: e.point.lat
			// 	})
			// },
			// stopPolyline(e) {
			// 	if (this.polyline.editing) {
			// 		this.polyline.editing = false
			// 	}
			// },
			// changeCursor(e) {
			// 	if (!this.polyline.editing) {
			// 		return
			// 	}
			// 	const {
			// 		paths
			// 	} = this.polyline
			// 	if (!paths.length) {
			// 		return
			// 	}
			// 	const path = paths[paths.length - 1]
			// 	if (!path.length) {
			// 		return
			// 	}
			// 	if (path.length === 1) {
			// 		path.push({
			// 			lng: e.point.lng,
			// 			lat: e.point.lat
			// 		})
			// 	}
			// 	this.$set(path, path.length - 1, {
			// 		lng: e.point.lng,
			// 		lat: e.point.lat
			// 	})
			// },
			confirm() {
				if (
					this.polyline.paths &&
					this.polyline.paths.length > 0 &&
					this.polyline.paths.length < 3
				) {
					this.$msg.error({
						text: "区域无效"
					});
					return;
				}
				this.$emit("confirm", this.pathData, this.pointData, this.area);
				this.showNewMapModal = false;
			},
			clear() {
				this.map.clearOverlays()
				this.pathData = null
				this.pointData = null
			}
		},
	};
</script>

<style lang="scss" scoped>
	.newMapModal {
		position: relative;

		.map-container {
			width: 100%;
			height: 100%;
			overflow: hidden;

			.map {
				width: 100%;
				height: 100%;
			}

		}

		.map-container>div {
			width: 100%;
			height: 100%;
		}

	}

	.serachinput {
		width: 300px;
		height: 36px;
		line-height: 36px;
		font-size: 18px;
	}

	.btnBox {
		margin-top: 10px;

		.btns {
			position: absolute;
			width: 64px;
			height: 45px;
			border: 1px solid #666;
			cursor: pointer;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100% auto;
			border-radius: 5px;


			&.btn-paint {
				left: 70px;
				background-image: url(../../assets/image/paint.png);
				background-color: white;
				background-size: 50% auto;
				z-index: 99999;

				&:hover {
					background-image: url(../../assets/image/paint_active.png);
					background-color: #2479b5;
				}

				&.active {
					background-image: url(../../assets/image/paint_active.png);
					background-color: #2479b5;
				}
			}

			&.btn-location {
				left: 140px;
				background-image: url(../../assets/image/location.jpg);
				z-index: 99999;

				&:hover {
					background-image: url(../../assets/image/location_active.jpg);
				}

				&.active {
					background-image: url(../../assets/image/location_active.jpg);
				}
			}

			&.btn-weixing {
				left: 210px;
				background-image: url(../../assets/image/weixing.jpg);
				z-index: 999999;

				&:hover {
					background-image: url(../../assets/image/weixing_active.jpg);
				}

				&.active {
					background-image: url(../../assets/image/dimian.jpg);

					&:hover {
						background-image: url(../../assets/image/dimian_active.jpg);
					}
				}
			}

			&.btn-reset {
				left: 350px;
				background-image: url(../../assets/image/reset.jpg);

				&:hover {
					background-image: url(../../assets/image/reset_active.jpg);
				}

				&.active {
					background-image: url(../../assets/image/reset_active.jpg);
				}
			}
		}

		.btn-sure {
			left: 280px;
			position: absolute;
			cursor: pointer;
			width: 64px;
			height: 44px;
		}

		&.active {
			color: #2d8cf0;
			border-color: #2d8cf0;
		}
	}
</style>
