<template>
<div class="detail-left">
    <Header title="项目详情" :list="navList"></Header>
    <div class="content bigContent">
        <div class="pj-name">{{ projectDetail.xmmc }}</div>
        <div class="time">创建时间：{{ createTime }}</div>
        <div class="address">
            <svg-icon iconClass="map"></svg-icon>
            <span class="text">{{ address }}</span>
        </div>
        <ul class="area" v-if="projectTypeId == 3">
            <li class="used item">
                <p class="name">用地面积</p>
                <p class="value">{{ projectDetail.ydmj }}m²</p>
            </li>
            <li class="total item">
                <p class="name">总建筑面积</p>
                <p class="value">{{ projectDetail.zjzmj }}m²</p>
            </li>
        </ul>
        <ul class="tags">
            <li class="tag" v-for="item in tagsList" :key="item.id">
                <p class="name">{{ item.key }}</p>
                <p class="value" :title="item.value">
                    {{ item.value }}{{ item.unit }}
                </p>
            </li>
        </ul>

        <div class="table">
            <template v-if="projectTypeId == 2">
                <Row>
                    <Col class-name="key" span="6" title="楼盘名称">楼盘名称</Col>
                    <Col class-name="value" span="18" :title="projectDetail.xmfxLouResp.lpmc">{{ projectDetail.xmfxLouResp.lpmc }}</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="楼盘地址">楼盘地址</Col>
                    <Col class-name="value" span="18" :title="projectDetail.xmfxLouResp.lpdz">{{ projectDetail.xmfxLouResp.lpdz }}</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="销售状态">销售状态</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLouResp.xszt">{{ projectDetail.xmfxLouResp.xszt }}</Col>
                    <Col class-name="key bdl" span="6" title="所属板块">所属板块</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLouResp.ssbk">{{ projectDetail.xmfxLouResp.ssbk }}</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="物业类型">物业类型</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLouResp.wylx">{{ projectDetail.xmfxLouResp.wylx }}</Col>
                    <Col class-name="key bdl" span="6" title="装修情况">装修情况</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLouResp.zxqk">{{ projectDetail.xmfxLouResp.zxqk }}</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="总建筑面积">总建筑面积</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLouResp.zjjmj">{{ projectDetail.xmfxLouResp.zjjmj }}</Col>
                    <Col class-name="key bdl" span="6" title="规划户数">规划户数</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLouResp.ghhs">{{ projectDetail.xmfxLouResp.ghhs }}</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="开发商">开发商</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLouResp.kfs">{{ projectDetail.xmfxLouResp.kfs }}</Col>
                    <Col class-name="key bdl" span="6" title="容积率">容积率</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLouResp.rjl">{{ projectDetail.xmfxLouResp.rjl }}</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="物业公司">物业公司</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLouResp.wygs">{{ projectDetail.xmfxLouResp.wygs }}</Col>
                    <Col class-name="key" span="6" title="产权年限">产权年限</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLouResp.cqnx">{{ projectDetail.xmfxLouResp.cqnx }}</Col>
                </Row>
            </template>
            <template v-if="projectTypeId == 1">
                <Row>
                    <Col class-name="key" span="6" title="招拍挂起止时间">招拍挂起止时间</Col>
                    <Col class-name="value" span="18" :title="projectDetail.xmfxLandResp.zpgqssj">{{ projectDetail.xmfxLandResp.zpgqssj }} 至
                    {{ projectDetail.xmfxLandResp.fbsjCj }}</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="地块位置">地块位置</Col>
                    <Col class-name="value" span="18" :title="projectDetail.xmfxLandResp.dkwz">{{ projectDetail.xmfxLandResp.dkwz }}</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="地块编号">地块编号</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLandResp.zdbhCr">{{ projectDetail.xmfxLandResp.zdbhCr }}</Col>
                    <Col class-name="key bdl" span="6" title="成交时间">成交时间</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLandResp.fbsjCj">{{ projectDetail.xmfxLandResp.fbsjCj }}</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="出让方式">出让年限</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLandResp.crnx">{{ projectDetail.xmfxLandResp.crnx }}</Col>
                    <Col class-name="key bdl" span="6" title="交易状态">交易状态</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLandResp.stateText">{{ projectDetail.xmfxLandResp.stateText }}</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="建筑密度">建筑密度</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLandResp.jzmdms">{{ projectDetail.xmfxLandResp.jzmdms }}</Col>
                    <Col class-name="key bdl" span="6" title="起始价">起始价</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLandResp.qsjms">{{ projectDetail.xmfxLandResp.qsjms }}</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="成交价">成交价</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLandResp.cjjms + '万元'">{{ projectDetail.xmfxLandResp.cjjms }}万元</Col>
                    <Col class-name="key bdl" span="6" title="推出楼面价">推出楼面价</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLandResp.tclmjg + '元/m²'">{{
                Math.round(projectDetail.xmfxLandResp.tclmjg) || ""
              }}元/m²</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="成交楼面价">成交楼面价</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLandResp.cjlmj + '元/m²'">{{
                Math.round(projectDetail.xmfxLandResp.cjlmj) || ""
              }}元/m²</Col>
                    <Col class-name="key bdl" span="6" title="溢价率">溢价率</Col>
                    <Col class-name="value" span="6" :title="projectDetail.xmfxLandResp.yjl">{{ projectDetail.xmfxLandResp.yjl }}</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="受让单位">受让单位</Col>
                    <Col class-name="value" span="18" :title="projectDetail.xmfxLandResp.sldw">{{ projectDetail.xmfxLandResp.sldw }}</Col>
                </Row>
                <Row>
                    <Col class-name="key" span="6" title="配建信息">配建信息</Col>
                    <Col class-name="value" span="18" :title="projectDetail.xmfxLandResp.pjzzfqk">{{ projectDetail.xmfxLandResp.pjzzfqk }}</Col>
                </Row>
            </template>
        </div>

        <div class="text-box">
            <template v-if="projectTypeId == 1">
                <div class="introduce">
                    <p class="title">备注</p>
                    <div class="content">
                        {{ projectDetail.xmfxLandResp.bzCj }}
                    </div>
                </div>
            </template>
            <template v-if="projectTypeId == 2">
                <div class="introduce features">
                    <p class="title">项目特色</p>
                    <div class="content">
                        {{ projectDetail.xmfxLouResp.xmts }}
                    </div>
                </div>
                <div class="introduce">
                    <p class="title">项目简介</p>
                    <div style="white-space: pre-wrap;" class="content">
                        {{ projectDetail.xmfxLouResp.xmjs }}
                    </div>
                </div>
            </template>
            <template v-if="projectTypeId == 3">
                <div class="introduce status">
                    <p class="title">项目现状</p>
                    <div style="white-space: pre-wrap;" class="content">
                        {{ projectDetail.xz }}
                    </div>
                </div>
                <div class="introduce status">
                    <p class="title">项目地址</p>
                    <pre class="content">
                    {{ projectDetail.xmwz }}
                    </pre>
                </div>
                <div class="introduce">
                    <p class="title">项目简介</p>
                    <div style="white-space: pre-wrap;" class="content">
                        {{ projectDetail.xmjj }}
                    </div>
                </div>
                <div class="file">
                    <div class="title">相关文件</div>
                    <Table :columns="columns" tooltip-theme="light" :data="fileList" :width="440"></Table>
                </div>
            </template>
        </div>
    </div>
</div>
</template>

<script>
import BMap from "BMap";
import Header from "../LeftHeader";
import {
    mapState
} from "vuex";
import {
    formatTime,
    getFileType
} from "@/utils/index";

export default {
    name: "detailLeft",
    components: {
        Header,
    },
    props: {
        navList: {
            type: Array,
            default () {
                return [];
            },
        },
    },
    data() {
        const self = this;
        return {
            columns: [{
                    title: "文件名称",
                    key: "name",
                    align: "center",

                    tooltip: true,

                    render(h, params) {
                        return h(
                            "span", {
                                style: {
                                    overflow: "hidden",
                                    "white-space": "nowrap",
                                    "text-overflow": "ellipsis",
                                    color: "#00B6FF",
                                },
                                domProps: {
                                    title: params.row.name,
                                },
                            },
                            params.row.name
                        );
                    },
                },
                {
                    title: "描述",
                    key: "desc",
                    width: 87,
                    align: "center",
                    tooltip: true
                },
                {
                    title: "类型",
                    key: "type",
                    width: 80,
                    align: "center",
                    tooltip: true
                },
                {
                    title: "操作",
                    key: "options",
                    width: 134,
                    align: "center",
                    render(h, params) {
                        return h(
                            "a", {
                                style: {
                                    color: "#00B6FF",
                                    "text-decoration": "underline",
                                    cursor: "pointer",
                                },
                                domProps: {
                                    href: params.row.url,
                                    download: params.row.name,
                                    target: "_blank",
                                },
                                on: {
                                    click(e) {
                                        // e.preventDefault();
                                        // self.downloadByData(params.row.url);
                                    },
                                },
                            },
                            "打开"
                        );
                    },
                },
            ],

            address: "",
            landUseList: [{
                    id: 0,
                    name: "综合",
                },
                {
                    id: 1,
                    name: "住宅",
                },
                {
                    id: 2,
                    name: "商服",
                },
                {
                    id: 3,
                    name: "商住",
                },
                {
                    id: 4,
                    name: "工业",
                },
                {
                    id: 5,
                    name: "其他",
                },
            ],
        };
    },

    computed: {
        projectDetail() {
            return this.$store.state.project.projectDetail;
        },

        createTime() {
            return formatTime(this.projectDetail.gmtCreate);
        },

        ...mapState({
            dimData(state) {
                return state.project.option;
            },
        }),

        projectTypeId() {
            return this.projectDetail.xmlx;
        },

        projectType() {
            return this.$store.state.project.projectType;
        },

        fileList() {
            const result = [];
            window.URL = window.URL || window.webkitURL;

            this.projectDetail.fileList.forEach((item) => {
                const obj = {};

                obj.name = item.wjmc;
                obj.desc = item.ms;
                obj.type = getFileType(item.wjmc);
                obj.url = item.url;

                result.push(obj);
            });

            return result;
        },

        tagsList() {
            switch (this.projectTypeId) {
                case 3:
                    return [{
                            id: 0,
                            key: "项目类型",
                            value: this.projectDetail.zdyxmlx,
                        },
                        {
                            id: 1,
                            key: "项目性质",
                            value:
                                // this.projectDetail.zdytdyt
                                isNaN(this.projectDetail.zdytdyt) ?
                                this.projectDetail.zdytdyt : this.landUseList.filter((i) => {
                                    return i.id == this.projectDetail.zdytdyt;
                                })[0].name
                        },
                        {
                            id: 2,
                            key: "容积率",
                            value: this.projectDetail.rjl,
                        },
                    ];
                case 2:
                    return [{
                            id: 0,
                            key: "新房价格",
                            value: this.projectDetail.xmfxLouResp.ysckj,
                        },
                        {
                            id: 1,
                            key: "二手房价",
                            value: this.projectDetail.xmfxLouResp.esfj,
                        },
                        {
                            id: 2,
                            key: "租金价格",
                            value: this.projectDetail.xmfxLouResp.zjjg,
                        },
                    ];
                default:
                    return [{
                            id: 0,
                            key: "土地性质",
                            value: this.projectDetail.xmfxLandResp.tdxz,
                        },
                        {
                            id: 1,
                            key: "土地面积",
                            value: Math.round(this.projectDetail.xmfxLandResp.tdmj * 100) / 100,
                            unit: "万m²",
                        },
                        {
                            id: 2,
                            key: "规划建筑面积",
                            value: Math.round(
                                (this.projectDetail.xmfxLandResp.ghjzmj / 10000) * 100
                            ) / 100,
                            unit: "万m²",
                        },
                        {
                            id: 3,
                            key: "出让方式",
                            value: this.projectDetail.xmfxLandResp.crfsHbT,
                        },
                        {
                            id: 4,
                            key: "容积率",
                            value: this.projectDetail.xmfxLandResp.rjlms,
                        },
                        {
                            id: 5,
                            key: "绿化率",
                            value: this.projectDetail.xmfxLandResp.lhlms,
                        },
                    ];
            }
        },
    },

    watch: {
        projectDetail() {
            this.getAddress();
        },
    },

    mounted() {
        this.getAddress();
    },

    methods: {
        getAddress() {
            if (!this.projectDetail.jd || !this.projectDetail.wd) return;
            const geo = new BMap.Geocoder();
            geo.getLocation(
                new BMap.Point(this.projectDetail.jd, this.projectDetail.wd),
                (result) => {
                    if (result) {
                        this.address = result.address;
                    }
                },
                `${this.city}市`
            );
        },
        downloadByData(url) {
            let image = new Image();
            image.setAttribute("crossOrigin", "anonymous");
            image.src = url;
            image.onload = () => {
                let canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0, image.width, image.height);
                let ext = image.src
                    .substring(image.src.lastIndexOf(".") + 1)
                    .toLowerCase();
                let dataURL = canvas.toDataURL("image/" + ext);
                this.download(dataURL);
            };
        },
        download(href, name = "pic") {
            let eleLink = document.createElement("a");
            eleLink.download = name;
            eleLink.href = href;
            eleLink.click();
            eleLink.remove();
        },
    },
};
</script>

<style lang="scss" scoped>
.detail-left {
    width: 100%;
    height: 100%;
    background-color: #fff;
    // position: relative;
    top: -30px;
    // overflow: hidden;

    .header {
        // width: 100%;
        height: 60px;
        line-height: 60px;
        font-size: 18px;
        color: #1562d6;
        text-align: center;
        background-color: #f3f7ff;
    }

    .content {
        display: block;
        padding: 0px 20px 0px;

        &.bigContent {
            padding-top: 80px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 100px;
        }

        .pj-name {
            width: 100%;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 18px;
            color: #333333;
        }

        .table {
            margin-bottom: 20px;
            border: 1px solid #d6d7d9;

            .ivu-row {
                height: 40px;
                border-bottom: 1px solid #d6d7d9;

                &:last-child {
                    border-bottom: none;
                }

                .ivu-col {
                    height: 100%;
                    padding: 0 6px;
                    line-height: 40px;
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .key {
                    background-color: #fafafd;
                    border-right: 1px solid #d6d7d9;

                    &.bdl {
                        border-left: 1px solid #d6d7d9;
                    }
                }
            }
        }
    }

    .time {
        margin-bottom: 10px;
        color: #999;
        font-size: 14px;
    }

    .address {
        margin-bottom: 20px;
        padding-left: 11px;
        line-height: 40px;
        height: 40px;
        color: #333;
        font-size: 16px;
        background: rgba(242, 242, 242, 1);
        display: flex;
        align-items: center;

        .text {
            margin-left: 3px;
        }
    }

    .area {
        margin-bottom: 20px;
        display: flex;
        list-style: none;

        .item {
            flex: 1;
            text-align: center;

            .name {
                margin-bottom: 10px;
                color: #666;
                font-size: 14px;
            }

            .value {
                font-size: 20px;
                font-weight: bold;
                color: #ff3333;
            }
        }
    }

    .tags {
        margin-bottom: 30px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 6px;
        grid-row-gap: 6px;
        list-style: none;

        .tag {
            width: 142px;
            height: 70px;
            padding: 10px 6px 0px;
            background-color: #f3f7ff;
            text-align: center;

            .name {
                margin-bottom: 10px;
                font-size: 14px;
                color: #1562d6;
            }

            .value {
                width: 100%;
                height: 24px;
                color: #1562d6;
                font-size: 16px;
                font-weight: bold;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .introduce {
        margin-bottom: 30px;

        .title {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
        }

        .content {
            padding: 0;
            font-size: 14px;
            color: #666;
            line-height: 24px;
            word-break: break-word;
            white-space: pre-line
        }
    }

    .file {
        .title {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
        }
    }
}
</style>
